
import { CIcon } from '@coreui/icons-react'
import {
  CButton, CButtonGroup, CCard, CCardBody, CCardHeader, CCol,
  CDataTable, CRow, CTooltip
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import UserModal from './modals/UserModal'

function Users(props) {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState()
  const [showUserModal, setShowUserModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const deleteUser = async () => {
    if (selectedUser == null) return

    try {
      await Backend.deleteUser(selectedUser.id)
      const updatedUsers = users.filter(item => {
        if (item.id != selectedUser.id) {
          return item
        }
      })
      setUsers([...updatedUsers])
      setShowConfirmModal(false)
      setSelectedUser(null)
      Notify.success('User deleted successfully.')
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const getUsers = async () => {
    setLoading(true)

    try {
      const response = await Backend.getUsers();
      if (response.data) {
        setUsers([...response.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Users</h4>
        </CCol>
        <CCol className="text-right">
          {
            AuthManager.getCurrentUser().role === 'admin' &&
            (
              <CButton color="dark" className="btn-sm"
                onClick={() => setShowUserModal(!showUserModal)} ><CIcon name="cil-plus" /> Add User</CButton>
            )
          }
        </CCol>
      </CRow>


      <CRow className={'mt-3'}>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Users</h6>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {
                loading ?
                  <Loading />
                  :
                  <>
                    <CDataTable
                      items={users}
                      striped
                      outlined={true}
                      fields={[
                        {
                          key: 'name',
                          label: 'Name'
                        },
                        {
                          key: 'email',
                          label: 'Email'
                        }, {
                          key: 'role',
                          label: 'Role'
                        }, {
                          key: 'created_at',
                          label: 'Created At'
                        }, {
                          key: 'actions',
                          label: 'Actions',
                        }
                      ]}
                      scopedSlots={{
                        'role': (item) => {
                          return (
                            <td>{item.role.toUpperCase()}</td>
                          )
                        },
                        'created_at': (item) => {
                          return (
                            <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                          )
                        },
                        'actions': (item) => {
                          return (
                            <td>
                              <CButtonGroup className={'btn-sm'}>
                                {
                                  AuthManager.getCurrentUser().role === 'admin' &&
                                  (
                                    <>
                                      <CTooltip content="Edit User" placement="bottom">
                                        <CButton color="dark" variant="outline" className={'btn-sm'}
                                          onClick={() => {
                                            setSelectedUser(item)
                                            setShowUserModal(true)
                                          }}>
                                          <CIcon name="cil-pen-alt"></CIcon>
                                        </CButton>
                                      </CTooltip>
                                    </>
                                  )
                                }
                                {
                                  (AuthManager.getCurrentUser().id === item.id && AuthManager.getCurrentUser().role !== 'admin') &&
                                  (
                                    <>
                                      <CTooltip content="Edit User" placement="bottom">
                                        <CButton color="dark" variant="outline" className={'btn-sm'}
                                          onClick={() => {
                                            setSelectedUser(item)
                                            setShowUserModal(true)
                                          }}>
                                          <CIcon name="cil-pen-alt"></CIcon>
                                        </CButton>
                                      </CTooltip>
                                    </>
                                  )
                                }

                                {
                                  (AuthManager.getCurrentUser().role === 'admin' && AuthManager.getCurrentUser().id !== item.id) &&
                                  (
                                    <>
                                      <CTooltip content="Delete User" placement="bottom">
                                        <CButton color="danger" variant="outline" className={'btn-sm'}
                                          onClick={() => {
                                            setSelectedUser(item)
                                            setShowConfirmModal(true)
                                          }}>
                                          <CIcon name="cil-trash"></CIcon>
                                        </CButton>
                                      </CTooltip>
                                    </>
                                  )
                                }
                              </CButtonGroup>
                            </td>
                          )
                        },

                      }}
                    />
                  </>
              }
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {
        showUserModal &&
        <UserModal
          show={showUserModal}
          user={selectedUser}
          onAdded={(user) => {
            users.unshift(user)
            setUsers([...users])
          }}
          onUpdated={(user) => {
            const updatedUsers = users.map(item => {
              if (item.id == user.id) {
                return user
              } else {
                return item
              }
            })
            setUsers([...updatedUsers])
          }}
          onClose={() => {
            setSelectedUser(null)
            setShowUserModal(false)
          }
          }
        />
      }
    </>
  )
}

export default withRouter(Users)
