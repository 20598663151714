import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import AuthManager from '../utils/AuthManager'
import navigations from './_nav'

function Sidebar() {
  const [menuItems, setMenuItems] = useState([])
  const { sidebarShow, setToggleSidebar, currentUser } = useAuth()
  const [minimized, setMinimized] = useState(true)

  useEffect(() => {
    if (AuthManager.getCurrentUser()) {
      let user = AuthManager.getCurrentUser()
      let items = navigations
        .filter((nav) => nav.roles.includes(user.role))
        .map((nav) => {
          if (nav._children) {
            return {
              ...nav,
              _children: nav._children.filter((child) =>
                child.roles.includes(user.role)
              ),
            }
          }

          return nav
        })

      setMenuItems([...items])
    }
  }, [currentUser])

  return (
    <CSidebar
      overlaid={false}
      aside={false}
      colorScheme={'dark'}
      onMinimizeChange={(val) => {
        setMinimized(val)
      }}
      show={sidebarShow}
      onShowChange={(val) => {
        setToggleSidebar(val)
        console.log(val)
      }}
    >
      <CSidebarBrand className='d-md-down-none'>
        <img
          src={'../assets/logo.png'}
          className={'mr-2'}
          style={{ height: '50px' }}
          alt=''
        />
        <h5
          className={'text-center'}
          style={{ display: !minimized ? 'none' : 'block', paddingTop: '10px' }}
        >
          BCM FUND
        </h5>
      </CSidebarBrand>
      <CSidebarNav>
        {menuItems.length > 0 && (
          <CCreateElement
            items={menuItems}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        )}
      </CSidebarNav>
      <CSidebarMinimizer className='c-d-md-down-none' />
    </CSidebar>
  )
}

export default Sidebar
