import React from 'react'
import { CRow, CSpinner } from '@coreui/react'

export default function Loading() {
  return (
    <CRow className="mt-3">
      <div className="col w-100 text-center mt-3">
        <CSpinner
        />
      </div>
    </CRow>
  )
}

