window.Api = {};
window.Api.Base = process.env.REACT_APP_BASE_API

window.Api.User = {};
window.Api.User.Login = window.Api.Base + '/auth/login'
window.Api.User.Logout = window.Api.Base + '/auth/logout'
window.Api.User.RefreshToken = window.Api.Base + '/auth/refresh'
window.Api.User.Info = window.Api.Base + '/auth/info'
window.Api.User.OTP = window.Api.Base + '/send-otp'

window.Api.Users = window.Api.Base + '/users'
window.Api.Settings = window.Api.Base + '/settings'
window.Api.Branches = window.Api.Base + '/branches'
window.Api.FinancialYears = window.Api.Base + '/financial-years'
window.Api.Employees = window.Api.Base + '/employees'
window.Api.Accounts = window.Api.Base + '/accounts'
window.Api.FiscalAccounts = window.Api.Base + '/fiscal-accounts'
window.Api.Loans = window.Api.Base + '/loans'
window.Api.LoanRecoveries = window.Api.Base + '/loan-recoveries'
window.Api.Contributions = window.Api.Base + '/contributions'
window.Api.Signatories = window.Api.Base + '/signatories'
window.Api.AccountHeads = window.Api.Base + '/account-heads'
window.Api.Receipts = window.Api.Base + '/receipts'
window.Api.Payments = window.Api.Base + '/payments'
window.Api.AdvancePayments = window.Api.Base + '/advance-payments'
window.Api.UploadFile = window.Api.Base + '/upload'
window.Api.Stats = window.Api.Base + '/stats'
window.Api.Dependents = window.Api.Base + '/dependents'



