import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CInput,
  CSelect,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import BulkContributionModal from './modals/BulkContributionModal'
import ConfirmationModal from './modals/ConfirmationModal'
import SingleContributionModal from './modals/SingleContributionModal'
import { accountTypes } from '../constants/Options'
import AuthManager from '../utils/AuthManager'
import ExportContributionModal from './modals/ExportContributionModal'

function Contributions(props) {
  const nameRef = useRef(null)
  const branchRef = useRef(null)
  const typeRef = useRef(null)
  const [contributions, setContributions] = useState([])
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(true)
  const [showSingleContributionModal, setShowSingleContributionModal] =
    useState(false)
  const [showBulkContributionModal, setShowBulkContributionModal] =
    useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [showExportModal, setShowExportModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    content: '',
    confirmText: 'Delete',
    onConfirm: () => {},
  })

  const filterContributions = async () => {
    await getContributions()
  }

  const getContributions = async () => {
    setLoading(true)

    try {
      let params = `?per_page=15&page=${currentPage}`
      if (nameRef.current.value) {
        params += `&name=${nameRef.current.value}`
      }
      if (branchRef.current.value) {
        params += `&branch=${branchRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&account_type=${typeRef.current.value}`
      }
      const response = await Backend.getContributions(params)
      if (response.data) {
        setLastPage(response.data.last_page)
        setContributions([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initBranches = async () => {
    try {
      const response = await Backend.getBranches()
      if (response.data) {
        setBranches([...response.data])
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  useEffect(() => {
    initBranches()
  }, [])

  useEffect(() => {
    getContributions()
  }, [currentPage])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Contributions</h4>
        </CCol>
        <CCol className='text-right'>
          {/* <CButton color="dark" className="btn-sm"
            onClick={() => setShowSingleContributionModal(!showSingleContributionModal)} ><CIcon name="cil-plus" /> Add Employee Contribution</CButton> */}

          <CButton
            color='dark'
            className='btn-sm ml-2'
            onClick={() =>
              setShowBulkContributionModal(!showBulkContributionModal)
            }
          >
            <CIcon name='cil-plus' /> Add Bulk Contributions
          </CButton>
          <CButton
            color='dark'
            className='btn-sm ml-2'
            onClick={() => setShowExportModal(!showExportModal)}
          >
            <CIcon name='cil-share' /> Export Contributions
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Contributions</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol className={'col-4'}>
                  <CInput
                    innerRef={nameRef}
                    name='name'
                    placeholder={'Search by Employee name...'}
                    onKeyUp={(evt) => {
                      if (evt.key == 'Enter' || !evt.target.value) {
                        filterContributions()
                      }
                    }}
                  />
                </CCol>
                <CCol
                  className={'col-2'}
                  style={{
                    display:
                      AuthManager.getCurrentUser().role == 'admin'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <CSelect
                    innerRef={typeRef}
                    onChange={() => filterContributions()}
                  >
                    <option value=''>--Select Type--</option>
                    {accountTypes.map((accountType) => {
                      return (
                        <option value={accountType.value}>
                          {accountType.label}
                        </option>
                      )
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-sm-3'}>
                  <CSelect
                    innerRef={branchRef}
                    name='branch'
                    onChange={(evt) => {
                      filterContributions()
                    }}
                  >
                    <option value=''>Select Department</option>
                    {branches.map((branch) => {
                      return <option value={branch.id}>{branch.name}</option>
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-sm-2'}>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    onClick={() => filterContributions()}
                  >
                    <CIcon name='cil-filter' /> Filter
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <CDataTable
                        items={contributions}
                        itemsPerPage={100}
                        pagination
                        outlined={true}
                        fields={[
                          {
                            key: 'employee',
                            label: 'Employee',
                          },
                          {
                            key: 'account',
                            label: 'Account',
                          },
                          {
                            key: 'date',
                            label: 'Month/Year',
                          },
                          {
                            key: 'created_at',
                            label: 'Entry Date',
                          },
                          {
                            key: 'amount',
                            label: 'Amount',
                          },
                          {
                            key: 'interest_rate',
                            label: 'Interest Rate',
                          },
                          {
                            key: 'interest',
                            label: 'Interest',
                          },
                        ]}
                        scopedSlots={{
                          employee: (item) => {
                            return <td>{item.name}</td>
                          },
                          account: (item) => {
                            return (
                              <td>{`${item.type.toUpperCase()} (${
                                item.code
                              })`}</td>
                            )
                          },
                          date: (item) => {
                            return (
                              <td>
                                {moment(
                                  item.month + '-' + item.year,
                                  'M-YYYY'
                                ).format('MMMM YYYY')}
                              </td>
                            )
                          },
                          created_at: (item) => {
                            return (
                              <td>
                                {moment(item.created_at).format('DD/MM/YYYY')}
                              </td>
                            )
                          },
                          amount: (item) => {
                            return <td>{General.formatRupees(item.amount)}</td>
                          },
                          interest_rate: (item) => {
                            return (
                              <td>
                                {parseFloat(item.interest_rate / 12).toFixed(2)}
                                %
                              </td>
                            )
                          },
                          interest: (item) => {
                            return (
                              <td>{General.formatRupees(item.interest)}</td>
                            )
                          },
                        }}
                      />
                      <CPagination
                        className={'mt-2'}
                        activePage={currentPage}
                        pages={lastPage}
                        onActivePageChange={(i) => setCurrentPage(i)}
                      ></CPagination>
                    </>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showSingleContributionModal && (
        <SingleContributionModal
          show={showSingleContributionModal}
          onAdded={() => {
            setShowSingleContributionModal(false)
            getContributions()
          }}
          onClose={() => {
            // getContributions()
            setShowSingleContributionModal(false)
          }}
        />
      )}

      {showBulkContributionModal && (
        <BulkContributionModal
          show={showBulkContributionModal}
          onAdded={() => {
            setShowBulkContributionModal(false)
            getContributions()
          }}
          onClose={() => {
            // getContributions()
            setShowBulkContributionModal(false)
          }}
        />
      )}

      {showExportModal && (
        <ExportContributionModal
          show={showExportModal}
          onExported={() => {
            setShowExportModal(false)
          }}
          onClose={() => {
            setShowExportModal(false)
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmModal.title}
          content={() => (
            <>
              <CAlert color='danger' className='w-100'>
                {confirmModal.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          confirmText={confirmModal.confirmText}
          onConfirm={confirmModal.onConfirm}
        />
      )}
    </>
  )
}

export default withRouter(Contributions)
