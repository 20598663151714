import { CIcon } from "@coreui/icons-react";
import { CCardGroup, CCol, CRow, CWidgetProgressIcon } from "@coreui/react";
import React, { useEffect, useState } from "react";
import Backend from "../utils/Backend";

export default function Dashboard() {
  const [stats, setStats] = useState({
    total: 0,
    epf: 0,
    ess: 0,
    nps: 0,
  });
  const [employees, setEmployees] = useState([]);
  const [providentAccounts, setPensionAccounts] = useState([]);
  const [essAccounts, setESSAccounts] = useState([]);

  const getStats = async () => {
    const stats = await Backend.getStats();
    setStats({ ...stats.data[0] });
  };

  useEffect(() => {
    getStats();
    return () => {};
  }, []);

  return (
    <>
      <CRow>
        <CCol>
          <h4>Dashboard</h4>
        </CCol>
      </CRow>

      <CRow className={"mt-3"}>
        <CCol>
          <CCardGroup className="mb-4">
            <CWidgetProgressIcon
              header={`${stats.total}`}
              text="Employees"
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>

            <CWidgetProgressIcon
              header={`${stats.epf}`}
              text="EPF Accounts"
              color="gradient-danger"
              inverse
            >
              <CIcon name="cil-grid" height="36" />
            </CWidgetProgressIcon>

            <CWidgetProgressIcon
              header={`${stats.ess}`}
              text="ESS Accounts"
              color="gradient-info"
              inverse
            >
              <CIcon name="cil-grid" height="36" />
            </CWidgetProgressIcon>

            <CWidgetProgressIcon
              header={`${stats.nps}`}
              text="NPS Accounts"
              color="gradient-primary"
              inverse
            >
              <CIcon name="cil-grid" height="36" />
            </CWidgetProgressIcon>
          </CCardGroup>
        </CCol>
      </CRow>

      {/* <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <h5>Monthly Sales</h5>
            </CCardHeader>
            <CCardBody>
              <CChartLine
                datasets={[
                  {
                    label: '',
                    backgroundColor: 'rgb(31,169,73, 0.9)',
                    data: salesChartData
                  }
                ]}
                options={{
                  tooltips: {
                    enabled: true
                  }
                }}
                labels="months"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <h5>Monthly Expenses</h5>
            </CCardHeader>
            <CCardBody>
              <CChartLine
                datasets={[
                  {
                    label: '',
                    backgroundColor: 'rgb(220,62,62)',
                    data: expensesChartData
                  }
                ]}
                options={{
                  tooltips: {
                    enabled: true
                  }
                }}
                labels="months"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow> */}
    </>
  );
}
