import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify'
import RedirectRoute from './components/RedirectRoute';
import UnAuthenticatedRoute from './components/UnAuthenticatedRoute';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './layouts/Layout';
import NotFound from './pages/404';
import Login from './pages/Login';

require('dotenv').config()

toast.configure()

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <UnAuthenticatedRoute exact path='/login' component={Login} />
          <Route path='/dashboard' render={() => <Layout />} />
          <Route path='/' component={RedirectRoute} />
          <Route path='*' component={NotFound} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
