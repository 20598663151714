import { CIcon } from '@coreui/icons-react'
import { CFormGroup, CInput, CInputGroup, CInputGroupAppend, CLabel } from '@coreui/react'
import moment from 'moment'
import React, { useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'

export default function CustomDatePicker(props) {
  const [selectedDateString, setSelectedDateString] = useState(
    props.value ? moment(props.value).format('DD/MM/YYYY') : '--/--/-----'
  )

  return (
    <>
      <CFormGroup>
        <CLabel>{props.label}: </CLabel>
        <CInputGroup className={'col-12 pl-0 pr-0'}>
          <CInput
            value={selectedDateString}
            placeholder={'--/--/----'}
            onChange={(e) => console.log(e.target.value)}
          />
          <CInputGroupAppend>
            <DateRangePicker
              initialSettings={{
                autoApply: true,
                singleDatePicker: true,
                showDropdowns: true,
                startDate: moment(),
                minYear: 1901,
                maxYear: parseInt(moment().add(60, 'years').format('YYYY'), 10)
              }}
              onApply={(evt, picker) => {
                let date = moment(picker.startDate).format('DD/MM/YYYY')
                setSelectedDateString(date)
                props.onApply(picker.startDate)
              }}
              // onCallback={(start) => {
              //   let date = moment(start).format('DD/MM/YYYY')
              //   setSelectedDateString(date)
              //   props.onApply(start)
              // }}
            >
              <button className="btn-sm btn btn-dark"
                type={'button'}
              >
                <CIcon name={'cilCalendar'} />
              </button>
            </DateRangePicker>
          </CInputGroupAppend>
        </CInputGroup>
      </CFormGroup>
    </>
  )
}
