import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CDataTable,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPagination,
  CRow,
  CSelect,
  CTooltip,
} from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { accountTypes } from '../constants/Options'
import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import ConfirmationModal from './modals/ConfirmationModal'
import StatementModal from './modals/StatementModal'

function FiscalAccounts(props) {
  const nameRef = useRef(null)
  const fyRef = useRef(null)
  const typeRef = useRef(null)
  const [fiscalAccounts, setFiscalAccounts] = useState([])
  const [fys, setFys] = useState([])
  const [closeAccounts, setCloseAccounts] = useState([])
  const [addedAccounts, setAddedAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [closing, setClosing] = useState(false)
  const [adding, setAdding] = useState(false)
  const [selectedFiscalAccount, setSelectedFiscalAccount] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [showStatementModal, setShowStatementModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showClosedModal, setShowClosedModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    content: '',
    confirmText: 'Delete',
    onConfirm: () => {},
  })

  const filterFiscalAccounts = async () => {
    await getFiscalAccounts()
  }

  const closeFiscalAccount = async (account) => {
    try {
      const response = await Backend.closeFiscalAccount(account.id)
      setShowConfirmModal(false)
      Notify.success('Fiscal account closed successfully.')
      await getFiscalAccounts()
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
    }
  }

  // tih that tur. API request tam ltk
  const closeAllFiscalAccount = async () => {
    setShowConfirmModal(false)
    setClosing(true)
    setShowClosedModal(true)

    try {
      let params = ''
      if (fyRef.current.value) {
        params += `?financial_year_id=${fyRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&account_type=${typeRef.current.value}`
      }

      const response = await Backend.getAllFiscalAccounts(params)
      if (response.data.length === 0) {
        setClosing(false)
        Notify.success('No accounts found.')
        return
      }

      let closed = closeAccounts

      for (let i = 0; i < response.data.length; i++) {
        // setTimeout(async () => {
        try {
          const res = await Backend.closeFiscalAccount(response.data[i].id)
          closed.unshift({ result: true, message: res.messages[0] })
          setCloseAccounts([...closed])
        } catch (error) {
          closed.unshift({ result: false, message: error.message })
          setCloseAccounts([...closed])
        }
        // }, 300)
      }
      setClosing(false)
      getFiscalAccounts()
      // Notify.success('Fiscal Accounts closed successfully.')
    } catch (error) {
      console.log(error)
      setClosing(false)
      Notify.error(error.message)
    }
  }

  const addAllFiscalAccount = async () => {
    setShowConfirmModal(false)
    setAdding(true)
    setShowAddModal(true)

    try {
      let params = {}
      if (fyRef.current.value) {
        params['financial_year_id'] = fyRef.current.value
      }
      if (fyRef.current.value) {
        params['account_type'] = typeRef.current.value
      }

      const response = await Backend.getAllEmployees(
        `?account_type=${typeRef.current.value}`
      )
      if (response.data.length === 0) {
        setAdding(false)
        Notify.success('No accounts found.')
        return
      }

      let added = addedAccounts

      for (let i = 0; i < response.data.length; i++) {
        // setTimeout(async () => {
        try {
          const res = await Backend.addEmployeeFiscalAccount(
            response.data[i].id,
            params
          )
          added.unshift({ result: true, message: res.messages[0] })
          setAddedAccounts([...added])
        } catch (error) {
          added.unshift({ result: false, message: error.message })
          setAddedAccounts([...added])
        }
        // }, 300)
      }
      setAdding(false)
      getFiscalAccounts()
      // Notify.success('Fiscal Accounts added successfully.')
    } catch (error) {
      console.log(error)
      setAdding(false)
      Notify.error(error.message)
    }
  }

  const getFiscalAccounts = async () => {
    if (!fyRef.current.value) return

    setLoading(true)

    try {
      let params = `?per_page=15&page=${currentPage}`
      if (fyRef.current.value) {
        params += `&financial_year_id=${fyRef.current.value}`
      }
      if (nameRef.current.value) {
        params += `&name=${nameRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&account_type=${typeRef.current.value}`
      }

      const response = await Backend.getFiscalAccounts(params)
      if (response.data) {
        setLastPage(response.data.last_page)
        setFiscalAccounts([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initFYs = async () => {
    try {
      const response = await Backend.getFYs()
      if (response.data) {
        setFys([...response.data])
        getCurrentFY()
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  const getCurrentFY = async () => {
    try {
      const response = await Backend.getCurrentFY()
      if (response.data) {
        fyRef.current.value = response.data.id
        getFiscalAccounts()
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  useEffect(() => {
    initFYs()
  }, [])

  useEffect(() => {
    getFiscalAccounts()
  }, [currentPage])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Fiscal Accounts</h4>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Fiscal Accounts</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol className={'col-3'}>
                  <CSelect
                    innerRef={fyRef}
                    onChange={() => filterFiscalAccounts()}
                  >
                    <option value=''>--Select FY--</option>
                    {fys.map((fy) => {
                      return (
                        <option value={fy.id}>
                          {fy.start_year} - {fy.end_year}
                        </option>
                      )
                    })}
                  </CSelect>
                </CCol>
                <CCol
                  className={'col-3'}
                  style={{
                    display:
                      AuthManager.getCurrentUser().role == 'admin'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <CSelect
                    innerRef={typeRef}
                    onChange={() => filterFiscalAccounts()}
                  >
                    <option value=''>--Select Type--</option>
                    {accountTypes.map((accountType) => {
                      return (
                        <option value={accountType.value}>
                          {accountType.label}
                        </option>
                      )
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-4'}>
                  <CInput
                    innerRef={nameRef}
                    name='name'
                    placeholder={'Search by Name...'}
                    onKeyUp={(evt) => {
                      if (evt.key === 'Enter' || !evt.target.value) {
                        getFiscalAccounts()
                      }
                    }}
                  />
                </CCol>
              </CRow>
              <CRow className={'mt-3'}>
                {AuthManager.getCurrentUser().role === 'admin' && (
                  <CCol className='text-right col-12'>
                    <CButton
                      color='dark'
                      className='btn-sm mb-2'
                      onClick={() => {
                        if (!typeRef.current.value) {
                          Notify.error('Select account type')
                          return
                        }

                        setConfirmModal({
                          ...confirmModal,
                          title: 'Close All Fiscal Accounts?',
                          content:
                            'Are you sure you want to close all Fiscal Accounts? This action is irreversible.',
                          confirmText: 'Close All Fiscal Accounts',
                          onConfirm: () => closeAllFiscalAccount(),
                        })
                        setShowConfirmModal(true)
                      }}
                    >
                      <CIcon name='cil-lock-locked' /> Close All Accounts
                      (Selected FY)
                    </CButton>

                    <CButton
                      color='dark'
                      className='btn-sm ml-2 mb-2'
                      onClick={() => {
                        if (!fyRef.current.value) {
                          Notify.error('Select financial year')
                          return
                        }

                        if (!typeRef.current.value) {
                          Notify.error('Select account type')
                          return
                        }

                        setConfirmModal({
                          ...confirmModal,
                          title: 'Add Fiscal Accounts?',
                          content:
                            "Are you sure you want to add Fiscal Accounts for selected FY and account Type? If Empoyees' previous fiscal account is not closed, New account will not be created. This action is irreversible.",
                          confirmText: 'Add Fiscal Accounts',
                          onConfirm: () => addAllFiscalAccount(),
                        })
                        setShowConfirmModal(true)
                      }}
                    >
                      <CIcon name='cil-plus' /> Add Employees' Fiscal Accounts
                    </CButton>
                  </CCol>
                )}
              </CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <CDataTable
                    items={fiscalAccounts}
                    striped
                    outlined={true}
                    fields={[
                      {
                        key: 'name',
                        label: 'Name',
                      },
                      {
                        key: 'code',
                        label: 'Account Code',
                      },
                      {
                        key: 'type',
                        label: 'Account Type',
                      },
                      {
                        key: 'opening_balance',
                        label: 'Opening Balance',
                      },
                      {
                        key: 'closing_balance',
                        label: 'Closing Balance',
                      },
                      {
                        key: 'status',
                        label: 'Status',
                      },
                      {
                        key: 'actions',
                        label: 'Actions',
                      },
                    ]}
                    scopedSlots={{
                      name: (item) => {
                        return <td>{item.name.toUpperCase()}</td>
                      },
                      code: (item) => {
                        return <td>{item.code.toUpperCase()}</td>
                      },
                      type: (item) => {
                        return <td>{item.type.toUpperCase()}</td>
                      },
                      opening_balance: (item) => {
                        return (
                          <td>{General.formatRupees(item.opening_balance)}</td>
                        )
                      },
                      closing_balance: (item) => {
                        return (
                          <td>{General.formatRupees(item.closing_balance)}</td>
                        )
                      },
                      actions: (item) => {
                        if (item.status == 'active') {
                          return (
                            <td>
                              <CButtonGroup className={'btn-sm'}>
                                <CTooltip
                                  content='View Statements'
                                  placement='bottom'
                                >
                                  <CButton
                                    color='success'
                                    variant='outline'
                                    className={'btn-sm'}
                                    onClick={() => {
                                      setSelectedFiscalAccount(item)
                                      setSelectedEmployee(item.account.employee)
                                      setShowStatementModal(true)
                                    }}
                                  >
                                    <CIcon name='cil-spreadsheet'></CIcon>
                                  </CButton>
                                </CTooltip>
                                <CTooltip
                                  content='Close Fiscal Account'
                                  placement='bottom'
                                >
                                  <CButton
                                    color='danger'
                                    variant='outline'
                                    className={'btn-sm'}
                                    onClick={() => {
                                      if (
                                        AuthManager.getCurrentUser().role !==
                                        'admin'
                                      ) {
                                        Notify.error(
                                          'Only admin user can close an Account'
                                        )
                                        return
                                      }

                                      setSelectedFiscalAccount(item)
                                      setConfirmModal({
                                        ...confirmModal,
                                        title: 'Close Fiscal Account?',
                                        content:
                                          'Are you sure you want to close this Fiscal Account? This action is irreversible.',
                                        confirmText: 'Close Fiscal Account',
                                        onConfirm: () =>
                                          closeFiscalAccount(item),
                                      })
                                      setShowConfirmModal(true)
                                    }}
                                  >
                                    <CIcon name='cil-lock-locked'></CIcon>
                                  </CButton>
                                </CTooltip>
                              </CButtonGroup>
                            </td>
                          )
                        } else {
                          return (
                            <td>
                              <CButtonGroup className={'btn-sm'}>
                                <CTooltip
                                  content='View Statements'
                                  placement='bottom'
                                >
                                  <CButton
                                    color='success'
                                    variant='outline'
                                    className={'btn-sm'}
                                    onClick={() => {
                                      setSelectedFiscalAccount(item)
                                      setSelectedEmployee(item.account.employee)
                                      setShowStatementModal(true)
                                    }}
                                  >
                                    <CIcon name='cil-spreadsheet'></CIcon>
                                  </CButton>
                                </CTooltip>
                                <CTooltip
                                  content='Account Closed'
                                  placement='bottom'
                                >
                                  <CButton
                                    color='dark'
                                    variant='outline'
                                    className={'btn-sm'}
                                    onClick={() => {
                                      // setSelectedFY(item)
                                      // setShowConfirmModal(true)
                                    }}
                                  >
                                    <CIcon name='cil-lock-locked'></CIcon>
                                  </CButton>
                                </CTooltip>
                              </CButtonGroup>
                            </td>
                          )
                        }
                      },
                    }}
                  />
                  <CPagination
                    className={'mt-2'}
                    activePage={currentPage}
                    pages={lastPage}
                    onActivePageChange={(i) => setCurrentPage(i)}
                  ></CPagination>
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showStatementModal && (
        <StatementModal
          show={showStatementModal}
          fiscalAccount={selectedFiscalAccount}
          employee={selectedEmployee}
          onClose={() => {
            setShowStatementModal(false)
            setSelectedFiscalAccount(null)
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmModal.title}
          content={() => (
            <>
              <CAlert color='danger' className='w-100'>
                {confirmModal.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          confirmText={confirmModal.confirmText}
          onConfirm={confirmModal.onConfirm}
        />
      )}

      {showClosedModal && (
        <CModal show={showClosedModal} closeOnBackdrop={false} centered={true}>
          <CModalHeader>
            <CModalTitle>Closed Accounts</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer fluid style={{ maxHeight: '500px', overflow: 'auto' }}>
              <CRow>
                {closeAccounts.length === 0 ? (
                  <CCol className={'col-12 text-center'}>
                    <Loading />
                  </CCol>
                ) : (
                  closeAccounts.map((closeAccount) => {
                    return (
                      <CAlert
                        color={closeAccount.result ? 'success' : 'danger'}
                        className={'w-100'}
                      >
                        {closeAccount.message}
                      </CAlert>
                    )
                  })
                )}
              </CRow>
            </CContainer>
          </CModalBody>
          <CModalFooter>
            <CButton
              disabled={closing}
              variant='outline'
              color='dark'
              className='btn-sm pl-4 pr-4'
              onClick={() => {
                setCloseAccounts([])
                setShowClosedModal(false)
              }}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}

      {showAddModal && (
        <CModal show={showAddModal} closeOnBackdrop={false} centered={true}>
          <CModalHeader>
            <CModalTitle>Add Accounts</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer fluid style={{ maxHeight: '500px', overflow: 'auto' }}>
              <CRow>
                {addedAccounts.length === 0 ? (
                  <CCol className={'col-12 text-center'}>
                    <Loading />
                  </CCol>
                ) : (
                  addedAccounts.map((addAccount) => {
                    return (
                      <CAlert
                        color={addAccount.result ? 'success' : 'danger'}
                        className={'w-100'}
                      >
                        {addAccount.message}
                      </CAlert>
                    )
                  })
                )}
              </CRow>
            </CContainer>
          </CModalBody>
          <CModalFooter>
            <CButton
              disabled={adding}
              variant='outline'
              color='dark'
              className='btn-sm pl-4 pr-4'
              onClick={() => {
                setAddedAccounts([])
                setShowAddModal(false)
              }}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}
    </>
  )
}

export default withRouter(FiscalAccounts)
