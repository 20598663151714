import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CSwitch
} from "@coreui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";
import CustomDatePicker from "../components/CustomDatePicker";

export default function FinancialYearModal(props) {
  const fyModel = {
    start_month: "",
    end_month: "",
    start_year: "",
    end_year: "",
    epf_cash_opening_balance: 0,
    epf_bank_opening_balance: 0,
    ess_cash_opening_balance: 0,
    ess_bank_opening_balance: 0,
    nps_cash_opening_balance: 0,
    nps_bank_opening_balance: 0,
    current: props.fy ? props.fy.current : true,
  };

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [fy, setFy] = useState(fyModel);

  function handleFormChange(evt) {
    setError("");
    let name = evt.target.name;
    let value = evt.target.value;
    setFy({ ...fy, [name]: value });
  }

  function validateData() {
    if (!fy.start_month || !fy.start_year) {
      setError("Select start of Financial year");
      return false;
    } else if (!fy.end_month || !fy.end_year) {
      setError("Select end of Financial year");
      return false;
    } else if (fy.start_year >= fy.end_year) {
      setError("End year of Financial year should be greater than start year");
      return false;
    }
    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      if (!props.fy) {
        const response = await Backend.addFY(fy);
        props.onAdded(response.data);
      } else {
        const response = await Backend.updateFY(fy.id, fy);
        props.onUpdated(response.data);
      }
      props.onClose();
      Notify.success("Financial added/updated successfully.");
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.fy) {
      setFy(props.fy);
    }

    return () => {};
  }, []);

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Financial Year</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color="danger" className="w-100">
                  {error}
                </CAlert>
              )}
              <CCol sm="12">
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CustomDatePicker
                      label={"Select start month/year"}
                      value={
                        props.fy
                          ? moment(
                              `${props.fy.start_month}-${props.fy.start_year}`,
                              "MM-YYYY"
                            ).format("DD/MM/YYYY")
                          : ""
                      }
                      onApply={(date) => {
                        setError("");
                        setFy({
                          ...fy,
                          start_month: parseInt(moment(date).format("MM")),
                          start_year: parseInt(moment(date).format("YYYY")),
                        });
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={"Select end month/year"}
                      value={
                        props.fy
                          ? moment(
                              `${props.fy.end_month}-${props.fy.end_year}`,
                              "MM-YYYY"
                            ).format("DD/MM/YYYY")
                          : ""
                      }
                      onApply={(date) => {
                        setError("");
                        setFy({
                          ...fy,
                          end_month: parseInt(moment(date).format("MM")),
                          end_year: parseInt(moment(date).format("YYYY")),
                        });
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>EPF Cash Opening Balance</CLabel>
                    <CInput
                      value={fy.epf_cash_opening_balance}
                      name={"epf_cash_opening_balance"}
                      type={"number"}
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>EPF Bank Opening Balance</CLabel>
                    <CInput
                      value={fy.epf_bank_opening_balance}
                      name={"epf_bank_opening_balance"}
                      type={"number"}
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>ESS Cash Opening Balance</CLabel>
                    <CInput
                      value={fy.ess_cash_opening_balance}
                      name={"ess_cash_opening_balance"}
                      type={"number"}
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>ESS Bank Opening Balance</CLabel>
                    <CInput
                      value={fy.ess_bank_opening_balance}
                      name={"ess_bank_opening_balance"}
                      type={"number"}
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>NPS Cash Opening Balance</CLabel>
                    <CInput
                      value={fy.nps_cash_opening_balance}
                      name={"nps_cash_opening_balance"}
                      type={"number"}
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>NPS Bank Opening Balance</CLabel>
                    <CInput
                      value={fy.nps_bank_opening_balance}
                      name={"nps_bank_opening_balance"}
                      type={"number"}
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Active: </CLabel>

                    <CSwitch
                      className="text-right ml-2"
                      checked={fy.current}
                      name={"current"}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setFy({ ...fy, current: !fy.current });
                      }}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color="dark"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component="span" size="sm" aria-hidden="true" />{" "}
                Saving...
              </>
            ) : (
              "Add/Update Financial Year"
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
