import React, { useContext, useState, useEffect } from 'react'
import AuthManager from '../utils/AuthManager'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(AuthManager.currentUser)
  const [sidebarShow, setSidebarShow] = useState('responsive')

  function login(username, password) {
    return AuthManager.login(username, password)
  }

  function logout() {
    return AuthManager.logout()
  }

  function setToggleSidebar(val) {
    setSidebarShow(val)
  }

  useEffect(() => {
    AuthManager.silentLogin()
      .then((data) => {
        setCurrentUser(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setCurrentUser(null)
      })
  }, [])

  const values = {
    currentUser,
    sidebarShow,
    login,
    logout,
    setToggleSidebar,
    setCurrentUser,
  }

  return (
    <AuthContext.Provider value={values}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
