import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner
} from "@coreui/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { months } from "../../constants/Options";
import Backend from "../../utils/Backend";
import General from "../../utils/General";
import Notify from "../../utils/Notify";

export default function ContributionModal(props) {
  const fiscalAccount = props.fiscalAccount;
  const monthRef = useRef(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [contribution, setContribution] = useState({
    fiscal_account_id: fiscalAccount.id,
    month: fiscalAccount.financial_year.start_month,
    year: fiscalAccount.financial_year.start_year,
    amount: 0.0,
    interest_rate: 0.0,
  });
  const [contributions, setContributions] = useState([]);

  function handleFormChange(evt) {
    setError("");
    let name = evt.target.name;
    let value = evt.target.value;
    if (name === "amount" || name === "interest_rate") {
      value = parseFloat(parseFloat(value).toFixed(2));
    }

    setContribution({ ...contribution, [name]: value });
  }

  function validateData() {
    if (!contribution.fiscal_account_id) {
      setError("Select Fiscal Account");
      return false;
    } else if (!contribution.month) {
      setError("Select Month");
      monthRef.current.focus();
      return false;
    } else if (!contribution.year) {
      setError("Select Year");
      return false;
    } else if (isNaN(contribution.amount) || contribution.amount < 1) {
      setError("Contribution amount must be number and greater than Zero");
      return false;
    } else if (isNaN(contribution.interest_rate)) {
      setError("Interest rate must be number");
      return false;
    }

    if (
      !General.validateContributionDate(
        contribution,
        fiscalAccount.financial_year
      )
    ) {
      setError(
        "Please select month and year between selected Fiscal Account(Financial Year)"
      );
      return false;
    }

    setError("");
    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      const response = await Backend.addContribution(contribution);
      Notify.success("Contribution added successfully.");
      setContribution({ ...contribution, month: "" });
      contributions.push(response.data);
      setContributions([...contributions]);
      setLoading(false);
      monthRef.current.focus();
    } catch (error) {
      setError(error.message);
      monthRef.current.focus();
      setLoading(false);
    }
  }

  const deleteContribution = async (item) => {
    setDeleting(true);
    try {
      await Backend.deleteContribution(item.id);
      setContributions([
        ...contributions.filter((contribution) => {
          if (item.id !== contribution.id) {
            return contribution;
          }
        }),
      ]);
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
      Notify.error(error.message);
    }
  };

  const initContributions = async () => {
    try {
      const response = await Backend.fiscalAccountData(
        fiscalAccount.id,
        "contributions"
      );

      if (response.data) {
        setContributions([...response.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initInterestRate = async () => {
    try {
      const response = await Backend.getSetting(
        `${fiscalAccount.account.type}_interest_rate`
      );
      if (response.data) {
        setContribution({
          ...contribution,
          interest_rate: parseFloat(response.data.value).toFixed(2),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initInterestRate();
    initContributions();
    return () => {};
  }, []);

  // useEffect(() => {
  //   // init current fiscal fiscalAccount
  //   if (fiscalAccounts.length && currentFY) {
  //     fiscalAccounts.forEach(fa => {
  //       if (fa.financial_year_id == currentFY.id) {
  //         setContribution({ ...contribution, fiscal_account_id: fa.id })
  //       }
  //     })
  //   }
  // }, [fiscalAccounts, currentFY])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={"lg"}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>
            Add Contribution - {fiscalAccount.account.type.toUpperCase()}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol sm="6">
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>Select Fiscal Account</CLabel>
                    <CSelect
                      value={contribution.fiscal_account_id}
                      name="fiscal_account_id"
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    >
                      <option value={fiscalAccount.id}>
                        {General.getFiscalAccountFY(fiscalAccount)}
                      </option>
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Month</CLabel>
                    <CSelect
                      innerRef={monthRef}
                      value={contribution.month}
                      type="text"
                      name="month"
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option value={month.value}>{month.label}</option>
                      ))}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Year</CLabel>
                    <CSelect
                      value={contribution.year}
                      type="text"
                      name="year"
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value="">Select Year</option>
                      <option value={fiscalAccount.financial_year.start_year}>
                        {fiscalAccount.financial_year.start_year}
                      </option>
                      )
                      <option value={fiscalAccount.financial_year.end_year}>
                        {fiscalAccount.financial_year.end_year}
                      </option>
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Amount</CLabel>
                    <CInput
                      value={contribution.amount}
                      type="number"
                      name="amount"
                      min={0}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Interest Rate (pa)</CLabel>
                    <CInput
                      value={contribution.interest_rate}
                      type="number"
                      name="interest_rate"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                </CForm>
                {error && <CAlert color={"danger"}>{error}</CAlert>}
                <CRow className={"text-right"}>
                  <CCol>
                    <CButton
                      color="dark"
                      className="btn-sm pl-4 pr-4"
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      {loading ? (
                        <>
                          <CSpinner
                            component="span"
                            size="sm"
                            aria-hidden="true"
                          />{" "}
                          Saving...
                        </>
                      ) : (
                        "Add Contribution"
                      )}
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="6">
                <h5>Contributions</h5>
                {contributions.map((contribution) => {
                  return (
                    <div>
                      <CAlert color={"info"} className={"mb-1"}>
                        <CRow>
                          <CCol sm={"10"}>
                            {moment(`${contribution.date}`).format("MMMM YYYY")}
                            : &nbsp;
                            {General.formatRupees(contribution.amount)} -{" "}
                            {parseFloat(
                              contribution.interest_rate / 12
                            ).toFixed(2)}
                            %
                          </CCol>
                          <CCol sm={"2"}>
                            {fiscalAccount.status === "active" && !deleting && (
                              <CIcon
                                onClick={() => {
                                  deleteContribution(contribution);
                                }}
                                name="cil-trash"
                                className="trash icon-btn"
                              ></CIcon>
                            )}
                          </CCol>
                        </CRow>
                      </CAlert>
                    </div>
                  );
                })}
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
