import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
  CSwitch,
  CTextarea,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { accountTypes } from '../../constants/Options'
import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'
import CustomDatePicker from '../components/CustomDatePicker'

export default function PaymentModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [accountHeads, setAccountHeads] = useState(props.accountHeads)
  const [payment, setPayment] = useState({
    id: '',
    type: 'Cash',
    account_type:
      AuthManager.getCurrentUser().role != 'admin'
        ? AuthManager.getCurrentUser().role.toUpperCase()
        : '',
    date: moment().format('YYYY-MM-DD'),
    voucher_no: '',
    bank_ref_no: '',
    contra_bank: '',
    particulars: '',
    purpose: '',
    account_head_id: '',
    amount: '',
    status: 'Paid',
    note: '',
  })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name
    let value = evt.target.value
    if (name == 'amount') {
      value = parseFloat(parseFloat(value).toFixed(2))
    }

    setPayment({ ...payment, [name]: value })
  }

  function validateData() {
    // if (!payment.fiscal_account_id) {
    //   setError('Select Fiscal Account')
    //   return false;
    // } else if (!payment.month) {
    //   setError('Select Month')
    //   monthRef.current.focus()
    //   return false;
    // } else if (!payment.year) {
    //   setError('Select Year')
    //   return false;
    // } else if (isNaN(payment.amount) || payment.amount < 1) {
    //   setError('Payment amount must be number and greater than Zero')
    //   return false;
    // } else if (isNaN(payment.interest_rate) || payment.interest_rate < 1) {
    //   setError('Interest rate must be number and greater than Zero')
    //   return false;
    // }

    // if (!General.validateContributionDate(payment, fiscalAccount.financial_year)) {
    //   setError('Please select month and year between selected Fiscal Account(Financial Year)')
    //   return false
    // }

    setError('')
    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (!props.payment) {
        const response = await Backend.addPayment(payment)
        Notify.success('Payment added successfully.')
        props.onAdded()
      } else {
        const response = await Backend.updatePayment(payment.id, payment)
        Notify.success('Payment updated successfully.')
        props.onAdded()
      }
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.payment) {
      setPayment(props.payment)
    }
  }, [])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'md'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>{props.payment ? 'Edit' : 'Add'} Payment </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <CFormGroup>
                    <CLabel>Type: </CLabel>
                    <br />
                    <CLabel className={'pl-0'}>Cash</CLabel>
                    <CSwitch
                      disabled={
                        props.payment && props.payment.type === 'Contra'
                      }
                      className="text-right ml-1"
                      checked={payment.type == 'Cash'}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setError('')
                        setPayment({ ...payment, type: 'Cash', purpose: '' })
                      }}
                    />
                    <CLabel className={'pl-4'}>Bank</CLabel>
                    <CSwitch
                      disabled={
                        props.payment && props.payment.type === 'Contra'
                      }
                      className="text-right mt-2 ml-1"
                      checked={payment.type == 'Bank'}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setError('')
                        setPayment({ ...payment, type: 'Bank', purpose: '' })
                      }}
                    />
                    <CLabel className={'pl-4'}>Contra Entry</CLabel>
                    <CSwitch
                      disabled={
                        props.payment && props.payment.type !== 'Contra'
                      }
                      className="text-right mt-2 ml-1"
                      checked={payment.type == 'Contra'}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setError('')
                        setPayment({
                          ...payment,
                          type: 'Contra',
                          purpose: 'Withdraw',
                        })
                      }}
                    />
                  </CFormGroup>

                  {payment.type != 'Contra' && (
                    <>
                      <CFormGroup>
                        <CLabel>Voucher No</CLabel>
                        <CInput
                          value={payment.voucher_no}
                          type="text"
                          name="voucher_no"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </CFormGroup>
                      {payment.type == 'Bank' && (
                        <CFormGroup>
                          <CLabel>Bank Reference No</CLabel>
                          <CInput
                            value={payment.bank_ref_no}
                            type="text"
                            name="bank_ref_no"
                            onChange={(e) => handleFormChange(e)}
                          />
                        </CFormGroup>
                      )}
                      <CFormGroup>
                        <CLabel>Particulars</CLabel>
                        <CInput
                          value={payment.particulars}
                          type="text"
                          name="particulars"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Purpose</CLabel>
                        <CInput
                          value={payment.purpose}
                          type="text"
                          name="purpose"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CustomDatePicker
                          label={'Date'}
                          value={
                            props.payment
                              ? props.payment.date
                              : moment().format('YYYY-MM-DD')
                          }
                          onApply={(date) => {
                            setError('')
                            setPayment({
                              ...payment,
                              date: moment(date).format('YYYY-MM-DD'),
                            })
                          }}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Account Head</CLabel>
                        <CSelect
                          value={payment.account_head_id}
                          name="account_head_id"
                          onChange={(e) => {
                            handleFormChange(e)
                          }}
                        >
                          <option value="">Select Account Head</option>
                          {accountHeads.map((accountHead) => {
                            if (accountHead.type == 'Payment') {
                              return (
                                <option value={accountHead.id}>
                                  {accountHead.name}
                                </option>
                              )
                            }
                          })}
                        </CSelect>
                      </CFormGroup>
                      <CFormGroup
                        style={{
                          display:
                            AuthManager.getCurrentUser().role == 'admin'
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <CLabel>Account Type</CLabel>
                        <CSelect
                          value={payment.account_type}
                          name="account_type"
                          onChange={(e) => {
                            handleFormChange(e)
                          }}
                        >
                          <option value="">Select Account Type</option>
                          {accountTypes.map((accountType) => {
                            return (
                              <option value={accountType.value}>
                                {accountType.label}
                              </option>
                            )
                          })}
                        </CSelect>
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Amount</CLabel>
                        <CInput
                          value={payment.amount}
                          type="number"
                          name="amount"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Notes</CLabel>
                        <CTextarea
                          name={'note'}
                          onChange={(e) => handleFormChange(e)}
                        >
                          {payment.note}
                        </CTextarea>
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Status: </CLabel>
                        <CSwitch
                          className="text-right ml-5"
                          checked={payment.status == 'Paid'}
                          color="dark"
                          size="lg"
                          variant="3d"
                          shape="pill"
                          onChange={(e) => {
                            setPayment({ ...payment, status: 'Paid' })
                          }}
                        />
                        <CLabel className={'pl-2'}>Paid</CLabel>
                        <CSwitch
                          className="text-right mt-2 ml-5"
                          checked={payment.status == 'Pending'}
                          color="dark"
                          size="lg"
                          variant="3d"
                          shape="pill"
                          onChange={(e) => {
                            setPayment({ ...payment, status: 'Pending' })
                          }}
                        />
                        <CLabel className={'pl-2'}>Pending</CLabel>
                      </CFormGroup>
                    </>
                  )}

                  {payment.type == 'Contra' && (
                    <>
                      <CAlert color={'info'}>
                        Hemi entry i siam rual hian payment lamah contra entry a
                        in siam ve nghal a, chuvangin payment lamah contra turin
                        enter nawn a ngai lo.
                      </CAlert>
                      <CFormGroup>
                        <CLabel>Purpose: </CLabel>
                        <br />
                        <CLabel className={'pl-0'}>Withdraw</CLabel>
                        <CSwitch
                          className="text-right ml-1"
                          checked={payment.purpose == 'Withdraw'}
                          color="dark"
                          size="lg"
                          variant="3d"
                          shape="pill"
                          onChange={(e) => {
                            setPayment({ ...payment, purpose: 'Withdraw' })
                          }}
                        />
                        <CLabel className={'pl-3'}>Deposit</CLabel>
                        <CSwitch
                          className="text-right mt-2 ml-1"
                          checked={payment.purpose == 'Deposit'}
                          color="dark"
                          size="lg"
                          variant="3d"
                          shape="pill"
                          onChange={(e) => {
                            setPayment({ ...payment, purpose: 'Deposit' })
                          }}
                        />
                        <CLabel className={'pl-3'}>Bank Transfer</CLabel>
                        <CSwitch
                          className="text-right mt-2 ml-1"
                          checked={payment.purpose == 'Bank Transfer'}
                          color="dark"
                          size="lg"
                          variant="3d"
                          shape="pill"
                          onChange={(e) => {
                            setPayment({
                              ...payment,
                              purpose: 'Bank Transfer',
                            })
                          }}
                        />
                      </CFormGroup>
                      {payment.purpose === 'Bank Transfer' ? (
                        <>
                          <CFormGroup>
                            <CLabel>From Bank</CLabel>
                            <CInput
                              value={payment.from_contra_bank}
                              type="text"
                              name="from_contra_bank"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </CFormGroup>
                          <CFormGroup>
                            <CLabel>To Bank</CLabel>
                            <CInput
                              value={payment.to_contra_bank}
                              type="text"
                              name="to_contra_bank"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </CFormGroup>
                        </>
                      ) : (
                        <CFormGroup>
                          <CLabel>Bank</CLabel>
                          <CInput
                            value={payment.contra_bank}
                            type="text"
                            name="contra_bank"
                            onChange={(e) => handleFormChange(e)}
                          />
                        </CFormGroup>
                      )}

                      <CFormGroup>
                        <CustomDatePicker
                          label={'Date'}
                          value={
                            props.payment
                              ? moment(props.payment.date).format('DD/MM/YYYY')
                              : moment().format('DD/MM/YYYY')
                          }
                          onApply={(date) => {
                            setError('')
                            setPayment({
                              ...payment,
                              date: moment(date).format('YYYY-MM-DD'),
                            })
                          }}
                        />
                      </CFormGroup>

                      <CFormGroup
                        style={{
                          display:
                            AuthManager.getCurrentUser().role == 'admin'
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <CLabel>Account Type</CLabel>
                        <CSelect
                          value={payment.account_type}
                          name="account_type"
                          onChange={(e) => {
                            handleFormChange(e)
                          }}
                        >
                          <option value="">Select Account Type</option>
                          {accountTypes.map((accountType) => {
                            return (
                              <option value={accountType.value}>
                                {accountType.label}
                              </option>
                            )
                          })}
                        </CSelect>
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Amount</CLabel>
                        <CInput
                          value={payment.amount}
                          type="number"
                          name="amount"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </CFormGroup>
                    </>
                  )}
                </CForm>
                {error && <CAlert color={'danger'}>{error}</CAlert>}
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
          <CButton
            color="dark"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {loading ? (
              <>
                <CSpinner component="span" size="sm" aria-hidden="true" />{' '}
                Saving...
              </>
            ) : (
              'Save Payment'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
