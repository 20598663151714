import {
  CButton,
  CCol,
  CContainer,
  CDataTable,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Backend from '../../utils/Backend'
import General from '../../utils/General'
import Loading from '../components/Loading'
import { PDFExport } from '@progress/kendo-react-pdf'
import { jsPDF } from 'jspdf'
import Notify from '../../utils/Notify'
import AuthManager from '../../utils/AuthManager'

export default function StatementModal(props) {
  const doc = new jsPDF({
    unit: 'mm',
  })
  const pdfComponent = useRef(null)
  const fiscalAccount = props.fiscalAccount
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [fiscalData, setFiscalData] = useState(null)
  const [signatories, setSignatories] = useState([])
  const [selectedSignatory, setSelectedSignatory] = useState(null)

  const getFiscalData = async () => {
    setLoading(true)
    try {
      const response = await Backend.fiscalAccountStatements(fiscalAccount.id)
      if (response.data) {
        setFiscalData({ ...response.data })
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setError(error.message)
      setLoading(false)
    }
  }

  const getSignatories = async () => {
    try {
      const response = await Backend.getSignatories()
      if (response.data) {
        setSignatories([...response.data])
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const handleExportWithComponent = (event) => {
  //   if (!selectedSignatory) {
  //     Notify.error('Please select Signatory')
  //     return
  //   }
  //   pdfComponent.current.save()
  // }

  const exportPDF = async () => {
    if (!selectedSignatory) {
      Notify.error('Please select Signatory')
      return
    }

    setExporting(true)

    try {
      let data = {}
      data['headers'] = AuthManager.getHeaders()
      const response = await fetch(
        `${window.Api.Base}/fiscal-accounts/${fiscalAccount.id}/pdf-statements?signatory_id=${selectedSignatory.id}`,
        data
      )
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    } catch (error) {
      Notify.error(error.message)
    }
    setExporting(false)
  }

  useEffect(() => {
    getFiscalData()
    getSignatories()
  }, [])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'xl'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>
            Fiscal Account (
            {moment(fiscalAccount.financial_year.start).format('MM/YYYY')} -{' '}
            {moment(fiscalAccount.financial_year.end).format('MM/YYYY')})
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol className={'text-center'}>
              <CFormGroup
                className={'col-4'}
                style={{ display: 'inline-block' }}
              >
                <CSelect
                  value={selectedSignatory && selectedSignatory.id}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setSelectedSignatory(null)
                      return
                    }
                    let signatory = signatories.find(
                      (item) => item.id == e.target.value
                    )
                    if (signatory !== undefined) {
                      setSelectedSignatory(signatory)
                    }
                  }}
                >
                  <option value=''>-- Select Signatory --</option>
                  {signatories.map((signatory) => {
                    return (
                      <option value={signatory.id}>{signatory.name}</option>
                    )
                  })}
                </CSelect>
              </CFormGroup>
              <CFormGroup style={{ display: 'inline-block' }}>
                <CButton
                  disabled={exporting}
                  className={''}
                  color={'dark'}
                  onClick={() => exportPDF()}
                >
                  {exporting ? 'Exporting PDF...' : 'Export PDF'}
                </CButton>
              </CFormGroup>
            </CCol>
          </CRow>

          <PDFExport
            scale={0.7}
            paperSize='A4'
            margin='1.5cm'
            fileName={'statements.pdf'}
            ref={pdfComponent}
          >
            {/* <CButton className={' btn-sm'} color={'dark'}
            onClick={() => {
              doc.setDisplayMode(1, 'single');
              doc.html(document.getElementById('pdf'), {
                callback: function (doc) {
                  doc.save(`${props.employee.name}-statements.pdf`);
                },
                // margin: [150, 150, 150, 150],
                x: 5,
                y: 10,
                margin: 20,
                html2canvas: { scale: 0.25 },
              });
            }}>Export PDF</CButton> */}
            <CContainer id={'pdf'} className={'mt-4'}>
              <CRow>
                <CCol className={'text-center'}>
                  <h3>BAPTIST CHURCH OF MIZORAM</h3>
                  <h4>{fiscalAccount.account.type.toUpperCase()} ACCOUNT</h4>
                  <h5>
                    {moment(fiscalAccount.financial_year.start).format(
                      'MMMM YYYY'
                    )}{' '}
                    -{' '}
                    {moment(fiscalAccount.financial_year.end).format(
                      'MMMM YYYY'
                    )}
                  </h5>
                </CCol>
              </CRow>
              <CRow className={'mt-3'}>
                <CCol>
                  <h5>Account Details: </h5>
                  <dl className='row'>
                    <dt className='col-sm-6'>Name : </dt>
                    <dd className='col-sm-6'>{props.employee.name}</dd>
                    <dt className='col-sm-6'>Code : </dt>
                    <dd className='col-sm-6'>{fiscalAccount.account.code}</dd>
                    <dt className='col-sm-6'>Type :</dt>
                    <dd className='col-sm-6'>
                      {fiscalAccount.account.type.toUpperCase()}
                    </dd>
                    <dt className='col-sm-6'>Financial Year :</dt>
                    <dd className='col-sm-6'>
                      {moment(fiscalAccount.financial_year.start).format(
                        'MMMM YYYY'
                      )}{' '}
                      -{' '}
                      {moment(fiscalAccount.financial_year.end).format(
                        'MMMM YYYY'
                      )}
                    </dd>
                    <dt className='col-sm-6'>Opening Balance :</dt>
                    <dd className='col-sm-6'>
                      {fiscalData
                        ? General.formatRupees(
                            fiscalAccount.opening_balance,
                            false
                          )
                        : '0.00'}
                    </dd>
                    <dt className='col-sm-6'>Total Contributions :</dt>
                    <dd className='col-sm-6'>
                      {fiscalData
                        ? General.formatRupees(
                            fiscalData.total_contributions,
                            false
                          )
                        : '0.00'}
                    </dd>
                    <dt className='col-sm-6'>Total Interests :</dt>
                    <dd className='col-sm-6'>
                      {fiscalData
                        ? General.formatRupees(
                            fiscalData.total_interests,
                            false
                          )
                        : '0.00'}
                    </dd>
                    <dt className='col-sm-6'>Total Loan Recoveries :</dt>
                    <dd className='col-sm-6'>
                      {fiscalData
                        ? General.formatRupees(
                            fiscalData.total_recoveries,
                            false
                          )
                        : '0.00'}
                    </dd>
                    <dt className='col-sm-6'>Total Advance Payments :</dt>
                    <dd className='col-sm-6'>
                      {fiscalData
                        ? General.formatRupees(
                            fiscalData.total_advance_payments,
                            false
                          )
                        : '0.00'}
                    </dd>
                    <dt className='col-sm-6'>Total Loans :</dt>
                    <dd className='col-sm-6'>
                      {fiscalData
                        ? General.formatRupees(fiscalData.total_loans, false)
                        : '0.00'}
                    </dd>
                    <dt className='col-sm-6'>Closing Balance :</dt>
                    <dd className='col-sm-6'>
                      {fiscalData
                        ? General.formatRupees(
                            fiscalData.closing_balance,
                            false
                          )
                        : '0.00'}
                    </dd>
                  </dl>
                  <h5>Account statements: </h5>
                  {!fiscalData ? (
                    <Loading />
                  ) : (
                    <CDataTable
                      items={fiscalData.statements}
                      itemsPerPage={100}
                      pagination
                      outlined={true}
                      fields={[
                        {
                          key: 'month',
                          label: 'Month',
                        },
                        {
                          key: 'contribution',
                          label: 'Contribution',
                        },
                        {
                          key: 'bcm_matching',
                          label: 'BCM Matching',
                        },
                        {
                          key: 'advance_payments',
                          label: 'Advance Payment',
                        },
                        {
                          key: 'loans',
                          label: 'Loan',
                        },
                        {
                          key: 'loan_recoveries',
                          label: 'Recovery',
                        },
                        {
                          key: 'monthly_interest_rate',
                          label: 'Monthly Interest Rate',
                        },
                        {
                          key: 'ibb',
                          label: 'IBB',
                        },
                        {
                          key: 'progressive_interest',
                          label: 'Interest',
                        },
                        {
                          key: 'progressive_balance',
                          label: 'Net Balance',
                        },
                      ]}
                      scopedSlots={{
                        month: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.month === 'Total' ||
                              item.month === 'Opening Balance'
                                ? item.month
                                : moment(
                                    `01/${item.month}`,
                                    'DD/MM/YYYY'
                                  ).format('MMM YYYY')}
                            </td>
                          )
                        },
                        contribution: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.contribution
                                ? General.formatRupees(item.contribution, false)
                                : ''}
                            </td>
                          )
                        },
                        bcm_matching: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.bcm_matching
                                ? General.formatRupees(item.bcm_matching, false)
                                : ''}
                            </td>
                          )
                        },
                        ibb: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {General.formatRupees(item.ibb, false)}
                            </td>
                          )
                        },
                        progressive_interest: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.progressive_interest
                                ? General.formatRupees(
                                    item.progressive_interest,
                                    false
                                  )
                                : ''}
                            </td>
                          )
                        },
                        progressive_balance: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.progressive_balance
                                ? General.formatRupees(
                                    item.progressive_balance,
                                    false
                                  )
                                : ''}
                            </td>
                          )
                        },
                        advance_payments: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.advance_payments
                                ? General.formatRupees(
                                    item.advance_payments,
                                    false
                                  )
                                : ''}
                            </td>
                          )
                        },
                        loans: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.loans
                                ? General.formatRupees(item.loans, false)
                                : ''}
                            </td>
                          )
                        },
                        loan_recoveries: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.loan_recoveries
                                ? General.formatRupees(
                                    item.loan_recoveries,
                                    false
                                  )
                                : ''}
                            </td>
                          )
                        },
                        monthly_interest_rate: (item) => {
                          return (
                            <td
                              className={
                                item.month === 'Total' ? 'text-bold' : ''
                              }
                            >
                              {item.monthly_interest_rate
                                ? parseFloat(
                                    item.monthly_interest_rate * 100
                                  ).toFixed(2) + '%'
                                : ''}
                            </td>
                          )
                        },
                      }}
                    />
                  )}
                  <dl className='row text-right'>
                    <dt className='col-sm-6'></dt>
                    <dt className='col-sm-3'>Closing Balance :</dt>
                    <dd className='col-sm-3 text-bold'>
                      {fiscalData
                        ? General.formatRupees(
                            fiscalData.closing_balance,
                            false
                          )
                        : '0.00'}
                    </dd>
                  </dl>
                  {selectedSignatory != null && (
                    <CRow className={'mt-3'}>
                      <CCol className={'text-center'}>
                        <img
                          src={`data:image/png;base64,${selectedSignatory.base64}`}
                          style={{ width: '100px', height: 'auto' }}
                          alt=''
                        />
                        <h5>{selectedSignatory.name}</h5>
                        <h5>{selectedSignatory.designation}</h5>
                      </CCol>
                    </CRow>
                  )}
                </CCol>
              </CRow>
            </CContainer>
          </PDFExport>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
