import CIcon from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Backend from '../../utils/Backend'
import General from '../../utils/General'
import Notify from '../../utils/Notify'
import CustomDatePicker from '../components/CustomDatePicker'
import EditRecoveryModal from './EditRecoveryModal'

export default function LoanPaymentModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [loan, setLoan] = useState(props.loan)
  const [loanRecoveries, setLoanRecoveries] = useState([])
  const [selectedRecovery, setSelectedRecovery] = useState(null)
  const [showEditLoanModal, setShowEditRecoveryModal] = useState(false)

  const setPaymentDate = async (item, value) => {
    let items = loanRecoveries.map((recovery) => {
      if (item.id === recovery.id) {
        return {
          ...item,
          recovered_amount: item.total,
          recovered_at: value,
        }
      }
      return recovery
    })

    setLoanRecoveries([...items])
  }

  function changeRecoveryAmount(e, index) {
    let updatedRecoveries = loanRecoveries.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          total: parseFloat(e.target.value) ? parseFloat(e.target.value) : '',
        }
      }

      return item
    })

    setLoanRecoveries([...updatedRecoveries])
  }

  const payEmi = async (item) => {
    setLoading(true)

    try {
      // const response =
      await Backend.payLoanRecovery(item.id, {
        ...item,
        recovered_amount: item.total,
        status: 'paid',
      })
      Notify.success('EMI paid successfully.')
      initLoan()
      setLoading(false)
    } catch (error) {
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initLoan = async () => {
    try {
      const response = await Backend.getLoan(props.loan.id)
      if (response.data) {
        setLoan({ ...response.data })
        setLoanRecoveries([...response.data.loan_recoveries])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initLoan()
    // return () => {
    // }
  }, [])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'lg'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Loan Payment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol>
                <h5>Loan Details: </h5>
                <dl className="row">
                  <dt className="col-sm-6">Amount : </dt>
                  <dd className="col-sm-6">
                    {General.formatRupees(loan.amount)}
                  </dd>
                  <dt className="col-sm-6">Total Interest :</dt>
                  <dd className="col-sm-6">
                    {General.formatRupees(loan.total_interest)} @
                    {loan.interest_rate}% pa
                  </dd>
                  <dt className="col-sm-6">Total Loan Amount :</dt>
                  <dd className="col-sm-6">
                    {General.formatRupees(loan.total)}
                  </dd>
                  <dt className="col-sm-6">Total Loan Balance :</dt>
                  <dd className="col-sm-6">
                    {General.formatRupees(loan.balance)}
                  </dd>
                  <dt className="col-sm-6">Number of EMIs :</dt>
                  <dd className="col-sm-6">{loan.no_of_emi} </dd>
                  <dt className="col-sm-6">Number of Paid EMIs :</dt>
                  <dd className="col-sm-6">{loan.recovered_emi}</dd>
                  <dt className="col-sm-6">Disbursement Date :</dt>
                  <dd className="col-sm-6">
                    {moment(loan.disbursed_at).format('DD/MM/YYYY')}
                  </dd>
                </dl>
                <h5>EMI Details: </h5>
                {loanRecoveries.length > 0 &&
                  loanRecoveries.map((recovery, index) => {
                    return (
                      <div>
                        <CAlert
                          color={
                            recovery.recovered_at && recovery.status === 'paid'
                              ? 'success'
                              : 'dark'
                          }
                          className={'mb-1'}
                        >
                          <CRow>
                            <CCol sm={'12'}>
                              {recovery.recovered_at &&
                                recovery.status === 'paid' && (
                                  <>
                                    <dl className="row mb-0">
                                      <dt className="col-sm-3">
                                        Payment Date :
                                      </dt>
                                      <dd className="col-sm-3">
                                        {moment(recovery.recovered_at).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </dd>
                                      <dt className="col-sm-3">EMI Date :</dt>
                                      <dd className="col-sm-3">
                                        {moment(recovery.due_at).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </dd>
                                      <dt className="col-sm-3">
                                        Paid Amount :
                                      </dt>
                                      <dd className="col-sm-3">
                                        {General.formatRupees(
                                          recovery.recovered_amount
                                        )}
                                      </dd>
                                      <dt className="col-sm-3">Due Amount :</dt>
                                      <dd className="col-sm-3">
                                        {General.formatRupees(
                                          recovery.recovered_amount
                                        )}
                                      </dd>
                                    </dl>
                                    <div className={'text-right'}>
                                      <CButton
                                        color="danger"
                                        className={'btn-sm'}
                                        onClick={() => {
                                          setSelectedRecovery(recovery)
                                          setShowEditRecoveryModal(true)
                                        }}
                                      >
                                        <CIcon name="cil-pencil" /> Edit
                                      </CButton>
                                    </div>
                                  </>
                                )}
                              {loan.foreclose === 1 &&
                                loan.status === 'closed' && (
                                  <>
                                    <dl className="row mb-0">
                                      <dt className="col-sm-3">
                                        EMI/Due Date :
                                      </dt>
                                      <dd className="col-sm-3">
                                        {moment(recovery.due_at).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </dd>
                                      <dt className="col-sm-3">Due Amount :</dt>
                                      <dd className="col-sm-3">
                                        {General.formatRupees(recovery.total)}
                                      </dd>
                                    </dl>
                                  </>
                                )}
                              {loan.foreclose === 0 &&
                                loan.status !== 'closed' &&
                                recovery.status === 'pending' && (
                                  <>
                                    <dl className="row mb-0">
                                      <dt className="col-sm-3">
                                        EMI/Due Date :
                                      </dt>
                                      <dd className="col-sm-3">
                                        {moment(recovery.due_at).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </dd>
                                      <dt className="col-sm-3">Due Amount :</dt>
                                      <dd className="col-sm-3">
                                        {General.formatRupees(
                                          parseFloat(recovery.emi_amount) +
                                            parseFloat(recovery.emi_interest)
                                        )}
                                      </dd>
                                    </dl>
                                    <CRow>
                                      <CCol>
                                        <CFormGroup>
                                          <CustomDatePicker
                                            label={'Payment Date'}
                                            value={''}
                                            onApply={(date) => {
                                              setError('')
                                              setPaymentDate(
                                                recovery,
                                                moment(date).format(
                                                  'YYYY-MM-DD'
                                                )
                                              )
                                            }}
                                          />
                                        </CFormGroup>
                                      </CCol>
                                      <CCol>
                                        <CFormGroup>
                                          <CLabel>Amount</CLabel>
                                          <CInput
                                            value={recovery.total}
                                            type="number"
                                            min={0}
                                            // readOnly={true}
                                            onChange={(e) => {
                                              changeRecoveryAmount(e, index)
                                            }}
                                          />
                                        </CFormGroup>
                                      </CCol>
                                      <CCol>
                                        <CFormGroup>
                                          <CLabel className={'w-100'}>
                                            &nbsp;
                                          </CLabel>
                                          <CButton
                                            color="dark"
                                            className="btn-sm pl-4 pr-4"
                                            disabled={loading}
                                            onClick={(e) => {
                                              e.preventDefault()
                                              payEmi(recovery)
                                            }}
                                          >
                                            {loading ? (
                                              <>
                                                <CSpinner
                                                  component="span"
                                                  size="sm"
                                                  aria-hidden="true"
                                                />{' '}
                                                Saving...
                                              </>
                                            ) : (
                                              'Pay EMI'
                                            )}
                                          </CButton>
                                        </CFormGroup>
                                      </CCol>
                                    </CRow>
                                  </>
                                )}
                            </CCol>
                          </CRow>
                        </CAlert>
                      </div>
                    )
                  })}
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>

      {showEditLoanModal && (
        <EditRecoveryModal
          show={showEditLoanModal}
          recovery={selectedRecovery}
          onClose={() => {
            setSelectedRecovery(null)
            setShowEditRecoveryModal(false)
          }}
          onUpdated={() => {
            setSelectedRecovery(null)
            setShowEditRecoveryModal(false)
            initLoan()
          }}
        />
      )}
    </>
  )
}
