const ReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #69b3ff' : '1px solid #E5EAEE',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #E5EAEE'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '10px',
  }),
  /*indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    width: '32px',
    height: '32px',
    color: '#555555',
    marginRight: '-6px'
  }),
  menu: (provided, state) => {
    console.log(state)
    return {
      ...provided,
      marginTop: '-30px',
      marginLeft: '-10px',
      background: '#f0f0f0'
    }
  },
  menuList: (provided, state) => ({
    ...provided,
    color: '#444444',
    paddingTop: '0',
    paddingBottom: '0',
    fontFamily: 'sans-serif',
  }),
  option: (provided, state) => ({
    ...provided,
    paddingLeft:'20px',
    fontWeight: '400',
    lineHeight: '0.7em'
  })*/
}

export default ReactSelectStyles