import {
  CButton, CCard, CCol, CForm, CFormGroup, CInput, CLabel,
  CRow, CSpinner, CCardBody, CAlert, CSelect
} from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'
import '../constants/Api'

export default function EPFReports() {
  const fyRef = useRef(null)
  const [fys, setFys] = useState([])
  const [error, setError] = useState('')
  const [exporting, setExporting] = useState(false)
  const [signatories, setSignatories] = useState([])
  const [selectedSignatory, setSelectedSignatory] = useState(null)
  const [data, setData] = useState({
    cash_opb: 0,
    bank_opb: 0,
    total_subscribers: 0,
    total_contributions: 0,
    total_loan_disburses: 0,
    total_loan_recoveries: 0,
    total_loan_pendings: 0,
    total_pension_fund_disburses: 0,
    total_available_funds: 0,
  })

  const getCurrentFY = async () => {
    try {
      const response = await Backend.getCurrentFY();
      if (response.data) {
        fyRef.current.value = response.data.id
        getReports()
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  const initFYs = async () => {
    try {
      const response = await Backend.getFYs();
      if (response.data) {
        setFys([...response.data])
        getCurrentFY()
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }


  const exportPDF = async () => {
    if (!fyRef.current.value) {
      Notify.error('Select Financial year')
      return
    }

    if (!selectedSignatory) {
      Notify.error('Select Signatory')
      return
    }

    setExporting(true)
    try {
      let data = {}
      data['headers'] = AuthManager.getHeaders()
      const response = await fetch(`${window.Api.Base}/account-reports?fy=${fyRef.current.value}&account_type=epf&signatory=${selectedSignatory.id}&export=true`, data)
      response.blob().then(blob => showInOtherTab(blob))
    } catch (error) {
      Notify.error(error.message)
    }

    setExporting(false)
  }

  function showInOtherTab(blob) {
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  const getSignatories = async () => {
    try {
      const response = await Backend.getSignatories();
      if (response.data) {
        setSignatories([...response.data])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getReports = async () => {
    if (!fyRef.current.value) {
      Notify.error('Select Financial year')
      return
    }
    try {
      const response = await Backend.getAccountReports(`?fy=${fyRef.current.value}&account_type=epf`)
      console.log(response.data)
      setData({ ...response.data })
    } catch (error) {
      Notify.error(error.message)
    }
  }

  useEffect(() => {
    initFYs()
    getSignatories()
  }, [])

  return (
    <>
      <CRow>
        <CCol xs={'12'}>
          <h4>EPF Reports</h4>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CFormGroup className={'col-4'} style={{ display: 'inline-block' }}>

                  <CSelect
                    innerRef={fyRef}
                    onChange={() => getReports()}
                  >
                    <option value="">--Select FY--</option>
                    {
                      fys.map(fy => {
                        return (<option value={fy.id}>{fy.start_year} - {fy.end_year}</option>)
                      })
                    }
                  </CSelect>
                </CFormGroup>

                <CFormGroup className={'col-4'} style={{ display: 'inline-block' }}>
                  <CSelect
                    value={selectedSignatory && selectedSignatory.id}
                    onChange={(e) => {
                      if (e.target.value == '') {
                        setSelectedSignatory(null)
                        return
                      }
                      let signatory = signatories.find(item => item.id == e.target.value)
                      if (signatory !== undefined) {
                        setSelectedSignatory(signatory)
                      }
                    }}
                  >
                    <option value="">-- Select Signatory --</option>
                    {
                      signatories.map(signatory => {
                        return <option value={signatory.id}>{signatory.name}</option>
                      })
                    }
                  </CSelect>
                </CFormGroup>
                <CFormGroup style={{ display: 'inline-block' }}>
                  <CButton
                    disabled={exporting}
                    className={''} color={'dark'} onClick={() => exportPDF()}>
                    {
                      exporting ? 'Exporting PDF...' : 'Export PDF'
                    }
                  </CButton>
                </CFormGroup>

              </CRow>
              {
                error && <CAlert color="danger" className="w-100">{error}</CAlert>
              }

              <CRow className={'mt-4'}>
                <CCol>
                  <h5>EPF Reports: </h5>
                  <hr />
                  <dl className="row">
                    <dt className="col-sm-4">1. Cash Opening Balance: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.cash_opb)}</dd>
                    <dt className="col-sm-4">2. Bank Opening Balance: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.bank_opb)}</dd>
                    <dt className="col-sm-4">3. Total Subscribers: </dt>
                    <dd className="col-sm-6">{data.total_subscribers}</dd>
                    <dt className="col-sm-4">4. Total Contributions: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.total_contributions)}</dd>
                    <dt className="col-sm-4">5. Total Loan Disbursed: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.total_loan_disburses)}</dd>
                    <dt className="col-sm-4">6. Total Loan Recoveries: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.total_loan_recoveries)}</dd>
                    <dt className="col-sm-4">7. Total Pending Loans: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.total_loan_pendings)}</dd>
                    <dt className="col-sm-4">8. Total Pension Fund Disbursed: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.total_pension_fund_disburses)}</dd>
                    <dt className="col-sm-4">9. Total Available Funds: </dt>
                    <dd className="col-sm-6">{General.formatRupees(data.total_available_funds)}</dd>
                  </dl>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}
