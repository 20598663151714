import React from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'

export default function Layout() {
  return (
    <div className="c-app c-default-layout">
      <Sidebar />
      <div className="c-wrapper">
        <Header />
        <div className="c-body">
            <Main />
        </div>
        <Footer />
      </div>
    </div>
  )
}
