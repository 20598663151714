
import { CIcon } from '@coreui/icons-react'
import {
  CButton, CCard, CCardBody, CCardHeader, CCol,
  CDataTable, CPagination, CRow
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import RPReportModal from './modals/RPReportModal'

function ReceiptPaymentReports(props) {
  const [accountHeads, setAccountHeads] = useState([])
  const [loading, setLoading] = useState(true)
  const [showReportModal, setShowReportModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const perPage = 10

  const getAccountHeads = async () => {
    setLoading(true)

    try {
      let params = `?per_page=${perPage}&page=${currentPage}`
      const response = await Backend.getAccountHeads(params);
      if (response.data) {
        setLastPage(response.data.last_page)
        setAccountHeads([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAccountHeads()
  }, [currentPage])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Report -  Receipts & Payments</h4>
        </CCol>
        <CCol className="text-right">
          <CButton color="dark" className="btn-sm"
            onClick={() => setShowReportModal(!showReportModal)} ><CIcon name="cil-file" /> Generate R&P Reports</CButton>
        </CCol>
      </CRow>


      <CRow className={'mt-3'}>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Account Heads</h6>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {
                loading ?
                  <Loading />
                  :
                  <>
                    <CDataTable
                      items={accountHeads}
                      striped
                      outlined={true}
                      fields={[
                        {
                          key: 'slNo',
                          label: '#'
                        }, {
                          key: 'name',
                          label: 'Name'
                        }, {
                          key: 'type',
                          label: 'Type'
                        }, {
                          key: 'report_generated_at',
                          label: 'Generated Date'
                        },
                      ]}
                      scopedSlots={{
                        'slNo': (item, index) => {
                          return (
                            <td>{(currentPage - 1) * perPage + (++index)}</td>
                          )
                        },
                        'report_generated_at': (item) => {
                          return (
                            <td>{item.report_generated_at ? moment(item.report_generated_at).format('DD/MM/YYYY h:mm A') : 'N/A'}</td>
                          )
                        },
                      }}
                    />
                    <CPagination
                      className={'mt-2'}
                      activePage={currentPage}
                      pages={lastPage}
                      onActivePageChange={(i) => setCurrentPage(i)}
                    ></CPagination>
                  </>
              }
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {
        showReportModal &&
        <RPReportModal
          show={showReportModal}
          onClose={() => {
            setShowReportModal(false)
            getAccountHeads()
          }}
        />
      }
    </>
  )
}

export default withRouter(ReceiptPaymentReports)
