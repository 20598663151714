import AuthManager from './AuthManager'

// returns an authenticated fetch requests if possible
// these method are just shortcuts for including headers into
// fetch requests

export default class FetchHelper {
  static get(endpoint, validateTokens = true) {
    // console.log('Endpoint', endpoint)

    let data = {
      credentials: 'include',
    }

    data['headers'] = AuthManager.getHeaders()
    let statusCode = null
    return FetchHelper.handleUserLoginStatus(validateTokens).then(() => {
      return fetch(endpoint, data)
        .then((response) => {
          statusCode = response.status
          return response.json()
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
          }

          return responseJson
        })
    })
  }

  static post(endpoint, data, isMultiPart = false, validateTokens = true) {
    let statusCode = null
    return FetchHelper.handleUserLoginStatus(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'POST',
        headers: isMultiPart
          ? AuthManager.getHeaders('')
          : AuthManager.getHeaders(),
        credentials: 'include',
        body: isMultiPart ? data : JSON.stringify(data),
      })
        .then((response) => {
          statusCode = response.status
          if (statusCode === 204) {
            return response
          }
          return response.json()
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
          }
          return responseJson
        })
    })
  }

  static handleUserLoginStatus(validateTokens) {
    return new Promise((resolve, reject) => {
      if (validateTokens) {
        return AuthManager.isUserLoggedIn(resolve, reject)
      }
      resolve()
    })
  }

  static patch(endpoint, data, stringify = true, validateTokens = true) {
    var headers = AuthManager.getHeaders()
    if (stringify) {
      data = JSON.stringify(data)
    } else {
      headers = AuthManager.getHeaders('multipart/form-data')
    }

    let statusCode = null
    return FetchHelper.handleUserLoginStatus(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'PATCH',
        headers: headers,
        credentials: 'include',
        body: data,
      })
        .then((response) => {
          statusCode = response.status
          if (statusCode === 204) {
            return response
          }
          return response.json()
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
          }
          return responseJson
        })
    })
  }

  static put(endpoint, data, stringify = true, validateTokens = true) {
    var headers = AuthManager.getHeaders()
    // this is needed by server side for all put requests
    data._method = 'PUT'
    if (stringify) {
      data = JSON.stringify(data)
    } else {
      headers = AuthManager.getHeaders('multipart/form-data')
    }
    let statusCode = null
    return FetchHelper.handleUserLoginStatus(validateTokens).then(() => {
      return fetch(endpoint, {
        // this is needed by server side for all put requests
        method: 'PUT',
        headers: headers,
        credentials: 'include',
        body: data,
      })
        .then((response) => {
          statusCode = response.status
          return response.json()
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
          }

          return responseJson
        })
    })
  }

  static delete(endpoint, data, validateTokens = true) {
    let statusCode = null
    return FetchHelper.handleUserLoginStatus(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'DELETE',
        headers: AuthManager.getHeaders(),
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then((response) => {
          statusCode = response.status
          if (statusCode === 204) {
            return response
          }
          return response.json()
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
          }
          return responseJson
        })
    })
  }

  // static download(endpoint, filename, validateTokens = true) {
  //   let data = {}
  //   data['headers'] = AuthManager.getHeaders()
  //   let statusCode = null
  //   return FetchHelper.handleUserLoginStatus(validateTokens).then(() => {
  //     return fetch(endpoint, data)
  //       .then(response => {
  //         statusCode = response.status
  //         return response.blob()
  //       })
  //       .then(responseBlob => {
  //         let status = {code: statusCode, success: responseBlob.status}
  //         if (this._hasError(status)) {
  //           throw FetchHelper._getError(responseBlob)
  //           return
  //         }

  //         return responseBlob
  //       })
  //       .then((data) => {
  //         var link = document.createElement('a');
  //         link.href = window.URL.createObjectURL(data);
  //         link.download = filename;
  //         link.click();
  //       })
  //   })
  // }

  static _hasError({ code, success }) {
    return code < 200 || code > 299 || success === false
  }

  static _getError(responseJson) {
    let error = responseJson.messages || responseJson.message
    let messages = ''

    if (Array.isArray(error)) {
      error.forEach((message) => {
        messages += `${message} `
      })
    } else {
      messages = error
    }

    return { error: error, message: messages }
  }
}
