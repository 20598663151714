import { CIcon } from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CTooltip
} from "@coreui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Backend from "../utils/Backend";
import General from "../utils/General";
import Notify from "../utils/Notify";
import Loading from "./components/Loading";
import ConfirmationModal from "./modals/ConfirmationModal";
import FinancialYearModal from "./modals/FinancialYearModal";

export default function FinancialYears() {
  const [financialYears, setFinancialYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFY, setSelectedFY] = useState();
  const [showFYModal, setShowFYModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const deleteFY = async () => {
    if (selectedFY == null) return;

    try {
      await Backend.deleteFY(selectedFY.id);
      const updatedFinancialYears = financialYears.filter((item) => {
        if (item.id != selectedFY.id) {
          return item;
        }
      });
      setFinancialYears([...updatedFinancialYears]);
      setShowConfirmModal(false);
      setSelectedFY(null);
      Notify.success("Financial Year deleted successfully.");
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const initFinancialYears = async () => {
    try {
      const response = await Backend.getFYs();
      if (response.data) {
        setFinancialYears([...response.data]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      Notify.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    initFinancialYears();
  }, []);

  return (
    <>
      <CRow>
        <CCol>
          <h4>Financial Years</h4>
        </CCol>
        <CCol className="text-right">
          <CButton
            color="dark"
            className="btn-sm"
            onClick={() => setShowFYModal(!showFYModal)}
          >
            <CIcon name="cil-plus" /> Add Financial Year
          </CButton>
        </CCol>
      </CRow>

      {loading ? (
        <Loading />
      ) : (
        <CRow className={"mt-3"}>
          <CCol xs="12" lg="12">
            <CCard>
              <CCardHeader>Financial Years</CCardHeader>
              <CCardBody>
                <CDataTable
                  items={financialYears}
                  striped
                  itemsPerPage={100}
                  pagination
                  outlined={true}
                  fields={[
                    {
                      key: "duration",
                      label: "Financial Year",
                    },
                    {
                      key: "epf_cash_opening_balance",
                      label: "EPF Cash Opening Balance",
                    },
                    {
                      key: "epf_bank_opening_balance",
                      label: "EPF Bank Opening Balance",
                    },
                    {
                      key: "ess_cash_opening_balance",
                      label: "ESS Cash Opening Balance",
                    },
                    {
                      key: "ess_bank_opening_balance",
                      label: "ESS Bank Opening Balance",
                    },
                    {
                      key: "nps_cash_opening_balance",
                      label: "NPS Cash Opening Balance",
                    },
                    {
                      key: "nps_bank_opening_balance",
                      label: "NPS Bank Opening Balance",
                    },
                    {
                      key: "current",
                      label: "Active",
                    },
                    {
                      key: "actions",
                      label: "Actions",
                    },
                  ]}
                  scopedSlots={{
                    duration: (item) => {
                      return (
                        <td>
                          {moment(item.start).format("YYYY")} -
                          {moment(item.end).format("YYYY")}
                        </td>
                      );
                    },
                    epf_cash_opening_balance: (item) => {
                      return (
                        <td>
                          {General.formatRupees(item.epf_cash_opening_balance)}
                        </td>
                      );
                    },
                    epf_bank_opening_balance: (item) => {
                      return (
                        <td>
                          {General.formatRupees(item.epf_bank_opening_balance)}
                        </td>
                      );
                    },
                    ess_cash_opening_balance: (item) => {
                      return (
                        <td>
                          {General.formatRupees(item.ess_cash_opening_balance)}
                        </td>
                      );
                    },
                    ess_bank_opening_balance: (item) => {
                      return (
                        <td>
                          {General.formatRupees(item.ess_bank_opening_balance)}
                        </td>
                      );
                    },
                    nps_cash_opening_balance: (item) => {
                      return (
                        <td>
                          {General.formatRupees(item.nps_cash_opening_balance)}
                        </td>
                      );
                    },
                    nps_bank_opening_balance: (item) => {
                      return (
                        <td>
                          {General.formatRupees(item.nps_bank_opening_balance)}
                        </td>
                      );
                    },
                    current: (item) => {
                      return <td>{item.current ? "Yes" : "No"}</td>;
                    },
                    // 'updated_at': (item) => {
                    //   return (
                    //     <td>{moment(item.updated_at).format('DD/MM/YYYY hh:mm A')}</td>
                    //   )
                    // },
                    actions: (item) => {
                      return (
                        <td>
                          <CButtonGroup className={"btn-sm"}>
                            <CTooltip
                              content="Edit Financial Year"
                              placement="bottom"
                            >
                              <CButton
                                color="dark"
                                variant="outline"
                                className={"btn-sm"}
                                onClick={() => {
                                  setSelectedFY(item);
                                  setShowFYModal(true);
                                }}
                              >
                                <CIcon name="cil-pen-alt"></CIcon>
                              </CButton>
                            </CTooltip>
                            <CTooltip
                              content="Delete Financial Year"
                              placement="bottom"
                            >
                              <CButton
                                color="danger"
                                variant="outline"
                                className={"btn-sm"}
                                onClick={() => {
                                  setSelectedFY(item);
                                  setShowConfirmModal(true);
                                }}
                              >
                                <CIcon name="cil-trash"></CIcon>
                              </CButton>
                            </CTooltip>
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      )}

      {showFYModal && (
        <FinancialYearModal
          show={showFYModal}
          fy={selectedFY}
          onAdded={(fy) => {
            // financialYears.unshift(fy)
            setFinancialYears([]);
            initFinancialYears();
          }}
          onUpdated={(fy) => {
            // const updatedFinancialYears = financialYears.map(item => {
            //   if (item.id === fy.id) {
            //     return fy
            //   } else {
            //     return item
            //   }
            // })
            // setFinancialYears([...updatedFinancialYears])
            setFinancialYears([]);
            initFinancialYears();
          }}
          onClose={() => {
            setSelectedFY(null);
            setShowFYModal(false);
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={`Confirm delete Financial Year?`}
          content={() => (
            <>
              <CAlert color="warning" className="w-100">
                Are you sure you want to delete? Deleting this will affects
                Accounts, Fiscal Account etc...
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText="Delete"
          onConfirm={() => {
            deleteFY();
          }}
        />
      )}
    </>
  );
}
