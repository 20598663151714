import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup,
  CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CSpinner, CSwitch
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';

export default function FiscalAccountModal(props) {
  const fiscalAccountModel = {
    account_id: '',
    financial_year_id: '',
    opening_balance: 0.00,
    closing_balance: 0.00,
    total_interest: 0.00,
    status: 'active'
  }

  const accounts = props.accounts
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [fiscalAccount, setFiscalAcount] = useState(props.fiscalAccount ? props.fiscalAccount : fiscalAccountModel)
  const [financialYears, setFinancialYears] = useState([])

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;
    if (name == 'opening_balance' || name == 'closing_balance') {
      value = parseFloat(parseFloat(value).toFixed(2))
    }

    setFiscalAcount({ ...fiscalAccount, [name]: value })
  }

  function validateData() {
    if (!fiscalAccount.account_id) {
      setError('Select Account')
      return false;
    } else if (!fiscalAccount.financial_year_id) {
      setError('Select Financial Year')
      return false;
    } else if (isNaN(fiscalAccount.opening_balance)) {
      setError('Enter valid value of Opening Balance')
      return false;
    } else if (isNaN(fiscalAccount.closing_balance)) {
      setError('Enter valid value of Closing balance')
      return false;
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (props.fiscalAccount) {
        const response = await Backend.updateFiscalAccount(fiscalAccount.id, fiscalAccount)
        if (props.onUpdated) {
          props.onUpdated(response.data)
        }
        Notify.success('Fiscal Account updated successfully.')
      } else {
        const response = await Backend.addFiscalAccount(fiscalAccount)
        if (props.onAdded) {
          props.onAdded(response.data)
        }
        Notify.success('Fiscal Account added successfully.')
      }

      props.onClose()
    } catch (error) {
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initFinancialYears = async () => {
    try {
      const response = await Backend.getFYs();
      if (response.data) {
        setFinancialYears([...response.data])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initFinancialYears()
    return () => {
    }
  }, [])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Fiscal Account</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol sm="12">
                <CForm onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                  <CFormGroup>
                    <CLabel>Select Account</CLabel>
                    <CSelect
                      value={fiscalAccount.account_id}
                      name="account_id"
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    >
                      <option value="">Select Account</option>

                      {
                        props.accounts.map((account) => {
                          return (
                            <option value={account.id}>{account.code} ({account.type.toUpperCase()})</option>
                          )
                        })
                      }
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Select Financial Year</CLabel>
                    <CSelect
                      value={fiscalAccount.financial_year_id}
                      name="financial_year_id"
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    >
                      <option value="">Select Financial Year</option>
                      {
                        financialYears.map((fy) => {
                          return (
                            <option value={fy.id}>{moment(fy.start).format('MM/YYYY')}  - {moment(fy.end).format('MM/YYYY')}</option>
                          )
                        })
                      }
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Opening Balance</CLabel>
                    <CInput
                      value={fiscalAccount.opening_balance}
                      type="number"
                      name="opening_balance"
                      min={'0'}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Closing Balance</CLabel>
                    <CInput
                      readOnly={!props.fiscalAccount}
                      value={fiscalAccount.closing_balance}
                      type="number"
                      name="closing_balance"
                      min={'0'}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
            {
              error && <CRow><CAlert color="danger" className="w-100">{error}</CAlert></CRow>
            }
          </CContainer>
        </CModalBody>
        <CModalFooter>

          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}>Cancel</CButton>
          <CButton color="dark" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit()
            }}>
            {
              loading ?
                (
                  <>
                    <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                  </>
                ) : 'Add/Update Fiscal Account'
            }
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
