import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CTooltip,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import AccountModal from './modals/AccountModal'
import AdvancePaymentModal from './modals/AdvancePaymentModal'
import ConfirmationModal from './modals/ConfirmationModal'
import ContributionModal from './modals/ContributionModal'
import EmployeeModal from './modals/EmployeeModal'
import FiscalAccountModal from './modals/FiscalAccountModal'
import LoanModal from './modals/LoanModal'
import LoanPaymentModal from './modals/LoanPaymentModal'
import LoanPreClosureModal from './modals/LoanPreClosureModal'
import StatementModal from './modals/StatementModal'

function Employee(props) {
  const { employeeId } = useParams()
  const [employee, setEmployee] = useState(null)
  const [branches, setBranches] = useState([])
  const [accounts, setAccounts] = useState(null)
  const [fiscalAccounts, setFiscalAccounts] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedFiscalAccount, setSelectedFiscalAccount] = useState(null)
  const [selectedLoan, setSelectedLoan] = useState(null)
  const [loans, setLoans] = useState(null)
  const [payments, setPayments] = useState(null)
  const [showEmployeeModal, setShowEmployeeModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const [showContributionModal, setShowContributionModal] = useState(false)
  const [showFiscalModal, setShowFiscalModal] = useState(false)
  const [showStatementModal, setShowStatementModal] = useState(false)
  const [showLoanModal, setShowLoanModal] = useState(false)
  const [showLoanPaymentModal, setShowLoanPaymentModal] = useState(false)
  const [showLoanPreClosureModal, setShowLoanPreClosureModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    content: '',
    confirmText: 'Delete',
    onConfirm: () => {},
  })

  const getEmployee = async () => {
    try {
      const response = await Backend.getEmployee(employeeId)
      if (response.data) {
        setEmployee(response.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const getAccounts = async () => {
    try {
      const response = await Backend.getEmployeeData(employeeId, 'accounts')
      if (response.data) {
        setAccounts(response.data)
      }
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
    }
  }

  const getLoans = async () => {
    try {
      const response = await Backend.getEmployeeData(employeeId, 'loans')
      if (response.data) {
        setLoans(response.data)
      }
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
    }
  }

  const getAdvancePayments = async () => {
    try {
      const response = await Backend.getAllAdvancePayments(employeeId)
      if (response.data) {
        setPayments(response.data)
      }
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
    }
  }

  const initBranches = async () => {
    try {
      const response = await Backend.getBranches()
      if (response.data) {
        setBranches([...response.data])
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  const initFiscalAccounts = async () => {
    try {
      if (accounts) {
        let fcAccounts = []

        await Promise.all(
          accounts.map(async (account) => {
            const response = await Backend.getAccountData(
              account.id,
              'fiscal-accounts'
            )
            if (response.data) {
              response.data.forEach((item) => fcAccounts.push(item))
            }
          })
        )

        setFiscalAccounts([...fcAccounts])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const closeAccount = async (account) => {
    try {
      const response = await Backend.closeAccount(account.id)
      setShowConfirmModal(false)
      Notify.success('Account closed successfully.')
      await getAccounts()
      await initFiscalAccounts()
      await getLoans()
    } catch (error) {
      setShowConfirmModal(false)
      console.log(error)
      Notify.error(error.message)
    }
  }

  const closeFiscalAccount = async (account) => {
    try {
      const response = await Backend.closeFiscalAccount(account.id)
      setShowConfirmModal(false)
      Notify.success('Fiscal account closed successfully.')
      await getAccounts()
      await initFiscalAccounts()
    } catch (error) {
      setShowConfirmModal(false)
      console.log(error)
      Notify.error(error.message)
    }
  }

  const deleteFiscalAccount = async (account) => {
    try {
      const response = await Backend.deleteFiscalAccount(account.id)
      setShowConfirmModal(false)
      Notify.success('Fiscal account deleted successfully.')
      await getAccounts()
      await initFiscalAccounts()
      setShowConfirmModal(false)
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const deletePayment = async (payment) => {
    try {
      const response = await Backend.deleteAdvancePayment(payment.id)
      setShowConfirmModal(false)
      Notify.success('Payment deleted successfully.')
      await getAccounts()
      await getAdvancePayments()
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const deleteAccount = async (account) => {
    try {
      const response = await Backend.deleteAccount(account.id)
      setShowConfirmModal(false)
      Notify.success('Account deleted successfully.')
      await getAccounts()
      await initFiscalAccounts()
      await getLoans()
      setSelectedAccount(null)
      setShowConfirmModal(false)
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  useEffect(() => {
    getEmployee()
    getAccounts()
    getLoans()
    initBranches()
    getAdvancePayments()
  }, [])

  useEffect(() => {
    initFiscalAccounts()

    return () => {}
  }, [accounts])

  return (
    <>
      <CRow>
        <CCol>
          <h4>{employee ? employee.name : ''}</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            disabled={!employee}
            onClick={() => setShowEmployeeModal(!showEmployeeModal)}
          >
            <CIcon name='cil-pen-alt' /> Edit Employee
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <h5>Employee Details</h5>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {loading ? (
                    <Loading />
                  ) : (
                    <dl className='row'>
                      <dt className='col-sm-3'>Name :</dt>
                      <dd className='col-sm-9'>{employee.name}</dd>
                      <dt className='col-sm-3'>NPS Employee ID :</dt>
                      <dd className='col-sm-9'>{employee.nps_id}</dd>
                      <dt className='col-sm-3'>NPS PRAN No. :</dt>
                      <dd className='col-sm-9'>{employee.nps_pran_no}</dd>
                      <dt className='col-sm-3'>Father/Mother Name :</dt>
                      <dd className='col-sm-9'>{employee.father_name}</dd>
                      <dt className='col-sm-3'>Department :</dt>
                      <dd className='col-sm-9'>
                        {employee.branch ? employee.branch.name : 'N/A'}
                      </dd>
                      <dt className='col-sm-3'>Date of Birth :</dt>
                      <dd className='col-sm-9'>
                        {moment(employee.dob).format('DD/MM/YYYY')}
                      </dd>
                      <dt className='col-sm-3'>Basic Pay :</dt>
                      <dd className='col-sm-9'>
                        {General.formatRupees(employee.basic_pay)}
                      </dd>
                      {/* <dt className="col-sm-3">Contribution % of Basic Pay :</dt>
                        <dd className="col-sm-9">{ employee.contribution_percentage}%</dd> */}
                      <dt className='col-sm-3'>Service start Date :</dt>
                      <dd className='col-sm-9'>
                        {moment(employee.service_started_at).format(
                          'DD/MM/YYYY'
                        )}
                      </dd>
                      <dt className='col-sm-3'>Superanuation Date :</dt>
                      <dd className='col-sm-9'>
                        {moment(employee.superanuation_date).format(
                          'DD/MM/YYYY'
                        )}
                      </dd>
                    </dl>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className={'mt-1'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h5>Main Accounts</h5>
                </CCol>
                <CCol className='text-right'>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    disabled={!employee}
                    onClick={() => setShowAccountModal(!showEmployeeModal)}
                  >
                    <CIcon name='cil-plus' /> Add/Edit Accounts
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {!accounts ? (
                    <Loading />
                  ) : (
                    <CDataTable
                      items={accounts}
                      itemsPerPage={100}
                      pagination
                      outlined={true}
                      fields={[
                        {
                          key: 'code',
                          label: 'Code',
                        },
                        {
                          key: 'type',
                          label: 'Type',
                        },
                        {
                          key: 'total_outstanding_loan',
                          label: 'Total Outstanding Loan',
                        },
                        {
                          key: 'total_outstanding_balance',
                          label: 'Opening Balance',
                        },
                        {
                          key: 'running_balance',
                          label: 'IBB',
                        },

                        {
                          key: 'actions',
                          label: 'Actions',
                        },
                      ]}
                      scopedSlots={{
                        type: (item) => {
                          return <td>{item.type.toUpperCase()}</td>
                        },
                        total_outstanding_loan: (item) => {
                          return (
                            <td>
                              {General.formatRupees(
                                item.total_outstanding_loan
                              )}
                            </td>
                          )
                        },
                        total_outstanding_balance: (item) => {
                          return (
                            <td>
                              {General.formatRupees(
                                item.total_outstanding_balance
                              )}
                            </td>
                          )
                        },
                        running_balance: (item) => {
                          return (
                            <td>
                              {General.formatRupees(item.running_balance)}
                            </td>
                          )
                        },
                        created_at: (item) => {
                          return (
                            <td>
                              {moment(item.created_at).format(
                                'DD/MM/YYYY hh:mm A'
                              )}
                            </td>
                          )
                        },
                        updated_at: (item) => {
                          return (
                            <td>
                              {moment(item.updated_at).format(
                                'DD/MM/YYYY hh:mm A'
                              )}
                            </td>
                          )
                        },
                        actions: (item) => {
                          if (item.status === 'active') {
                            return (
                              <td>
                                <CButtonGroup className={'btn-sm'}>
                                  {/* <CTooltip content="View Details" placement="bottom">
                                    <CButton color="success" variant="outline" className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedAccount(item)
                                        setShowContributionModal(true)
                                      }}>
                                      <CIcon name="cil-grid"></CIcon>
                                    </CButton>
                                  </CTooltip> */}
                                  <CTooltip
                                    content='Delete Account'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='danger'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedAccount(item)
                                        setConfirmModal({
                                          ...confirmModal,
                                          title: 'Delete Account?',
                                          content:
                                            'Are you sure you want to close this Account? This action is irreversible.',
                                          confirmText: 'Delete Account',
                                          onConfirm: () => deleteAccount(item),
                                        })
                                        setShowConfirmModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-trash'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                  <CTooltip
                                    content='Close Account'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='danger'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setConfirmModal({
                                          ...confirmModal,
                                          title: 'Close  Account?',
                                          content:
                                            'Are you sure you want to close this Account? All Fiscal accounts and Loans under this account will be closed. This action is irreversible.',
                                          confirmText: 'Close Account',
                                          onConfirm: () => closeAccount(item),
                                        })
                                        setShowConfirmModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-lock-locked'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                </CButtonGroup>
                              </td>
                            )
                          } else {
                            return (
                              <td>
                                <strong>Closed</strong>
                              </td>
                            )
                          }
                        },
                      }}
                    />
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className={'mt-1'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h5>Fiscal Accounts</h5>
                </CCol>
                <CCol className='text-right'>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    onClick={() => setShowFiscalModal(!showFiscalModal)}
                  >
                    <CIcon name='cil-plus' /> Add Fiscal Account
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {!fiscalAccounts ? (
                    <Loading />
                  ) : (
                    <CDataTable
                      items={fiscalAccounts}
                      itemsPerPage={100}
                      pagination
                      outlined={true}
                      fields={[
                        {
                          key: 'financial_year',
                          label: 'Financial Year',
                        },
                        {
                          key: 'code',
                          label: 'Account Code',
                        },
                        {
                          key: 'type',
                          label: 'Account Type',
                        },
                        {
                          key: 'opening_balance',
                          label: 'Opening Balance',
                        },
                        {
                          key: 'closing_balance',
                          label: 'Closing Balance',
                        },
                        {
                          key: 'actions',
                          label: 'Actions',
                        },
                      ]}
                      scopedSlots={{
                        financial_year: (item) => {
                          return <td>{General.getFiscalAccountFY(item)}</td>
                        },
                        code: (item) => {
                          return <td>{item.account.code.toUpperCase()}</td>
                        },
                        type: (item) => {
                          return <td>{item.account.type.toUpperCase()}</td>
                        },
                        opening_balance: (item) => {
                          return (
                            <td>
                              {General.formatRupees(item.opening_balance)}
                            </td>
                          )
                        },
                        closing_balance: (item) => {
                          return (
                            <td>
                              {General.formatRupees(item.closing_balance)}
                            </td>
                          )
                        },
                        actions: (item) => {
                          if (item.status === 'active') {
                            return (
                              <td>
                                <CButtonGroup className={'btn-sm'}>
                                  <CTooltip
                                    content='Add Contribution'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='success'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedFiscalAccount(item)
                                        setShowContributionModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-plus'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                  <CTooltip
                                    content='View Statements'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='success'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedFiscalAccount(item)
                                        setShowStatementModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-spreadsheet'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                  <CTooltip
                                    content='Close Fiscal Account'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='danger'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedFiscalAccount(item)
                                        setConfirmModal({
                                          ...confirmModal,
                                          title: 'Close Fiscal Account?',
                                          content:
                                            'Are you sure you want to close this Fiscal Account? This action is irreversible.',
                                          confirmText: 'Close Fiscal Account',
                                          onConfirm: () =>
                                            closeFiscalAccount(item),
                                        })
                                        setShowConfirmModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-lock-locked'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                  <CTooltip
                                    content='Delete Fiscal Account'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='danger'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedFiscalAccount(item)
                                        setConfirmModal({
                                          ...confirmModal,
                                          title: 'Delete Fiscal Account?',
                                          content:
                                            'Are you sure you want to delete this Fiscal Account? This action is irreversible.',
                                          confirmText: 'Delete Fiscal Account',
                                          onConfirm: () =>
                                            deleteFiscalAccount(item),
                                        })
                                        setShowConfirmModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-trash'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                </CButtonGroup>
                              </td>
                            )
                          } else {
                            return (
                              <td>
                                <CButtonGroup className={'btn-sm'}>
                                  <CTooltip
                                    content='View Statements'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='success'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedFiscalAccount(item)
                                        setShowStatementModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-spreadsheet'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                  <CTooltip
                                    content='Account Closed'
                                    placement='bottom'
                                  >
                                    <CButton
                                      color='dark'
                                      variant='outline'
                                      className={'btn-sm'}
                                      onClick={() => {
                                        // setSelectedFY(item)
                                        // setShowConfirmModal(true)
                                      }}
                                    >
                                      <CIcon name='cil-lock-locked'></CIcon>
                                    </CButton>
                                  </CTooltip>
                                </CButtonGroup>
                              </td>
                            )
                          }
                        },
                      }}
                    />
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className={'mt-1'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h5>Loans</h5>
                </CCol>
                <CCol className='text-right'>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    onClick={() => setShowLoanModal(!showLoanModal)}
                  >
                    <CIcon name='cil-plus' /> Add Loan
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {!loans ? (
                    <Loading />
                  ) : (
                    <CDataTable
                      items={loans}
                      itemsPerPage={100}
                      pagination
                      outlined={true}
                      fields={[
                        {
                          key: 'code',
                          label: 'Account Code',
                        },
                        {
                          key: 'type',
                          label: 'Account Type',
                        },
                        {
                          key: 'amount',
                          label: 'Amount',
                        },
                        // {
                        //   key: 'total_interest',
                        //   label: 'Interest'
                        // },
                        {
                          key: 'total',
                          label: 'Total Amount',
                        },
                        {
                          key: 'balance',
                          label: 'Balance',
                        },
                        {
                          key: 'no_of_emi',
                          label: 'No. of EMIs',
                        },
                        //  {
                        //   key: 'interest_rate',
                        //   label: 'Interest Rate'
                        // },
                        {
                          key: 'disbursed_at',
                          label: 'Disburse Date',
                        },
                        {
                          key: 'approved',
                          label: 'Approved',
                        },
                        {
                          key: 'actions',
                          label: 'Actions',
                        },
                      ]}
                      scopedSlots={{
                        code: (item) => {
                          return <td>{item.account.code.toUpperCase()}</td>
                        },
                        type: (item) => {
                          return <td>{item.account.type.toUpperCase()}</td>
                        },
                        amount: (item) => {
                          return <td>{General.formatRupees(item.amount)}</td>
                        },
                        total_interest: (item) => {
                          return (
                            <td>{General.formatRupees(item.total_interest)}</td>
                          )
                        },
                        total: (item) => {
                          return <td>{General.formatRupees(item.total)}</td>
                        },
                        balance: (item) => {
                          return <td>{General.formatRupees(item.balance)}</td>
                        },
                        disbursed_at: (item) => {
                          return (
                            <td>
                              {moment(item.disbursed_at).format('DD/MM/YYYY')}
                            </td>
                          )
                        },
                        approved: (item) => {
                          return <td>{item.approved ? 'Yes' : 'Pending'}</td>
                        },
                        actions: (item) => {
                          return (
                            <td>
                              <CButtonGroup className={'btn-sm'}>
                                {item.status === 'active' &&
                                  item.approved === 1 && (
                                    <>
                                      <CTooltip
                                        content='Add Payment'
                                        placement='bottom'
                                      >
                                        <CButton
                                          color='success'
                                          variant='outline'
                                          className={'btn-sm'}
                                          onClick={() => {
                                            setSelectedLoan(item)
                                            setShowLoanPaymentModal(true)
                                          }}
                                        >
                                          <CIcon name='cil-plus'></CIcon>
                                        </CButton>
                                      </CTooltip>
                                      <CTooltip
                                        content='Close Loan'
                                        placement='bottom'
                                      >
                                        <CButton
                                          color='danger'
                                          variant='outline'
                                          className={'btn-sm'}
                                          onClick={() => {
                                            setSelectedLoan(item)
                                            setShowLoanPreClosureModal(true)
                                          }}
                                        >
                                          <CIcon name='cil-lock-locked'></CIcon>
                                        </CButton>
                                      </CTooltip>
                                    </>
                                  )}

                                {item.status === 'closed' && (
                                  <>
                                    <CTooltip
                                      content='View Details'
                                      placement='bottom'
                                    >
                                      <CButton
                                        color='success'
                                        variant='outline'
                                        className={'btn-sm'}
                                        onClick={() => {
                                          setSelectedLoan(item)
                                          setShowLoanPaymentModal(true)
                                        }}
                                      >
                                        <CIcon name='cil-spreadsheet'></CIcon>
                                      </CButton>
                                    </CTooltip>
                                  </>
                                )}
                              </CButtonGroup>
                            </td>
                          )
                        },
                      }}
                    />
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className={'mt-1'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h5>Advance Payments</h5>
                </CCol>
                <CCol className='text-right'>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    onClick={() => setShowPaymentModal(!showPaymentModal)}
                  >
                    <CIcon name='cil-plus' /> Add Advance Payment
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {!payments ? (
                    <Loading />
                  ) : (
                    <CDataTable
                      items={payments}
                      itemsPerPage={100}
                      pagination
                      outlined={true}
                      fields={[
                        {
                          key: 'code',
                          label: 'Account Code',
                        },
                        {
                          key: 'type',
                          label: 'Account Type',
                        },
                        {
                          key: 'amount',
                          label: 'Total Amount',
                        },
                        {
                          key: 'date',
                          label: 'Date',
                        },
                        {
                          key: 'actions',
                          label: 'Actions',
                        },
                      ]}
                      scopedSlots={{
                        code: (item) => {
                          console.log(item.id)
                          return <td>{item.account.code.toUpperCase()}</td>
                        },
                        type: (item) => {
                          return <td>{item.account.type.toUpperCase()}</td>
                        },
                        amount: (item) => {
                          return <td>{General.formatRupees(item.amount)}</td>
                        },
                        date: (item) => {
                          return (
                            <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                          )
                        },
                        actions: (item) => {
                          return (
                            <td>
                              <CButtonGroup className={'btn-sm'}>
                                <CTooltip
                                  content='Delete Payment'
                                  placement='bottom'
                                >
                                  <CButton
                                    color='danger'
                                    variant='outline'
                                    className={'btn-sm'}
                                    onClick={() => {
                                      setSelectedAccount(item)
                                      setConfirmModal({
                                        ...confirmModal,
                                        title: 'Delete Advance Payment?',
                                        content:
                                          'Are you sure you want to delete this Advance Payment? This action is irreversible.',
                                        confirmText: 'Delete Payment',
                                        onConfirm: () => deletePayment(item),
                                      })
                                      setShowConfirmModal(true)
                                    }}
                                  >
                                    <CIcon name='cil-trash'></CIcon>
                                  </CButton>
                                </CTooltip>
                              </CButtonGroup>
                            </td>
                          )
                        },
                      }}
                    />
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showEmployeeModal && (
        <EmployeeModal
          show={showEmployeeModal}
          employee={employee}
          branches={branches}
          onUpdated={(employee) => {
            setEmployee({ ...employee })
          }}
          onClose={() => {
            setShowEmployeeModal(false)
          }}
        />
      )}

      {showAccountModal && (
        <AccountModal
          show={showAccountModal}
          employee={employee}
          epf={accounts.find((item) => item.type == 'epf')}
          ess={accounts.find((item) => item.type == 'ess')}
          nps={accounts.find((item) => item.type == 'nps')}
          onAdded={(account) => {
            accounts.unshift(account)
            setAccounts([...accounts])
          }}
          onUpdated={() => {
            getAccounts()
          }}
          onClose={() => {
            setShowAccountModal(false)
          }}
        />
      )}

      {showFiscalModal && (
        <FiscalAccountModal
          show={showFiscalModal}
          accounts={accounts}
          onAdded={(fa) => {
            fiscalAccounts.unshift(fa)
            setFiscalAccounts([...fiscalAccounts])
          }}
          onUpdated={(fa) => {
            const fas = fiscalAccounts.map((item) => {
              if (item.id === fa.id) {
                return fa
              }

              return item
            })
            setFiscalAccounts([...fas])
          }}
          onClose={() => {
            setShowFiscalModal(false)
            setSelectedFiscalAccount(null)
          }}
        />
      )}
      {showStatementModal && (
        <StatementModal
          show={showStatementModal}
          fiscalAccount={selectedFiscalAccount}
          employee={employee}
          onClose={() => {
            setShowStatementModal(false)
            setSelectedFiscalAccount(null)
          }}
        />
      )}

      {showContributionModal && (
        <ContributionModal
          show={showContributionModal}
          fiscalAccount={selectedFiscalAccount}
          onClose={() => {
            setShowContributionModal(false)
            setSelectedFiscalAccount(null)
          }}
        />
      )}

      {showLoanModal && (
        <LoanModal
          show={showLoanModal}
          accounts={accounts}
          onAdded={() => {
            setShowLoanModal(false)
            getAccounts()
            getLoans()
          }}
          onClose={() => {
            setShowLoanModal(false)
          }}
        />
      )}
      {showLoanPaymentModal && (
        <LoanPaymentModal
          show={showLoanPaymentModal}
          loan={selectedLoan}
          onClose={() => {
            getAccounts()
            getLoans()
            setShowLoanPaymentModal(false)
          }}
        />
      )}
      {showLoanPreClosureModal && (
        <LoanPreClosureModal
          show={showLoanPreClosureModal}
          loan={selectedLoan}
          onClose={() => {
            getAccounts()
            getLoans()
            setShowLoanPreClosureModal(false)
          }}
        />
      )}

      {showPaymentModal && (
        <AdvancePaymentModal
          show={showPaymentModal}
          accounts={accounts}
          onAdded={() => {
            setShowPaymentModal(false)
            getAccounts()
            getAdvancePayments()
          }}
          onClose={() => {
            setShowPaymentModal(false)
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmModal.title}
          content={() => (
            <>
              <CAlert color='danger' className='w-100'>
                {confirmModal.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          confirmText={confirmModal.confirmText}
          onConfirm={confirmModal.onConfirm}
        />
      )}

      {/* {
        showAccountConfirmModal &&
        (
          <ConfirmationModal
            show={showAccountConfirmModal}
            title={`Add employee Account?`}
            content={() =>
            (
              <>
                <CAlert color="info" className="w-100">
                  Would you like to add "{addEmployeeAccount.name}'s" Account?
                </CAlert>
              </>
            )
            }
            onClose={() => {
              setShowAccountConfirmModal(false)
            }}
            confirmText='Add Account'
            buttonType={'success'}
            onConfirm={() => {
              setShowAccountConfirmModal(false)
              setShowAccountModal(true)
            }}
          />
        )
      } */}
    </>
  )
}

export default withRouter(Employee)
