import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTextarea,
  CSelect,
} from '@coreui/react'
import React, { useState, useEffect } from 'react'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'
import CustomDatePicker from '../components/CustomDatePicker'
import moment from 'moment'

export default function EmployeeModal(props) {
  const employeeModel = {
    branch_id: '',
    nps_id: '',
    nps_pran_no: '',
    name: '',
    father_name: '',
    dob: '',
    basic_pay: 0,
    contribution_percentage: 0,
    service_started_at: '',
    superanuation_date: '',
  }
  const branches = props.branches
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [employee, setEmployee] = useState(employeeModel)

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name
    let value = evt.target.value
    setEmployee({ ...employee, [name]: value })
  }

  function validateData() {
    if (!employee.name) {
      setError('Enter employee name')
      return false
    } else if (!employee.father_name) {
      setError("Enter employee's father/mother name")
      return false
    } else if (!employee.dob) {
      setError('Select employee Date of Birth')
      return false
    } else if (!employee.branch_id) {
      setError('Select branch')
      return false
    } else if (!employee.service_started_at) {
      setError('Select employee service state date')
      return false
    } else if (!employee.superanuation_date) {
      setError('Select employee superanuation date')
      return false
    }
    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (!props.employee) {
        const response = await Backend.addEmployee(employee)
        props.onAdded(response.data)
      } else {
        const response = await Backend.updateEmployee(employee.id, employee)
        props.onUpdated(response.data)
      }
      props.onClose()
      Notify.success('Employee added/updated successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.employee) {
      setEmployee(props.employee)
    }

    return () => {}
  }, [])

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Employee</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <CFormGroup>
                    <CLabel>Name</CLabel>
                    <CInput
                      value={employee.name}
                      type='text'
                      name='name'
                      placeholder='Enter name'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>
                      NPS Employee ID
                      <small style={{ color: 'red' }}>
                        (Required for NPS Employee)
                      </small>
                    </CLabel>
                    <CInput
                      value={employee.nps_id}
                      type='text'
                      name='nps_id'
                      placeholder='Enter NPS ID'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>
                      NPS PRAN NO
                      <small style={{ color: 'red' }}>
                        (Required for NPS Employee)
                      </small>
                    </CLabel>
                    <CInput
                      value={employee.nps_pran_no}
                      type='text'
                      name='nps_pran_no'
                      placeholder='Enter NPS PRAN NO'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Father/Mother Name</CLabel>
                    <CInput
                      value={employee.father_name}
                      type='text'
                      name='father_name'
                      placeholder='Enter Father/Mother name'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Select DOB'}
                      value={
                        props.employee
                          ? props.employee.dob
                          : ''
                      }
                      onApply={(date) => {
                        setError('')
                        setEmployee({
                          ...employee,
                          dob: moment(date).format('YYYY-MM-DD'),
                        })
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Select Department</CLabel>
                    <CSelect
                      value={employee.branch_id}
                      name='branch_id'
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value=''>Select Department</option>
                      {branches.map((branch) => {
                        return <option value={branch.id}>{branch.name}</option>
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Basic Pay</CLabel>
                    <CInput
                      value={employee.basic_pay}
                      type='number'
                      min={0}
                      name='basic_pay'
                      placeholder='Enter Basic Pay'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>
                      Contribution Percentage{' '}
                      <small style={{ color: 'red' }}>
                        (Required for ESS Employee)
                      </small>
                    </CLabel>
                    <CInput
                      value={employee.contribution_percentage}
                      type='number'
                      min={0}
                      name='contribution_percentage'
                      placeholder='Enter Contribution Percentage'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Select service start date'}
                      value={
                        props.employee
                          ? props.employee.service_started_at
                          : ''
                      }
                      onApply={(date) => {
                        setError('')
                        setEmployee({
                          ...employee,
                          service_started_at: moment(date).format('YYYY-MM-DD'),
                        })
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Superanuation date'}
                      value={
                        props.employee
                          ? props.employee.superanuation_date
                          : ''
                      }
                      onApply={(date) => {
                        setError('')
                        setEmployee({
                          ...employee,
                          superanuation_date: moment(date).format('YYYY-MM-DD'),
                        })
                      }}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : (
              'Add/Update Employee'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
