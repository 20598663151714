import {
  CAlert, CButton, CCol, CContainer, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Backend from '../../utils/Backend';
import General from '../../utils/General';
import Notify from '../../utils/Notify';


export default function LoanApproveModal(props) {
  const [loading, setLoading] = useState(false)
  const [loan, setLoan] = useState(props.loan)
  const [loanRecoveries, setLoanRecoveries] = useState([])

  const approveLoan = async () => {
    try {
      await Backend.approveLoan(props.loan.id);
      props.onClose()
      Notify.success('Loan approved')
    } catch (error) {
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initLoan = async () => {
    try {
      const response = await Backend.getLoan(props.loan.id);
      if (response.data) {
        setLoan({ ...response.data })
        setLoanRecoveries([...response.data.loan_recoveries])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initLoan()
    // return () => {
    // }
  }, [])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'lg'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Approve Loan Application</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol>
                <h5>Loan Details: </h5>
                <dl className="row">
                  <dt className="col-sm-6">Amount : </dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.amount)}</dd>
                  <dt className="col-sm-6">Total Interest :</dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.total_interest)} @{loan.interest_rate}% pa</dd>
                  <dt className="col-sm-6">Total Loan Amount :</dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.total)}</dd>
                  <dt className="col-sm-6">Total Loan Balance :</dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.balance)}</dd>
                  <dt className="col-sm-6">Number of EMIs :</dt>
                  <dd className="col-sm-6">{loan.no_of_emi} </dd>
                  <dt className="col-sm-6">Proposed Disbursement Date :</dt>
                  <dd className="col-sm-6">{moment(loan.disbursed_at).format('DD/MM/YYYY')}</dd>
                </dl>
                <h5>EMI Details: </h5>
                {
                  loanRecoveries.length > 0 &&
                  loanRecoveries.map((recovery, index) => {
                    return (
                      <div>
                        <CAlert color={'dark'} className={'mb-1'}>
                          <CRow>
                            <CCol sm={'12'}>
                              {
                                <>
                                  <dl className="row mb-0">
                                    <dt className="col-sm-3">EMI/Due Date :</dt>
                                    <dd className="col-sm-3">{moment(recovery.due_at).format('DD/MM/YYYY')}</dd>
                                    <dt className="col-sm-3">Due Amount :</dt>
                                    <dd className="col-sm-3">{General.formatRupees(recovery.total)}</dd>
                                  </dl>
                                </>
                              }
                            </CCol>
                          </CRow>
                        </CAlert>
                      </div>
                    )
                  })
                }
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}>Close</CButton>
          <CButton variant="outline" color="success" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => approveLoan()}>Approve</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
