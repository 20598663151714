import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup,
  CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CSpinner, CSwitch
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Backend from '../../utils/Backend';
import General from '../../utils/General';
import Notify from '../../utils/Notify';
import { months } from '../../constants/Options'
import CIcon from '@coreui/icons-react';
import CustomDatePicker from '../components/CustomDatePicker';


export default function LoanPreClosureModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [loan, setLoan] = useState(props.loan)
  const [preClosureData, setPreClosureData] = useState()
  const [loanRecoveries, setLoanRecoveries] = useState([])
  const closeLoan = async () => {
    setLoading(true)

    try {
      await Backend.closeLoan(loan.id)
      Notify.success('Loan closed successfully.')
      setLoading(false)
      props.onClose()
    } catch (error) {
      Notify.error(error.message)
      setLoading(false)
    }
  }



  const getLoanPreClosureData = async () => {
    try {
      const response = await Backend.getLoanPreClosureData(props.loan.id);
      if (response.data) {
        setPreClosureData({ ...response.data })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const initLoan = async () => {
    try {
      const response = await Backend.getLoan(props.loan.id);
      if (response.data) {
        setLoan({ ...response.data })
        setLoanRecoveries([...response.data.loan_recoveries])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initLoan()
    getLoanPreClosureData()
    return () => {
    }
  }, [])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'lg'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Loan Pre-Closure</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol>
                <h5>Loan Details: </h5>
                <dl className="row">
                  <dt className="col-sm-6">Amount : </dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.amount)}</dd>
                  <dt className="col-sm-6">Total Interest :</dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.total_interest)} @{loan.interest_rate}% pa</dd>
                  <dt className="col-sm-6">Total Loan Amount :</dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.total)}</dd>
                  <dt className="col-sm-6">Total Loan Balance :</dt>
                  <dd className="col-sm-6">{General.formatRupees(loan.balance)}</dd>
                  <dt className="col-sm-6">Number of EMIs :</dt>
                  <dd className="col-sm-6">{loan.no_of_emi} </dd>
                  <dt className="col-sm-6">Number of Paid EMIs :</dt>
                  <dd className="col-sm-6">{loan.recovered_emi}</dd>
                  <dt className="col-sm-6">Disbursement Date :</dt>
                  <dd className="col-sm-6">{moment(loan.disbursed_at).format('DD/MM/YYYY')}</dd>
                </dl>
                <h5>Pre-Closure Details as on {moment().format('DD/MM/YYYY')}: </h5>
                <dl className="row">
                  {
                    preClosureData &&
                    (
                      <>
                        <dt className="col-sm-6">Total Loan Amount (New): </dt>
                        <dd className="col-sm-6">{General.formatRupees(preClosureData.total_amount)}</dd>
                        <dt className="col-sm-6">Total Paid Amount : </dt>
                        <dd className="col-sm-6">{General.formatRupees(preClosureData.total_paid_with_interest)}</dd>
                        <dt className="col-sm-6">Total Due Amount :</dt>
                        <dd className="col-sm-6">{General.formatRupees(preClosureData.total_due_with_interest)}</dd>
                        <dt className="col-sm-6 mt-1 mb-1">

                        </dt>
                        <dd className="col-sm-6 mt-2 mb-2">
                          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
                            disabled={loading}
                            onClick={() => closeLoan()}>Close Loan</CButton>
                        </dd>

                      </>
                    )
                  }

                </dl>
                <h5>EMI Details: </h5>
                {
                  loanRecoveries.length > 0 &&
                  loanRecoveries.map(recovery => {
                    return (
                      <div>
                        <CAlert color={recovery.recovered_at && recovery.status == 'paid' ? 'success' : 'dark'} className={'mb-1'}>
                          <CRow>
                            <CCol sm={'12'}>
                              {
                                (recovery.recovered_at && recovery.status == 'paid') ?
                                  (<>
                                    <dl className="row mb-0">
                                      <dt className="col-sm-3">Payment Date :</dt>
                                      <dd className="col-sm-3">{moment(recovery.recovered_at).format('DD/MM/YYYY')}</dd>
                                      <dt className="col-sm-3">EMI Date :</dt>
                                      <dd className="col-sm-3">{moment(recovery.due_at).format('DD/MM/YYYY')}</dd>
                                      <dt className="col-sm-3">Paid Amount :</dt>
                                      <dd className="col-sm-3">{General.formatRupees(recovery.recovered_amount)}</dd>
                                      <dt className="col-sm-3">Due Amount :</dt>
                                      <dd className="col-sm-3">{General.formatRupees(recovery.total)}</dd>
                                    </dl>
                                  </>)
                                  :
                                  (
                                    <>
                                      <dl className="row mb-0">
                                        <dt className="col-sm-3">EMI/Due Date :</dt>
                                        <dd className="col-sm-3">{moment(recovery.due_at).format('DD/MM/YYYY')}</dd>
                                        <dt className="col-sm-3">Due Amount :</dt>
                                        <dd className="col-sm-3">{General.formatRupees(recovery.total)}</dd>
                                      </dl>
                                    </>
                                  )
                              }
                            </CCol>
                          </CRow>
                        </CAlert>
                      </div>
                    )
                  })
                }
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}>Close</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
