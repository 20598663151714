import React from 'react';
import ReactDOM from 'react-dom';
import { icons } from './assets/icons';
import '@coreui/coreui/scss/coreui.scss'
import './assets/css/custom.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import App from './App';

React.icons = icons

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
