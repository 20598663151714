import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import Backend from '../utils/Backend'

function Login() {
  const [loading, setLoading] = useState(false)
  const [currentForm, setCurrentForm] = useState('login')
  const [emailOrPhone, setEmailOrPhone] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [newPassword, setNewPasswword] = useState('')
  const [newPasswordConfirm, setNewPasswwordConfirm] = useState('')
  const [error, setError] = useState('')
  const { login, setCurrentUser } = useAuth()
  const history = useHistory()

  async function handleOtp() {
    setError('')
    setLoading(true)

    if (!emailOrPhone || emailOrPhone.length !== 10) {
      setError('Please enter 10 digits phone no')
      setLoading(false)
      return
    }

    try {
      await Backend.sendOtp(emailOrPhone)
      setCurrentForm('password_reset')
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function handleSetPassword() {
    setError('')
    setLoading(true)

    if (!otp || !newPassword || !newPasswordConfirm) {
      setError('Please enter all fields')
      setLoading(false)
      return
    }

    if (newPassword !== newPasswordConfirm) {
      setError('Password do not match')
      setLoading(false)
      return
    }

    try {
      await Backend.setPassword({
        otp,
        phone_no: emailOrPhone,
        password: newPassword,
        password_confirmation: newPasswordConfirm,
      })
      setCurrentForm('login')
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function handleLogin() {
    setError('')
    setLoading(true)

    if (!emailOrPhone || !password) {
      setError('Please enter email/phone no and password')
      setLoading(false)
      return
    }

    try {
      await Backend.csrfCookie()
      let user = await login(emailOrPhone, password)
      console.log(user.user.role)
      setCurrentUser(user)

      if (user.user.role !== 'employee') {
        history.push('/dashboard')
      } else {
        history.push('/dashboard/applications')
      }
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <div className='c-app c-default-layout flex-row align-items-center'>
      <CContainer>
        <CRow className='justify-content-center'>
          <CCol md='5'>
            <CCardGroup>
              <CCard className='p-4'>
                <CCardBody>
                  {/* <CForm
                    onSubmit={(e) => {
                      e.preventDefault()
                      handleLogin()
                    }}
                    type='post'
                  > */}
                  <div className={'text-center logo-container'}>
                    <img src={'../assets/logo.png'} alt={'logo'} />
                  </div>
                  {currentForm === 'login' && (
                    <>
                      <h5 className={'text-center'}>Login</h5>
                      <p className='text-muted text-center'>
                        Sign In to your account
                      </p>
                      <CForm
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleLogin()
                        }}
                      >
                        <CInputGroup className='mb-3'>
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name='cil-user' />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            value={emailOrPhone}
                            onChange={(e) => setEmailOrPhone(e.target.value)}
                            type='email'
                            placeholder='Email or Phone Number'
                          />
                        </CInputGroup>
                        <CInputGroup className='mb-4'>
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name='cil-lock-locked' />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            onChange={(e) => setPassword(e.target.value)}
                            type='password'
                            placeholder='Password'
                            autoComplete='current-password'
                          />
                        </CInputGroup>
                        <CRow className='text-right'>
                          <CCol xs='12'>
                            <CButton
                              disabled={loading}
                              color='dark'
                              className='px-4 w-100'
                              type='submit'
                              onClick={handleLogin}
                            >
                              {loading ? (
                                <>
                                  <CSpinner
                                    component='span'
                                    size='sm'
                                    aria-hidden='true'
                                  />{' '}
                                  Logging in...
                                </>
                              ) : (
                                'Login'
                              )}
                            </CButton>
                          </CCol>
                        </CRow>
                        <CRow className='text-right mt-2'>
                          <CCol xs='12'>
                            <CButton
                              disabled={loading}
                              color='dark'
                              className='px-4 w-100'
                              type='submit'
                              onClick={() => {
                                setError('')
                                setCurrentForm('otp')
                              }}
                            >
                              Set Password (For Employees)
                            </CButton>
                          </CCol>
                        </CRow>
                      </CForm>
                    </>
                  )}
                  {currentForm === 'otp' && (
                    <>
                      <h5 className={'text-center'}>OTP</h5>
                      <p className='text-muted text-center'>
                        Enter phone number to get one time password
                      </p>
                      <CInputGroup className='mb-3'>
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name='cil-phone' />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          value={emailOrPhone}
                          onChange={(e) => setEmailOrPhone(e.target.value)}
                          type='phone'
                          placeholder='Enter Phone Number'
                        />
                      </CInputGroup>
                      <CRow className='text-right'>
                        <CCol xs='12'>
                          <CButton
                            disabled={loading}
                            color='dark'
                            className='px-4 w-100'
                            type='submit'
                            onClick={handleOtp}
                          >
                            {loading ? (
                              <>
                                <CSpinner
                                  component='span'
                                  size='sm'
                                  aria-hidden='true'
                                />{' '}
                              </>
                            ) : (
                              'Get OTP'
                            )}
                          </CButton>
                        </CCol>
                      </CRow>
                      <CRow className='text-right mt-2'>
                        <CCol xs='12'>
                          <CButton
                            disabled={loading}
                            color='dark'
                            className='px-4 w-100'
                            type='submit'
                            onClick={() => {
                              setError('')
                              setCurrentForm('login')
                            }}
                          >
                            Back to Login
                          </CButton>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  {currentForm === 'password_reset' && (
                    <>
                      <h5 className={'text-center'}>Set Password</h5>
                      <CInputGroup className='mb-3'>
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name='cil-input' />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          type='text'
                          placeholder='Enter OTP'
                        />
                      </CInputGroup>
                      <CInputGroup className='mb-4'>
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name='cil-lock-locked' />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          value={newPassword}
                          onChange={(e) => setNewPasswword(e.target.value)}
                          type='password'
                          placeholder='Password'
                        />
                      </CInputGroup>
                      <CInputGroup className='mb-4'>
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name='cil-lock-locked' />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          value={newPasswordConfirm}
                          onChange={(e) =>
                            setNewPasswwordConfirm(e.target.value)
                          }
                          type='password'
                          placeholder='Confirm Password'
                        />
                      </CInputGroup>
                      <CRow className='text-right'>
                        <CCol xs='12'>
                          <CButton
                            disabled={loading}
                            color='dark'
                            className='px-4 w-100'
                            type='submit'
                            onClick={handleSetPassword}
                          >
                            {loading ? (
                              <>
                                <CSpinner
                                  component='span'
                                  size='sm'
                                  aria-hidden='true'
                                />{' '}
                              </>
                            ) : (
                              'Set Password'
                            )}
                          </CButton>
                        </CCol>
                      </CRow>
                      <CRow className='text-right mt-2'>
                        <CCol xs='12'>
                          <CButton
                            disabled={loading}
                            color='dark'
                            className='px-4 w-100'
                            type='submit'
                            onClick={() => {
                              setError('')
                              setCurrentForm('login')
                            }}
                          >
                            Back to Login
                          </CButton>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  {/* </CForm> */}
                  {error && <CAlert color='danger mt-2'>{error}</CAlert>}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
