import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton, CCol, CContainer, CFormGroup, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CInput, CSpinner
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import { accountTypes } from '../../constants/Options';
import Backend from '../../utils/Backend';
import General from '../../utils/General';
import Notify from '../../utils/Notify';
import { months } from '../../constants/Options';
import moment from 'moment';
import AuthManager from '../../utils/AuthManager';


export default function BulkLoanPaymentModal(props) {
  var lastTabIndex = 0
  const branchRef = useRef(null)
  const accountTypeRef = useRef(null)
  const fyRef = useRef(null)
  const monthRef = useRef(null)
  const yearRef = useRef(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState([])
  const [financialYears, setFinancialYears] = useState([])
  const [years, setYears] = useState([])
  const [loanRecoveries, setLoanRecoveries] = useState([])


  function validateFilterData() {
    setError('')
    if (!branchRef.current.value) {
      setError('Select Department')
      branchRef.current.focus()
      return false;
    } else if (!accountTypeRef.current.value) {
      setError('Select Account Type')
      accountTypeRef.current.focus()
      return false;
    } else if (!fyRef.current.value) {
      setError('Select Financial Year')
      fyRef.current.focus()
      return false;
    } else if (!monthRef.current.value) {
      setError('Select Contribution Month')
      monthRef.current.focus()
      return false;
    } else if (!yearRef.current.value) {
      setError('Select Contribution Year')
      yearRef.current.focus()
      return false;
    }

    setError('')
    getFilteredLoanRecoveries()
  }

  function changeRecoveryAmount(e, index) {
    let updatedRecoveries = loanRecoveries.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          amount: parseFloat(e.target.value) ? parseFloat(e.target.value) : ''
        }
      }

      return item
    })

    setLoanRecoveries([...updatedRecoveries])
  }

  async function payLoanRecoveries() {
    setLoading(true)
    try {
      for (let i = 0; i < loanRecoveries.length; i++) {
        let item = loanRecoveries[i]
        if (parseFloat(item.amount) > 0 && item.status === 'pending') {
          item.status = 'paid'
          item.recovered_amount = item.amount
          item.recovered_at = moment().format('YYYY-MM-DD')
          const response = await Backend.payLoanRecovery(item.id, item)
          if (response.data) {
            item.status = 'paid'
          }
        }
      }
      //setLoanRecoveries([...updatedRecoveries])
      setLoading(false)
    } catch (error) {
      //setError(error.message)
      setLoading(false)
      Notify.error(error.message)
    }
  }

  async function payLoanRecovery(item) {
    setLoading(true)

    try {
      if (parseFloat(item.amount) > 0 && item.status === 'pending') {
        item.status = 'paid'
        item.recovered_amount = item.amount
        item.recovered_at = moment().format('YYYY-MM-DD')
        const response = await Backend.payLoanRecovery(item.id, item)
        if (response.data) {
          item.status = 'paid'
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Notify.error(error.message)
    }
  }


  const getFilteredLoanRecoveries = async () => {
    try {
      let params = `?branch_id=${branchRef.current.value}&account_type=${accountTypeRef.current.value}` +
        `&financial_year_id=${fyRef.current.value}&month=${monthRef.current.value}&year=${yearRef.current.value}`
      const response = await Backend.getFilteredLoanRecoveries(params);
      if (response.data) {
        setLoanRecoveries([...response.data])
      }
    } catch (error) {
      setLoanRecoveries([])
      Notify.error(error.message)
      console.log(error)
    }
  }

  const initFinancialYears = async () => {
    try {
      const response = await Backend.getFYs();
      if (response.data) {
        setFinancialYears([...response.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const getCurrentFY = async () => {
    try {
      const response = await Backend.getCurrentFY();
      if (response.data) {
        fyRef.current.value = response.data.id
        setSelectedFYYears(response.data.id)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }


  const setSelectedFYYears = (fyId) => {
    yearRef.current.value = ''
    let fy = financialYears.find(item => item.id === fyId)
    if (fy) {
      fyRef.current.value = fyId
      setYears(General.getFYYears([fy]))
    } else {
      setYears([])
    }
  }

  const initBranches = async () => {
    try {
      const response = await Backend.getBranches();
      if (response.data) {
        setBranches([...response.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    initBranches()
    initFinancialYears()

    return () => {
    }
  }, [])

  useEffect(() => {
    if (financialYears.length) {
      getCurrentFY()
    }
  }, [financialYears])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'xl'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add Loan Payments </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {
                error && <CAlert color={'danger'} className={'w-100'}>{error}</CAlert>
              }
            </CRow>
            <CRow>
              <CCol className>
                <CFormGroup>
                  <CLabel>Department</CLabel>
                  <CSelect
                    innerRef={branchRef}
                    onChange={() => {
                      setLoanRecoveries([])
                    }}
                  >
                    <option value="">----</option>
                    {
                      branches.map(branch => {
                        return (<option value={branch.id}>{branch.name}</option>)
                      })
                    }
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel>Account Type</CLabel>
                  <CSelect
                    innerRef={accountTypeRef}
                    onChange={() => {
                      setLoanRecoveries([])
                    }}
                  >
                    <option value="">----</option>
                    {
                      AuthManager.getCurrentUser().role !== 'admin' ?
                        [
                          accountTypes.map(accountType => {
                            if (accountType.value === AuthManager.getCurrentUser().role)
                              return (<option value={accountType.value}>{accountType.label}</option>)
                          })
                        ]
                        :
                        accountTypes.map(accountType => {
                          return (<option value={accountType.value}>{accountType.label}</option>)
                        })
                    }

                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol className={'col-sm-3'}>
                <CFormGroup>
                  <CLabel>Financial Year</CLabel>
                  <CSelect
                    innerRef={fyRef}
                    onChange={(e) => {
                      if (e.target.value) {
                        setSelectedFYYears(e.target.value)
                      } else {
                        setYears([])
                      }

                      setLoanRecoveries([])
                    }}
                  >
                    <option value="">----</option>
                    {
                      financialYears.map(fy => {
                        return (<option value={fy.id}>{General.getFyName(fy)}</option>)
                      })
                    }
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel> Month</CLabel>
                  <CSelect
                    innerRef={monthRef}
                    onChange={() => {
                      setLoanRecoveries([])
                    }}
                  >
                    <option value="">----</option>
                    {
                      months.map(month => {
                        return (<option value={month.value}>{month.label}</option>)
                      })
                    }
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel> Year</CLabel>
                  <CSelect
                    innerRef={yearRef}
                    onChange={() => {
                      setLoanRecoveries([])
                    }}
                  >
                    <option value="">----</option>
                    {
                      years.map(year => {
                        return (<option value={year}>{year}</option>)
                      })
                    }
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol className={'col-sm-3'}>
                <CFormGroup>
                  <CLabel>&nbsp;</CLabel><br />
                  <CButton color="dark" className="btn-sm" style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    onClick={() => {
                      validateFilterData()
                    }} ><CIcon name="cil-filter" /> Show Employees</CButton>
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow style={{ minHeight: '100px' }}>
              {
                loanRecoveries.map((item, index) => {
                  lastTabIndex = index
                  return (
                    <CAlert color={item.status === 'paid' ? 'success' : 'dark'} className={'mb-1 w-100'}>
                      <CRow>
                        <CCol className={'col-sm-12'}>
                          {
                            (item.status === 'paid') ?
                              (
                                <dl className="row mb-0">
                                  <dt className="col-sm-3">Employee :</dt>
                                  <dd className="col-sm-3">{item.name}</dd>
                                  <dt className="col-sm-3">Loan month/year :</dt>
                                  <dd className="col-sm-3">{moment(item.due_at).format('MM/YYYY')}</dd>
                                  <dt className="col-sm-3">Payment Date :</dt>
                                  <dd className="col-sm-3">{moment(item.recovered_at).format('DD/MM/YYYY')}</dd>
                                  <dt className="col-sm-3">Amount :</dt>
                                  <dd className="col-sm-3">{General.formatRupees(item.recovered_amount)}</dd>
                                  <dt className="col-sm-3">Interest Rate :</dt>
                                  <dd className="col-sm-3">{parseFloat(item.interest_rate).toFixed(2)}% pa</dd>
                                </dl>
                              )
                              :
                              (

                                <CRow>
                                  <CCol className={'col-sm-3'}>
                                    <CFormGroup>
                                      <CLabel><strong>Employee:</strong></CLabel><br />
                                      <CLabel>{item.name}</CLabel>
                                    </CFormGroup>
                                  </CCol>
                                  <CCol className={'col-sm-3'}>
                                    <CFormGroup>
                                      <CLabel><strong>Due date :</strong></CLabel><br />
                                      <CLabel>{moment(item.due_at).format('DD/MM/YYYY')}</CLabel>
                                    </CFormGroup>
                                  </CCol>
                                  <CCol className={'col-sm-2'}>
                                    <CFormGroup>
                                      <CLabel>Amount</CLabel>
                                      <CInput
                                        value={item.amount}
                                        type="number"
                                        tabIndex={index}
                                        min={0}
                                        onChange={(e) => changeRecoveryAmount(e, index)}
                                      // readOnly={true}
                                      />
                                    </CFormGroup>
                                  </CCol>
                                  <CCol className={'col-sm-2'}>
                                    <CFormGroup>
                                      <CLabel>Interest rate per annum</CLabel>
                                      <CInput
                                        value={item.interest_rate}
                                        type="number"
                                        min={0}
                                        readOnly={true}
                                      />
                                    </CFormGroup>
                                  </CCol>
                                  <CCol className={'col-sm-2'}>
                                    <CFormGroup>
                                      <CLabel className={'w-100'}>&nbsp;</CLabel>
                                      <CButton color="dark" className="btn-sm pl-4 pr-4"
                                        disabled={loading || item.amount < 1}
                                        onClick={e => {
                                          e.preventDefault()
                                          payLoanRecovery(item)
                                        }}>
                                        {
                                          loading ?
                                            (
                                              <>
                                                <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                                              </>
                                            ) : 'Pay Loan'
                                        }
                                      </CButton>
                                    </CFormGroup>
                                  </CCol>
                                </CRow>
                              )
                          }
                        </CCol>
                      </CRow>
                    </CAlert>
                  )
                })
              }

            </CRow>

          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton color="dark" className="btn-sm pl-4 pr-4"
            tabIndex={lastTabIndex + 1}
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              payLoanRecoveries()
            }}>
            {
              loading ?
                (
                  <>
                    <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                  </>
                ) : 'Pay All Loans'
            }
          </CButton>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4 ml-2"
            disabled={loading}
            onClick={() => props.onClose()}>Close</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
