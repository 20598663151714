import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import ReactSelectStyles from '../../constants/ReactSelectStyles'
import Backend from '../../utils/Backend'
import General from '../../utils/General'
import Notify from '../../utils/Notify'
import CustomDatePicker from '../components/CustomDatePicker'

export default function BulkLoanModal(props) {
  const [params, setParams] = useState('?per_page=15&page=1')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [employees, setEmployees] = useState([])
  const [accounts, setAccounts] = useState([])
  const [loanDetails, setLoanDetails] = useState(null)
  const [loan, setLoan] = useState({
    account_id: '',
    amount: 0.0,
    interest_rate: 0.0,
    no_of_emi: 0,
    recovered_emi: 0,
    total_interest: 0,
    disbursed_at: moment().format('YYYY-MM-DD'),
  })

  const getEmployees = async (search) => {
    let urlParams = search ? `?per_page=15&page=1&name=${search}` : params

    return Backend.getEmployees(urlParams)
      .then((response) => {
        let options = []
        response.data.data.map((employee) => {
          options.push({
            label: `${employee.name} (${employee.branch.name})`,
            value: employee.id,
          })
        })

        let url = response.data.next_page_url
        let queryParams = url ? url.substr(url.indexOf('?')) : ''
        setParams(queryParams)

        return {
          options: options,
          hasMore: response.data.next_page_url !== null,
        }
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  const changeEmployee = (selectedOption) => {
    setAccounts([])
    setLoan({ ...loan, account_id: '' })
    setSelectedEmployee(selectedOption.value)
  }

  function handleFormChange(evt) {
    setError('')
    setLoanDetails(null)
    let name = evt.target.name
    let value = evt.target.value
    if (
      name == 'amount' ||
      name == 'interest_rate' ||
      name == 'total_interest'
    ) {
      value = parseFloat(parseFloat(value).toFixed(2))
    }

    if (name == 'no_of_emi') {
      value = parseInt(value)
    }

    setLoan({ ...loan, [name]: value })
  }

  function validateData() {
    if (!loan.account_id) {
      setError('Select  Account')
      return false
    } else if (isNaN(loan.amount) || loan.amount < 1) {
      setError('Loan amount must be number and greater than Zero')
      return false
    } else if (isNaN(loan.no_of_emi) || loan.no_of_emi < 1) {
      setError('Duration/Months must be number and greater than Zero')
      return false
    } else if (isNaN(loan.interest_rate)) {
      setError('Interest rate must be number and greater than Zero')
      return false
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      const response = await Backend.addLoan(loan)
      Notify.success('Loan added successfully.')
      if (props.onAdded) {
        props.onAdded()
      }
      props.onClose()
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  async function checkEligibility() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      const response = await Backend.validateLoan(loan)
      setLoanDetails({ ...response.data })
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  const initInterestRate = async () => {
    try {
      const response = await Backend.getSetting('loan_interest_rate')
      if (response.data) {
        setLoan({
          ...loan,
          interest_rate: parseFloat(response.data.value).toFixed(2),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAccounts = async () => {
    try {
      const response = await Backend.getEmployeeData(
        selectedEmployee,
        'accounts'
      )
      if (response.data) {
        setAccounts(response.data)
      }
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
    }
  }

  useEffect(() => {
    initInterestRate()
    return () => {}
  }, [])

  useEffect(() => {
    if (selectedEmployee) {
      getAccounts()
    }
    return () => {}
  }, [selectedEmployee])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'lg'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add Loan</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol sm='6'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <CFormGroup>
                    <CLabel>Select Employee</CLabel>
                    <AsyncPaginate
                      debounceTimeout={300}
                      onChange={(selectedOption) =>
                        changeEmployee(selectedOption)
                      }
                      loadOptions={(search) => getEmployees(search)}
                      isSearchable={true}
                      styles={ReactSelectStyles}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Select Account</CLabel>
                    <CSelect
                      value={loan.account_id}
                      name='account_id'
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    >
                      <option value=''>Select Account</option>
                      {accounts.map((account) => {
                        return (
                          <option value={account.id}>
                            {account.type.toUpperCase()} ({account.code})
                          </option>
                        )
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Amount</CLabel>
                    <CInput
                      value={loan.amount}
                      type='number'
                      name='amount'
                      min={0}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Duration/ No. of Months</CLabel>
                    <CInput
                      value={loan.no_of_emi}
                      type='number'
                      name='no_of_emi'
                      min={0}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Interest Rate (pa)</CLabel>
                    <CInput
                      value={loan.interest_rate}
                      type='number'
                      name='interest_rate'
                      min={0}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Date of Disbursement'}
                      value={moment().format('DD/MM/YYYY')}
                      onApply={(date) => {
                        setError('')
                        setLoan({
                          ...loan,
                          disbursed_at: moment(date).format('YYYY-MM-DD'),
                        })
                      }}
                    />
                  </CFormGroup>
                </CForm>
                {error && <CAlert color={'danger'}>{error}</CAlert>}
                <CRow className={'text-right'}>
                  <CCol>
                    <CButton
                      color='primary'
                      className='btn-sm pl-4 pr-4 mt-2'
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault()
                        checkEligibility()
                      }}
                    >
                      {loading ? (
                        <>
                          <CSpinner
                            component='span'
                            size='sm'
                            aria-hidden='true'
                          />{' '}
                          Processing...
                        </>
                      ) : (
                        'Check Eligibility/Details'
                      )}
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm='6'>
                <h5>Loan Details: </h5>
                {loanDetails != null && (
                  <>
                    <CAlert color={'success'} className={'mb-1'}>
                      {loanDetails.message}
                    </CAlert>
                    <dl className='row'>
                      <dt className='col-sm-6'>Amount : </dt>
                      <dd className='col-sm-6'>
                        {General.formatRupees(loanDetails.details.amount)}
                      </dd>
                      <dt className='col-sm-6'>Total Interest :</dt>
                      <dd className='col-sm-6'>
                        {General.formatRupees(loanDetails.details.interest)} @
                        {loan.interest_rate}% pa
                      </dd>
                      <dt className='col-sm-6'>Total Loan Amount :</dt>
                      <dd className='col-sm-6'>
                        {General.formatRupees(loanDetails.details.total)}
                      </dd>
                      <dt className='col-sm-6'>Duration :</dt>
                      <dd className='col-sm-6'>{loan.no_of_emi} Months</dd>
                      <dt className='col-sm-6'>Disbursement Date :</dt>
                      <dd className='col-sm-6'>
                        {moment(loan.disbursed_at).format('DD/MM/YYYY')}
                      </dd>
                    </dl>
                    <h5>EMI Details: </h5>
                    {loanDetails.details.emis.map((emi) => {
                      return (
                        <div>
                          <CAlert color={'info'} className={'mb-1'}>
                            <CRow>
                              <CCol sm={'12'}>
                                Due at{' '}
                                {moment(`${emi.due_at}`).format('DD MMMM YYYY')}{' '}
                                : {General.formatRupees(emi.total)}
                              </CCol>
                            </CRow>
                          </CAlert>
                        </div>
                      )
                    })}
                  </>
                )}
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : (
              'Add Loan'
            )}
          </CButton>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4 ml-1'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
