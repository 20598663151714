import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
  CSwitch,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Backend from '../../utils/Backend'
import General from '../../utils/General'
import Notify from '../../utils/Notify'
import { months } from '../../constants/Options'
import CIcon from '@coreui/icons-react'
import { AsyncPaginate } from 'react-select-async-paginate'
import ReactSelectStyles from '../../constants/ReactSelectStyles'

export default function SingleContributionModal(props) {
  const monthRef = useRef(null)
  const [params, setParams] = useState('?per_page=15&page=1')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [selectedAccountId, setSelectedAccountId] = useState(null)
  const [selectedFiscalAccountId, setSelectedFiscalAccountId] = useState([])
  const [fiscalAccounts, setFiscalAccounts] = useState([])
  const [years, setYears] = useState([])
  const [contributions, setContributions] = useState([])
  const [contribution, setContribution] = useState({
    fiscal_account_id: '',
    month: '',
    year: '',
    amount: 0.0,
    interest_rate: 0.0,
  })

  const getEmployees = async (search) => {
    let urlParams = search ? `?per_page=15&page=1&name=${search}` : params

    console.log('--' + urlParams)

    return Backend.getEmployees(urlParams)
      .then((response) => {
        let options = []
        response.data.data.map((employee) => {
          options.push({
            label: `${employee.name} (${employee.branch.name})`,
            value: employee.id,
          })
        })

        let url = response.data.next_page_url
        let queryParams = url ? url.substr(url.indexOf('?')) : ''
        setParams(queryParams)

        return {
          options: options,
          hasMore: response.data.next_page_url !== null,
        }
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  const changeEmployee = (selectedOption) => {
    setAccounts([])
    setSelectedAccountId(null)
    setFiscalAccounts([])
    setContribution({ ...contribution, fiscal_account_id: '' })
    setSelectedEmployeeId(selectedOption.value)
  }

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name
    let value = evt.target.value
    if (name == 'amount' || name == 'interest_rate') {
      value = parseFloat(parseFloat(value).toFixed(2))
    }

    setContribution({ ...contribution, [name]: value })
  }

  function validateData() {
    if (!contribution.fiscal_account_id) {
      setError('Select Fiscal Account')
      return false
    } else if (!contribution.month) {
      setError('Select Month')
      monthRef.current.focus()
      return false
    } else if (!contribution.year) {
      setError('Select Year')
      return false
    } else if (isNaN(contribution.amount) || contribution.amount < 1) {
      setError('Contribution amount must be number and greater than Zero')
      return false
    } else if (
      isNaN(contribution.interest_rate) ||
      contribution.interest_rate < 1
    ) {
      setError('Interest rate must be number and greater than Zero')
      return false
    }
    let fiscalAccount = fiscalAccounts.find(
      (item) => item.id == selectedFiscalAccountId
    )
    if (
      !General.validateContributionDate(
        contribution,
        fiscalAccount.financial_year
      )
    ) {
      setError(
        'Please select month and year between selected Fiscal Account(Financial Year)'
      )
      return false
    }

    setError('')
    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      const response = await Backend.addContribution(contribution)
      Notify.success('Contribution added successfully.')
      setContribution({ ...contribution, month: '' })
      contributions.push(response.data)
      setContributions([...contributions])
      setLoading(false)
      monthRef.current.focus()
    } catch (error) {
      setError(error.message)
      monthRef.current.focus()
      setLoading(false)
    }
  }

  const deleteContribution = async (item) => {
    setDeleting(true)
    try {
      await Backend.deleteContribution(item.id)
      setContributions([
        ...contributions.filter((contribution) => item.id !== contribution.id)
      ])
      setDeleting(false)
    } catch (error) {
      setDeleting(false)
      Notify.error(error.message)
    }
  }

  const getContributions = async () => {
    try {
      const response = await Backend.fiscalAccountData(
        selectedFiscalAccountId,
        'contributions'
      )
      if (response.data) {
        setContributions([...response.data])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const initInterestRate = async () => {
    try {
      const response = await Backend.getSetting('epf_interest_rate')
      if (response.data) {
        setContribution({
          ...contribution,
          interest_rate: parseFloat(response.data.value).toFixed(2),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAccounts = async () => {
    try {
      const response = await Backend.getEmployeeData(
        selectedEmployeeId,
        'accounts'
      )
      if (response.data) {
        setAccounts(response.data)
      }
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
    }
  }

  const getFiscalAccounts = async () => {
    try {
      const response = await Backend.getAccountData(
        selectedAccountId,
        'fiscal-accounts'
      )
      if (response.data) {
        setFiscalAccounts(response.data)
      }
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
    }
  }

  const initYears = () => {
    let fiscalAccount = fiscalAccounts.find(
      (item) => item.id == selectedFiscalAccountId
    )
    if (fiscalAccount) {
      setYears([
        fiscalAccount.financial_year.start_year,
        fiscalAccount.financial_year.end_year,
      ])
    } else {
      setYears([])
    }
  }

  useEffect(() => {
    initInterestRate()
    return () => {}
  }, [])

  useEffect(() => {
    if (selectedEmployeeId) {
      getAccounts()
    }
    return () => {}
  }, [selectedEmployeeId])

  useEffect(() => {
    if (selectedAccountId) {
      getFiscalAccounts()
    }
    return () => {}
  }, [selectedAccountId])

  useEffect(() => {
    if (selectedFiscalAccountId) {
      getContributions()
      initYears()
    } else {
      setContributions([])
      setYears([])
    }
    return () => {}
  }, [selectedFiscalAccountId])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'lg'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add Contributions </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol sm='6'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <CFormGroup>
                    <CLabel>Select Employee</CLabel>
                    <AsyncPaginate
                      debounceTimeout={300}
                      onChange={(selectedOption) =>
                        changeEmployee(selectedOption)
                      }
                      loadOptions={(search) => getEmployees(search)}
                      isSearchable={true}
                      styles={ReactSelectStyles}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Select Account</CLabel>
                    <CSelect
                      value={selectedAccountId ? selectedAccountId : ''}
                      onChange={(e) => {
                        setSelectedAccountId(e.target.value)
                      }}
                    >
                      <option value=''>Select Account</option>
                      {accounts.map((account) => {
                        return (
                          <option value={account.id}>
                            {account.type.toUpperCase()} ({account.code})
                          </option>
                        )
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Select Fiscal Account</CLabel>
                    <CSelect
                      value={contribution.fiscal_account_id}
                      name='fiscal_account_id'
                      onChange={(e) => {
                        handleFormChange(e)
                        setSelectedFiscalAccountId(e.target.value)
                      }}
                    >
                      <option value=''>Select Fiscal Account</option>
                      {fiscalAccounts.map((fa) => {
                        return (
                          <option value={fa.id}>
                            {General.getFiscalAccountFY(fa)}
                          </option>
                        )
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Month</CLabel>
                    <CSelect
                      innerRef={monthRef}
                      value={contribution.month}
                      type='text'
                      name='month'
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value=''>Select Month</option>
                      {months.map((month) => (
                        <option value={month.value}>{month.label}</option>
                      ))}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Year</CLabel>
                    <CSelect
                      value={contribution.year}
                      type='text'
                      name='year'
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value=''>Select Year</option>
                      {years.map((year) => (
                        <option value={year}>{year}</option>
                      ))}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Amount</CLabel>
                    <CInput
                      value={contribution.amount}
                      type='number'
                      name='amount'
                      min={0}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Interest Rate (pa)</CLabel>
                    <CInput
                      value={contribution.interest_rate}
                      type='number'
                      name='interest_rate'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                </CForm>
                {error && <CAlert color={'danger'}>{error}</CAlert>}
                <CRow className={'text-right'}>
                  <CCol>
                    <CButton
                      color='dark'
                      className='btn-sm pl-4 pr-4'
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault()
                        handleSubmit()
                      }}
                    >
                      {loading ? (
                        <>
                          <CSpinner
                            component='span'
                            size='sm'
                            aria-hidden='true'
                          />{' '}
                          Saving...
                        </>
                      ) : (
                        'Add Contribution'
                      )}
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm='6'>
                <h5>Contributions</h5>
                {contributions.map((contribution) => {
                  return (
                    <div>
                      <CAlert color={'info'} className={'mb-1'}>
                        <CRow>
                          <CCol sm={'10'}>
                            {moment(`${contribution.date}`).format('MMMM YYYY')}
                            : &nbsp;
                            {General.formatRupees(contribution.amount)} -{' '}
                            {parseFloat(
                              contribution.interest_rate / 12
                            ).toFixed(2)}
                            %
                          </CCol>
                          <CCol sm={'2'}>
                            {selectedFiscalAccountId.status == 'active' &&
                              !deleting && (
                                <CIcon
                                  onClick={() => {
                                    deleteContribution(contribution)
                                  }}
                                  name='cil-trash'
                                  className='trash icon-btn'
                                ></CIcon>
                              )}
                          </CCol>
                        </CRow>
                      </CAlert>
                    </div>
                  )
                })}
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
