import {
  CButton, CCol, CContainer, CFormGroup, CInput, CLabel, CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner
} from '@coreui/react'
import moment from 'moment'
import React, { useState } from 'react'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'
import CustomDatePicker from '../components/CustomDatePicker'

export default function EditRecoveryModal(props) {
  const [loading, setLoading] = useState(false)
  const [recovery, setRecovery] = useState(props.recovery)

  const updateEmi = async () => {
    setLoading(true)
    try {
      await Backend.adjustLoanRecovery(recovery.id, recovery)
      props.onUpdated()
    } catch (error) {
      Notify.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CModal
      show={props.show}
      closeOnBackdrop={false}
      centered={true}
      onClose={() => props.onClose()}
    >
      <CModalHeader>
        <CModalTitle>Edit Loan Recovery</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol>
              <CFormGroup>
                <CustomDatePicker
                  label={'Payment Date'}
                  value={recovery.recovered_at}
                  onApply={(date) => {
                    // setError('')
                    setRecovery({
                      ...recovery,
                      recovered_at: moment(date).format('YYYY-MM-DD'),
                    })
                  }}
                />
              </CFormGroup>
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel>Amount</CLabel>
                <CInput
                  value={recovery.recovered_amount}
                  type="number"
                  min={0}
                  // readOnly={true}
                  onChange={(e) => {
                    // changeRecoveryAmount(e, index)
                    setRecovery({
                      ...recovery,
                      recovered_amount: e.target.value,
                    })
                  }}
                />
              </CFormGroup>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={loading}
          variant="outline"
          color="dark"
          className="btn-sm pl-4 pr-4"
          onClick={() => props.onClose()}
        >
          Cancel
        </CButton>
        <CButton
          color="dark"
          className="btn-sm pl-4 pr-4"
          disabled={loading}
          onClick={(e) => {
            e.preventDefault()
            updateEmi(recovery)
          }}
        >
          {loading ? (
            <>
              <CSpinner component="span" size="sm" aria-hidden="true" />{' '}
              Saving...
            </>
          ) : (
            'Update EMI'
          )}
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
