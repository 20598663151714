import moment from 'moment'

export default class General {
  static debounce(func, wait, immediate) {
    var timeout

    return function () {
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  static formatRupees(amount, prefix = true) {
    if (amount == null) return ''
    
    let formattedRupees = ''

    if (prefix) {
      formattedRupees += '₹'
    }

    formattedRupees += new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
      .format(amount)
      .toString()

    return formattedRupees
  }

  static getFyName(fy) {
    return `${moment(fy.start).format('MM/YYYY')} - ${moment(fy.end).format(
      'MM/YYYY'
    )}`
  }

  static getFYYears(financialYears) {
    if (!financialYears.length) {
      return []
    }

    let years = []
    financialYears.forEach((fy) => {
      years.push(fy.start_year)
      years.push(fy.end_year)
    })

    return [...new Set(years)]
  }

  static getFiscalAccountFY(fiscalAccount) {
    return `${moment(fiscalAccount.financial_year.start).format(
      'MM/YYYY'
    )} - ${moment(fiscalAccount.financial_year.end).format('MM/YYYY')}`
  }

  static getFiscalAccountYears(fiscalAccounts) {
    if (!fiscalAccounts.length) {
      return []
    }

    let years = []
    fiscalAccounts.forEach((fa) => {
      years.push(fa.financial_year.start_year)
      years.push(fa.financial_year.end_year)
    })

    return [...new Set(years)]
  }

  static validateContributionDate(contribution, financialYear) {
    let selectedDate = moment(
      `01-${contribution.month}-${contribution.year}`,
      'DD-M-YYYY'
    )
    let startFY = moment(financialYear.start)
    let endFY = moment(financialYear.end)
    return (
      selectedDate.isSameOrAfter(startFY) && selectedDate.isSameOrBefore(endFY)
    )
  }
}
