import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup,
  CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CSpinner
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { roles } from '../../constants/Options';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';

export default function UserModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(props.user ? props.user :
    {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: 'epf',
    })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;
    setUser({ ...user, [name]: value })
  }

  function validateData() {
    if (!user.name) {
      setError('Enter user name')
      return false;
    } else if (!user.email) {
      setError('Enter valid email')
      return false;
    } else if (!user.role) {
      setError('Select role')
      return false;
    }

    if (!props.user || (props.user && user.password)) {
      if (!user.password) {
        setError('Enter password')
        return false;
      } else if (!user.password_confirmation) {
        setError('Enter password confirmation')
        return false;
      } else if (user.password !== user.password_confirmation) {
        setError('Password doesn\'t match')
        return false;
      }
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (!props.user) {
        const response = await Backend.addUser(user)
        props.onAdded(response.data)
      } else {
        const response = await Backend.updateUser(user.id, user)
        props.onUpdated(response.data)
      }
      props.onClose()
      Notify.success('User added/updated successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.user) {
      setUser(props.user)
    }

    return () => {
    }
  }, [])


  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit User</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {
                error && <CAlert color="danger" className="w-100">{error}</CAlert>
              }
              <CCol sm="12">
                <CForm onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                  <CFormGroup>
                    <CLabel>Name</CLabel>
                    <CInput
                      value={user.name}
                      type="text"
                      name="name"
                      placeholder="Enter username"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Email</CLabel>
                    <CInput
                      value={user.email}
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Select Role</CLabel>
                    <CSelect
                      value={user.role}
                      name="role"
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value="">Select Role</option>
                      {
                        roles.map((role) => {
                          if (AuthManager.getCurrentUser().role !== 'admin' && role.value === 'admin') {
                            return
                          }

                          return (
                            <option value={role.value}>{role.label}</option>
                          )
                        })
                      }
                    </CSelect>
                  </CFormGroup>
                  {
                    props.user &&
                    (
                      <CFormGroup>
                        <CAlert color={'info'}>Leave password and password confirmation field blank if you dont want to change Password</CAlert>
                      </CFormGroup>
                    )
                  }

                  <CFormGroup>
                    <CLabel>Password</CLabel>
                    <CInput
                      value={user.password}
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Confirm Password</CLabel>
                    <CInput
                      value={user.password_confirmation}
                      type="password"
                      name="password_confirmation"
                      placeholder="Enter password"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}>Cancel</CButton>
          <CButton color="dark" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit()
            }}>
            {
              loading ?
                (
                  <>
                    <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                  </>
                ) : 'Add/Update User'
            }
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
