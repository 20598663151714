import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CDataTable,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPagination,
  CRow,
  CSelect,
  CSpinner,
} from '@coreui/react'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'
import Loading from '../components/Loading'

export default function MergeEmployeeModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const nameRef = useRef('')
  const [employees, setEmployees] = useState([])
  const [mainEmployeeId, setMainEmployeeId] = useState()
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  function handleSelectedEmployee(id) {
    console.log(id, mainEmployeeId)
    if (mainEmployeeId === id) return

    let index = selectedEmployees.indexOf(id)

    if (index < 0) {
      setSelectedEmployees([...selectedEmployees, id])
    } else {
      setSelectedEmployees([
        ...selectedEmployees.filter((itemId) => id !== itemId),
      ])
    }
  }

  const filterEmployees = async () => {
    if (!nameRef.current.value.length) {
      setEmployees([])
      setMainEmployeeId('')
      setSelectedEmployees([])
      return
    }
    await getEmployees()
  }

  const getEmployees = async () => {
    setLoading(true)
    setMainEmployeeId('')
    setSelectedEmployees([])
    try {
      let params = `?per_page=50&page=${currentPage}`
      if (nameRef.current.value) {
        params += `&name=${nameRef.current.value}`
      }

      const response = await Backend.getEmployees(params)
      if (response.data) {
        setEmployees([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      Notify.error(error.message)
      setLoading(false)
    }
  }

  function validateData() {
    if (!selectedEmployees.length || !mainEmployeeId) {
      setError('Select Main Employee and Other Employee(s)')
      return false
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      await Backend.mergeEmployees({
        main_id: mainEmployeeId,
        employee_ids: selectedEmployees,
      })
      Notify.success('Employees merged successfully.')

      // nameRef.current.value = ''
      filterEmployees()
      setMainEmployeeId('')
      setSelectedEmployees([])
      setError('')
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <>
      <CModal
        size={'lg'}
        show={props.show}
        centered={true}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Merge Employees</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CFormGroup>
                  <CLabel>Search Employee:</CLabel>
                  <CInput
                    innerRef={nameRef}
                    name='name'
                    placeholder={'Search by Name...'}
                    onKeyUp={(evt) => {
                      if (evt.key === 'Enter' || !evt.target.value) {
                        filterEmployees()
                      }
                    }}
                  />
                </CFormGroup>
              </CCol>

              <CCol sm='12'>
                <CFormGroup>
                  <CLabel>Select Main Employee:</CLabel>
                  <CSelect
                    onChange={(evt) => {
                      setMainEmployeeId(evt.target.value)
                    }}
                    value={mainEmployeeId}
                  >
                    <option value=''>-- Select --</option>
                    {employees.map((employee) => {
                      return (
                        <option value={employee.id}>
                          #{employee.id} - {employee.name} -{' '}
                          {employee.father_name} - {employee.branch.name}
                        </option>
                      )
                    })}
                  </CSelect>
                </CFormGroup>
              </CCol>
            </CRow>
            <CCard style={{ padding: '0', border: 'none' }}>
              <CCardBody style={{ padding: '0', border: 'none' }}>
                <CLabel>Select other Employee(s):</CLabel>

                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <CDataTable
                      items={employees}
                      striped
                      outlined={true}
                      dense
                      fields={[
                        {
                          key: 'actions',
                          label: '#',
                        },
                        {
                          key: 'id',
                          label: 'Employee ID',
                        },
                        {
                          key: 'name',
                          label: 'Name',
                        },
                        {
                          key: 'father_name',
                          label: 'Father/Mother Name',
                        },
                        {
                          key: 'branch_name',
                          label: 'Department',
                        },
                        {
                          key: 'type',
                          label: 'Account Type',
                        },
                      ]}
                      scopedSlots={{
                        id: (item) => {
                          return <td>#{item.id}</td>
                        },
                        branch: (item) => {
                          return <td>{item.branch_name}</td>
                        },
                        dob: (item) => {
                          return (
                            <td>{moment(item.dob).format('DD/MM/YYYY')}</td>
                          )
                        },
                        type: (item) => {
                          return <td>{item.type.toUpperCase()}</td>
                        },
                        code: (item) => {
                          return <td>{item.code.toUpperCase()}</td>
                        },
                        service_started_at: (item) => {
                          return (
                            <td>
                              {moment(item.service_started_at).format(
                                'DD/MM/YYYY'
                              )}
                            </td>
                          )
                        },
                        actions: (item) => {
                          return (
                            <td>
                              <CInput
                                disabled={mainEmployeeId === item.id}
                                type='checkbox'
                                checked={selectedEmployees.includes(item.id)}
                                onChange={() => handleSelectedEmployee(item.id)}
                              />
                            </td>
                          )
                        },
                      }}
                    />
                    <CPagination
                      className={'mt-2'}
                      activePage={currentPage}
                      pages={lastPage}
                      onActivePageChange={(i) => setCurrentPage(i)}
                    ></CPagination>
                  </>
                )}
              </CCardBody>
            </CCard>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading || !selectedEmployees.length || !mainEmployeeId}
            onClick={(e) => {
              handleSubmit()
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Merging Employees...
              </>
            ) : (
              'Merge Employees'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
