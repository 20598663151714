import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CPagination,
  CRow,
  CSelect,
  CTooltip,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { accountTypes } from '../constants/Options'
import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import ConfirmationModal from './modals/ConfirmationModal'
import PaymentModal from './modals/PaymentModal'

function Payments(props) {
  const fyRef = useRef(null)
  const accTypeRef = useRef(null)
  const accRef = useRef(null)
  const statusRef = useRef(null)
  const typeRef = useRef(null)
  const voucherRef = useRef(null)
  const bankRef = useRef(null)
  const purposeRef = useRef(null)
  const particularRef = useRef(null)
  const [payments, setPayments] = useState([])
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [accountHeads, setAccountHeads] = useState([])
  const [financialYears, setFinancialYears] = useState([])
  const [loading, setLoading] = useState(true)
  const [exporting, setExporting] = useState(false)
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const perPage = 20

  const filterPayments = async () => {
    await getPayments()
  }

  const exportExcel = async () => {
    if (!fyRef.current.value) {
      Notify.error('Select Financial Year')
      return
    }

    setExporting(true)

    try {
      let params = `?fy=${fyRef.current.value}`
      if (accTypeRef.current.value) {
        params += `&account_type=${accTypeRef.current.value}`
      }
      if (accRef.current.value) {
        params += `&account_head=${accRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&type=${typeRef.current.value}`
      }
      if (statusRef.current.value) {
        params += `&status=${statusRef.current.value}`
      }
      if (voucherRef.current.value) {
        params += `&voucher_no=${voucherRef.current.value}`
      }
      if (bankRef.current.value) {
        params += `&bank_ref_no=${bankRef.current.value}`
      }
      if (purposeRef.current.value) {
        params += `&purpose=${purposeRef.current.value}`
      }
      if (particularRef.current.value) {
        params += `&particulars=${particularRef.current.value}`
      }

      let data = {}
      data['headers'] = AuthManager.getHeaders()
      const response = await fetch(
        `${window.Api.Payments}/export${params}`,
        data
      )
      response.blob().then((blob) => showInOtherTab(blob))
    } catch (error) {
      Notify.error(error.message)
    }
    setExporting(false)
  }

  function showInOtherTab(blob) {
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  const deletePayment = async () => {
    if (!selectedPayment) {
      setShowConfirmModal(false)
      return
    }

    try {
      await Backend.deletePayment(selectedPayment.id)
      Notify.success('Payment deleted successfully.')
      getPayments()
      setShowConfirmModal(false)
    } catch (error) {
      Notify.error(error.message)
      setShowConfirmModal(false)
    }
    setSelectedPayment(null)
  }

  const getPayments = async () => {
    if (!fyRef.current.value) return

    setLoading(true)

    try {
      let params = `?per_page=${perPage}&page=${currentPage}`
      if (fyRef.current.value) {
        params += `&fy=${fyRef.current.value}`
      }
      if (accTypeRef.current.value) {
        params += `&account_type=${accTypeRef.current.value}`
      }
      if (accRef.current.value) {
        params += `&account_head=${accRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&type=${typeRef.current.value}`
      }
      if (statusRef.current.value) {
        params += `&status=${statusRef.current.value}`
      }
      if (voucherRef.current.value) {
        params += `&voucher_no=${voucherRef.current.value}`
      }
      if (bankRef.current.value) {
        params += `&bank_ref_no=${bankRef.current.value}`
      }
      if (purposeRef.current.value) {
        params += `&purpose=${purposeRef.current.value}`
      }
      if (particularRef.current.value) {
        params += `&particulars=${particularRef.current.value}`
      }

      const response = await Backend.getPayments(params)
      if (response.data) {
        setLastPage(response.data.last_page)
        setPayments([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initAccountHeads = async () => {
    try {
      const response = await Backend.getAllAccountHeads()
      if (response.data) {
        setAccountHeads([...response.data])
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  const initFinancialYears = async () => {
    try {
      const response = await Backend.getFYs()
      if (response.data) {
        setFinancialYears([...response.data])
        getCurrentFY()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getCurrentFY = async () => {
    try {
      const response = await Backend.getCurrentFY()
      if (response.data) {
        fyRef.current.value = response.data.id
        getPayments()
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  useEffect(() => {
    initAccountHeads()
    initFinancialYears()
  }, [])

  useEffect(() => {
    getPayments()
  }, [currentPage])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Payments</h4>
        </CCol>
        <CCol className="text-right">
          <CButton
            color="dark"
            className="btn-sm"
            onClick={() => setShowAddPaymentModal(!showAddPaymentModal)}
          >
            <CIcon name="cil-plus" /> Add Payment
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Payments</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol
                  className={
                    AuthManager.getCurrentUser().role === 'admin'
                      ? 'col-2'
                      : 'col-3'
                  }
                >
                  <CSelect innerRef={fyRef} onChange={() => filterPayments()}>
                    <option value="">--Select FY--</option>
                    {financialYears.map((fy) => {
                      return (
                        <option value={fy.id}>
                          {fy.start_year} - {fy.end_year}
                        </option>
                      )
                    })}
                  </CSelect>
                </CCol>
                <CCol
                  className={'col-3'}
                  style={{
                    display:
                      AuthManager.getCurrentUser().role === 'admin'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <CSelect
                    innerRef={accTypeRef}
                    onChange={() => filterPayments()}
                  >
                    <option value="">--Select Account Type--</option>
                    {accountTypes.map((accountType) => {
                      return (
                        <option value={accountType.value}>
                          {accountType.label}
                        </option>
                      )
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-3'}>
                  <CSelect innerRef={accRef} onChange={() => filterPayments()}>
                    <option value="">--Select Account Head--</option>
                    {accountHeads.map((accountHead) => {
                      if (accountHead.type === 'Payment') {
                        return (
                          <option value={accountHead.id}>
                            {accountHead.name}
                          </option>
                        )
                      }
                    })}
                  </CSelect>
                </CCol>
                <CCol
                  className={
                    AuthManager.getCurrentUser().role === 'admin'
                      ? 'col-2'
                      : 'col-3'
                  }
                >
                  <CSelect innerRef={typeRef} onChange={() => filterPayments()}>
                    <option value="">--Select Type--</option>
                    <option value="Cash">Cash</option>
                    <option value="Bank">Bank</option>
                    <option value="Contra">Contra</option>
                  </CSelect>
                </CCol>
                <CCol
                  className={
                    AuthManager.getCurrentUser().role === 'admin'
                      ? 'col-2'
                      : 'col-3'
                  }
                >
                  <CSelect
                    innerRef={statusRef}
                    onChange={() => filterPayments()}
                  >
                    <option value="">--All Status--</option>
                    <option value="Received">Received</option>
                    <option value="Pending">Pending</option>
                  </CSelect>
                </CCol>
              </CRow>
              <CRow className={'mt-2'}>
                <CCol className={'col-2'}>
                  <CInput innerRef={voucherRef} placeholder={'Voucher No.'} />
                </CCol>
                <CCol className={'col-2'}>
                  <CInput innerRef={bankRef} placeholder={'Bank ref No.'} />
                </CCol>
                <CCol className={'col-2'}>
                  <CInput innerRef={purposeRef} placeholder={'Purpose'} />
                </CCol>
                <CCol className={'col-2'}>
                  <CInput
                    innerRef={particularRef}
                    placeholder={'Particulars'}
                  />
                </CCol>
                <CCol className={'col-2'}>
                  <CButton
                    color="dark"
                    className="btn-sm w-100"
                    onClick={() => filterPayments()}
                  >
                    <CIcon name="cil-filter" /> Filter
                  </CButton>
                </CCol>
                <CCol className={'col-2'}>
                  <CButton
                    color="dark"
                    className="btn-sm w-100"
                    disabled={exporting}
                    onClick={() => exportExcel()}
                  >
                    <CIcon name="cil-share" />
                    {exporting ? ' Exporting...' : ' Export Excel'}
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <CDataTable
                        items={payments}
                        itemsPerPage={100}
                        pagination
                        outlined={true}
                        fields={[
                          {
                            key: 'slNo',
                            label: '#',
                          },
                          {
                            key: 'account_type',
                            label: 'Account Type',
                          },
                          {
                            key: 'account_head',
                            label: 'Account Head',
                          },
                          {
                            key: 'date',
                            label: 'Date',
                          },
                          {
                            key: 'voucher_no',
                            label: 'Voucher No',
                          },
                          {
                            key: 'particulars',
                            label: 'Particulars',
                          },
                          {
                            key: 'purpose',
                            label: 'Purpose',
                          },
                          {
                            key: 'cash',
                            label: 'Cash',
                          },
                          {
                            key: 'bank',
                            label: 'Bank',
                          },
                          {
                            key: 'actions',
                            label: '',
                          },
                        ]}
                        scopedSlots={{
                          slNo: (item, index) => {
                            return (
                              <td>{(currentPage - 1) * perPage + ++index}</td>
                            )
                          },
                          account_head: (item) => {
                            return (
                              <td>
                                {item.type === 'Contra'
                                  ? item.contra_account_head
                                  : item.account_head.name}
                              </td>
                            )
                          },
                          date: (item) => {
                            return (
                              <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                            )
                          },
                          cash: (item) => {
                            // if (
                            //   item.type == "Contra" &&
                            //   item.purpose == "Deposit"
                            // ) {
                            //   return (
                            //     <td>{General.formatRupees(item.amount)}</td>
                            //   );
                            // }

                            return <td>{General.formatRupees(item.cash)}</td>
                          },
                          bank: (item) => {
                            // if (
                            //   item.type == "Contra" &&
                            //   item.purpose == "Withdraw"
                            // ) {
                            //   return (
                            //     <td>{General.formatRupees(item.amount)}</td>
                            //   );
                            // }

                            return <td>{General.formatRupees(item.bank)}</td>
                          },
                          actions: (item) => {
                            return (
                              <td>
                                <CButtonGroup className={'btn-sm'}>
                                  <CTooltip
                                    content="Edit Payment"
                                    placement="bottom"
                                  >
                                    <CButton
                                      color="dark"
                                      variant="outline"
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedPayment(item)
                                        setShowAddPaymentModal(true)
                                      }}
                                    >
                                      <CIcon name="cil-pen-alt"></CIcon>
                                    </CButton>
                                  </CTooltip>
                                  <CTooltip
                                    content="Delete Payment"
                                    placement="bottom"
                                  >
                                    <CButton
                                      color="danger"
                                      variant="outline"
                                      className={'btn-sm'}
                                      onClick={() => {
                                        setSelectedPayment(item)
                                        setShowConfirmModal(true)
                                      }}
                                    >
                                      <CIcon name="cil-trash"></CIcon>
                                    </CButton>
                                  </CTooltip>
                                </CButtonGroup>
                              </td>
                            )
                          },
                        }}
                      />
                      <CPagination
                        className={'mt-2'}
                        activePage={currentPage}
                        pages={lastPage}
                        onActivePageChange={(i) => setCurrentPage(i)}
                      ></CPagination>
                    </>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showAddPaymentModal && (
        <PaymentModal
          show={showAddPaymentModal}
          payment={selectedPayment}
          accountHeads={accountHeads}
          onAdded={() => {
            setShowAddPaymentModal(false)
            setSelectedPayment(null)
            getPayments()
          }}
          onClose={() => {
            setShowAddPaymentModal(false)
            setSelectedPayment(null)
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={`Confirm delete Payment?`}
          content={() => (
            <>
              <CAlert color="warning" className="w-100">
                Are you sure you want to delete this Payment?
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          confirmText="Delete"
          onConfirm={() => {
            deletePayment()
          }}
        />
      )}
    </>
  )
}

export default withRouter(Payments)
