import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CTooltip
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import ConfirmationModal from './modals/ConfirmationModal'
import DependentModal from './modals/DependentModal'

export default function Dependents() {
  const [dependents, setDependents] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedDependent, setSelectedFY] = useState()
  const [showDependentModal, setShowDependentModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const deleteDependent = async () => {
    if (selectedDependent == null) return

    try {
      await Backend.deleteDependent(selectedDependent.id)
      const updatedDependents = dependents.filter((item) => {
        if (item.id != selectedDependent.id) {
          return item
        }
      })
      setDependents([...updatedDependents])
      setShowConfirmModal(false)
      setSelectedFY(null)
      Notify.success('Dependents deleted successfully.')
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const initDependents = async () => {
    try {
      const response = await Backend.getDependents()
      if (response.data) {
        setDependents([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    initDependents()
  }, [])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Dependents</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowDependentModal(!showDependentModal)}
          >
            <CIcon name='cil-plus' /> Add Dependent
          </CButton>
        </CCol>
      </CRow>

      {loading ? (
        <Loading />
      ) : (
        <CRow className={'mt-3'}>
          <CCol xs='12' lg='12'>
            <CCard>
              <CCardHeader>Dependents</CCardHeader>
              <CCardBody>
                <CDataTable
                  items={dependents}
                  striped
                  itemsPerPage={100}
                  pagination
                  outlined={true}
                  fields={[
                    {
                      key: 'name',
                      label: 'Name',
                    },
                    {
                      key: 'relation',
                      label: 'Relation',
                    },
                    {
                      key: 'age',
                      label: 'Age',
                    },
                    {
                      key: 'married',
                      label: 'Married',
                    },
                    {
                      key: 'monthly_income',
                      label: 'Monthly Income',
                    },
                    {
                      key: 'actions',
                      label: 'Actions',
                    },
                  ]}
                  scopedSlots={{
                    married: (item) => {
                      return <td>{item.married ? 'Yes' : 'No'}</td>
                    },
                    actions: (item) => {
                      return (
                        <td>
                          <CButtonGroup className={'btn-sm'}>
                            <CTooltip
                              content='Edit Dependents'
                              placement='bottom'
                            >
                              <CButton
                                color='dark'
                                variant='outline'
                                className={'btn-sm'}
                                onClick={() => {
                                  setSelectedFY(item)
                                  setShowDependentModal(true)
                                }}
                              >
                                <CIcon name='cil-pen-alt'></CIcon>
                              </CButton>
                            </CTooltip>
                            <CTooltip
                              content='Delete Dependents'
                              placement='bottom'
                            >
                              <CButton
                                color='danger'
                                variant='outline'
                                className={'btn-sm'}
                                onClick={() => {
                                  setSelectedFY(item)
                                  setShowConfirmModal(true)
                                }}
                              >
                                <CIcon name='cil-trash'></CIcon>
                              </CButton>
                            </CTooltip>
                          </CButtonGroup>
                        </td>
                      )
                    },
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      )}

      {showDependentModal && (
        <DependentModal
          show={showDependentModal}
          dependent={selectedDependent}
          onAdded={(fy) => {
            // dependents.unshift(fy)
            setDependents([])
            initDependents()
          }}
          onUpdated={(fy) => {
            // const updatedDependents = dependents.map(item => {
            //   if (item.id === fy.id) {
            //     return fy
            //   } else {
            //     return item
            //   }
            // })
            // setDependents([...updatedDependents])
            setDependents([])
            initDependents()
          }}
          onClose={() => {
            setSelectedFY(null)
            setShowDependentModal(false)
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={`Confirm delete Dependents?`}
          content={() => (
            <>
              <CAlert color='warning' className='w-100'>
                Are you sure you want to delete?
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          confirmText='Delete'
          onConfirm={() => {
            deleteDependent()
          }}
        />
      )}
    </>
  )
}
