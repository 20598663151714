import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CSpinner
} from '@coreui/react';
import React, { useState } from 'react';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import UploadFile from '../components/UploadFile';
import {accountTypes} from '../../constants/Options'

export default function ImportEmployeeModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [importData, setImportData] = useState({
    type: '',
    path: '',
  })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;

    setImportData({ ...importData, [name]: value })
  }

  function validateData() {
    if (!importData.type) {
      setError('Select Employee Type')
      return false;
    } else if (!importData.path) {
      setError('Upload Excel File')
      return false;
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      await Backend.importEmployees(importData)
      props.onImported()
      props.onClose()
      Notify.success('Employees imported successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>Import Employees</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color="danger" className="w-100">
                  {error}
                </CAlert>
              )}
              <CCol sm="12">
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>Employee Type:</CLabel>
                    <CSelect
                      value={importData.type}
                      name={"type"}
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value="">--Select Employee Type--</option>
                      {accountTypes.map((accountType) => {
                        return (
                          <option value={accountType.value}>
                            {accountType.label}
                          </option>
                        );
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <UploadFile
                      label={"Select Excel file to import"}
                      buttonLabel={"Upload File"}
                      folder={"employees"}
                      accepts={".xlsx"}
                      onUploaded={(url, path) => {
                        setImportData({ ...importData, path: path });
                      }}
                    />
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel style={{ wordBreak: "break-all" }}>
                      Uploaded File: {importData.path}
                    </CLabel>
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color="dark"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component="span" size="sm" aria-hidden="true" />{" "}
                Importing...
              </>
            ) : (
              "Import Employees"
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
