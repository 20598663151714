import React, { useRef, useState } from 'react'
import { CButton, CFormGroup, CLabel, CSpinner } from '@coreui/react'
import Notify from '../../utils/Notify'
import Backend from '../../utils/Backend'

export default function UploadFile(props) {
  const [loading, setLoading] = useState(false)
  const fileInput = useRef()

  const onFileChange = async (e) => {
    let file = e.target.files[0]

    if (!file)
      return

    setLoading(true)
    try {
      const response = await Backend.uploadFile(file, props.folder);
      setLoading(false)
      props.onUploaded(response.url, response.path)
    } catch (error) {
      setLoading(false)
      props.onUploaded('')
      Notify.error(error.message)
    }

  }

  const onUploadBtnClick = () => {
    fileInput.current.click()
  }


  return (
    <div>
      <CFormGroup>
        <CLabel>{props.label ? props.label : 'Select File'} : </CLabel>
        <input
          ref={fileInput}
          onChange={e => onFileChange(e)}
          type="file"
          className="form-control "
          style={{ visibility: 'hidden', height: 0, padding: 0 }}
          accept={props.accepts}
        />

        <CButton color="dark" className="btn-sm pl-4 pr-4 w-100"
          disabled={loading}
          onClick={e => {
            e.preventDefault()
            onUploadBtnClick()
          }}>
          {
            loading ?
              (
                <>
                  <CSpinner component="span" size="sm" aria-hidden="true" />  Uploading...
                </>
              ) : props.buttonLabel ? props.buttonLabel : 'Upload File'
          }
        </CButton>
      </CFormGroup>
    </div>
  )
}
