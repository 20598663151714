import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
} from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'
import '../../constants/Api'
import { accountTypes } from '../../constants/Options'
import { months } from '../../constants/Options'
import General from '../../utils/General'

export default function ExportContributionModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedfinancialYear, setSelectedFinancialYear] = useState(null)
  const [financialYears, setFinancialYears] = useState([])
  const [years, setYears] = useState([])
  const [exportData, setExportData] = useState({
    type: '',
    financial_year: '',
    month: '',
    year: '',
  })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name
    let value = evt.target.value

    setExportData({ ...exportData, [name]: value })
  }

  function validateData() {
    if (!exportData.type) {
      setError('Select Employee Type')
      return false
    }
    if (!exportData.financial_year) {
      setError('Select Financial Year')
      return false
    }
    if (!exportData.month) {
      setError('Select Month')
      return false
    }
    if (!exportData.year) {
      setError('Select Year')
      return false
    }

    if (!General.validateContributionDate(exportData, selectedfinancialYear)) {
      setError('Please select month and year of selected Financial Year')
      return false
    }

    return true
  }

  const initFinancialYears = async () => {
    try {
      const response = await Backend.getFYs()
      if (response.data) {
        setFinancialYears([...response.data])
      }
      setLoading(false)
    } catch (error) {
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const setSelectedFYYears = (fyId) => {
    setExportData({ ...exportData, financial_year: fyId, year: '' })

    let fy = financialYears.find((item) => parseInt(item.id) === parseInt(fyId))
    if (fy) {
      setSelectedFinancialYear(fy)
      setYears(General.getFYYears([fy]))
    } else {
      setYears([])
    }
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    // console.log(JSON.stringify(exportData))
    // return

    try {
      let data = {}
      data['headers'] = AuthManager.getHeaders()
      const response = await fetch(
        `${window.Api.Contributions}/export?type=${exportData.type}&month=${exportData.month}&year=${exportData.year}&start=${selectedfinancialYear.start_year}&end=${selectedfinancialYear.end_year}`,
        data
      )
      response.blob().then((blob) => showInOtherTab(blob))
      props.onClose()
      Notify.success('Contributions exported successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  function showInOtherTab(blob) {
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  useEffect(() => {
    initFinancialYears()

    return () => {}
  }, [])

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>Export Employees' Contribtutions</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <CFormGroup>
                    <CLabel>Employee Type:</CLabel>
                    <CSelect
                      value={exportData.type}
                      name={'type'}
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value=''>--Select Employee Type--</option>
                      {accountTypes.map((accountType) => {
                        return (
                          <option value={accountType.value}>
                            {accountType.label}
                          </option>
                        )
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Financial Year</CLabel>
                    <CSelect
                      onChange={(e) => {
                        handleFormChange(e)

                        if (e.target.value) {
                          setSelectedFYYears(e.target.value)
                        } else {
                          setYears([])
                        }
                      }}
                      name={'financial_year'}
                    >
                      <option value=''>----</option>
                      {financialYears.map((fy) => {
                        return (
                          <option value={fy.id}>{General.getFyName(fy)}</option>
                        )
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Month: </CLabel>
                    <CSelect
                      value={exportData.month}
                      type='text'
                      name='month'
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value=''>Select Month</option>
                      {months.map((month) => (
                        <option value={month.value}>{month.label}</option>
                      ))}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Select Year:</CLabel>
                    <CSelect
                      onChange={(e) => handleFormChange(e)}
                      name={'year'}
                    >
                      <option value=''>----</option>
                      {years.map((year) => {
                        return <option value={year}>{year}</option>
                      })}
                    </CSelect>
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Exporting...
              </>
            ) : (
              'Export Contributions'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
