
import React from 'react'
import { useLocation } from 'react-router-dom'

export default function NotFound(){
  let location = useLocation()

  return(
    <div>
      <h2>404</h2>
    </div>
  )
}