import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
} from '@coreui/react'
import moment from 'moment'
import React, { useRef, useState, useEffect } from 'react'
import '../../constants/Api'
import AuthManager from '../../utils/AuthManager'
import Notify from '../../utils/Notify'
import CustomDatePicker from '../components/CustomDatePicker'
import { accountTypes } from '../../constants/Options'
import Backend from '../../utils/Backend'

export default function RPReportModal(props) {
  const accTypeRef = useRef()
  const fyRef = useRef(null)
  const [fys, setFys] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [peroid, setPeriod] = useState({ start: '', end: '' })

  function validateData() {
    if (
      AuthManager.getCurrentUser().role === 'admin' &&
      !accTypeRef.current.value
    ) {
      setError('Select Account Type')
      return false
    } else if (!fyRef.current.value) {
      setError('Select Financial Year')
      return false
    } else if (!peroid.start) {
      setError('Select From Date')
      return false
    } else if (!peroid.end) {
      setError('Select To Date')
      return false
    }

    if (moment(peroid.start).isAfter(moment(peroid.end))) {
      setError('From date should be before To date')
      return false
    }

    setError('')
    return true
  }

  async function handleSubmit() {
    if (!validateData()) {
      return
    }

    setLoading(true)
    try {
      let accountType =
        AuthManager.getCurrentUser().role === 'admin'
          ? accTypeRef.current.value
          : AuthManager.getCurrentUser().role
      let data = {}
      data['headers'] = AuthManager.getHeaders()
      const response = await fetch(
        `${window.Api.Base}/rp-reports?start=${peroid.start}&end=${peroid.end}&account_type=${accountType}&fy=${fyRef.current.value}`,
        data
      )
      response.blob().then((blob) => showInOtherTab(blob))
      setLoading(false)
      props.onClose()
    } catch (error) {
      Notify.error(error.message)
      setLoading(false)
    }
  }

  function showInOtherTab(blob) {
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  const initFYs = async () => {
    try {
      const response = await Backend.getFYs()
      if (response.data) {
        setFys([...response.data])
        getCurrentFY()
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  const getCurrentFY = async () => {
    try {
      const response = await Backend.getCurrentFY()
      if (response.data) {
        fyRef.current.value = response.data.id
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  useEffect(() => {
    initFYs()
  }, [])

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        closeOnBackdrop={false}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Receipt and Payment Report</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color="danger" className="w-100">
                  {error}
                </CAlert>
              )}
              <CCol sm="12">
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <CFormGroup
                    style={{
                      display:
                        AuthManager.getCurrentUser().role === 'admin'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <CLabel>Account Type</CLabel>
                    <CSelect innerRef={accTypeRef}>
                      <option value="">Select Account Type</option>
                      {accountTypes.map((accountType) => {
                        return (
                          <option value={accountType.value}>
                            {accountType.label}
                          </option>
                        )
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CSelect innerRef={fyRef}>
                      <option value="">--Select FY--</option>
                      {fys.map((fy) => {
                        return (
                          <option value={fy.id}>
                            {fy.start_year} - {fy.end_year}
                          </option>
                        )
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Report Period (From)'}
                      onApply={(date) => {
                        setPeriod({
                          ...peroid,
                          start: moment(date).format('YYYY-MM-DD'),
                        })
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Report Period (To)'}
                      onApply={(date) => {
                        setPeriod({
                          ...peroid,
                          end: moment(date).format('YYYY-MM-DD'),
                        })
                      }}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color="dark"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {loading ? (
              <>
                <CSpinner component="span" size="sm" aria-hidden="true" />{' '}
                Generating Report...
              </>
            ) : (
              'Generate Report'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
