import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CSwitch,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'

export default function DependentModal(props) {
  const dependentModel = {
    employee_id: AuthManager.getCurrentUser().id,
    name: '',
    age: '',
    relation: '',
    married: false,
    monthly_income: 0,
  }

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [dependent, setFy] = useState(props.dependent || dependentModel)

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name
    let value = evt.target.value
    setFy({ ...dependent, [name]: value })
  }

  function validateData() {
    if (!dependent.name) {
      setError('Please enter name')
      return false
    }
    if (!dependent.age) {
      setError('Please enter Age')
      return false
    }
    if (!dependent.relation) {
      setError('Please enter relation')
      return false
    }
    if (dependent.monthly_income === '') {
      setError('Please enter Monthly Income')
      return false
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (!props.dependent) {
        const response = await Backend.addDependent(dependent)
        props.onAdded(response.data)
      } else {
        const response = await Backend.updateDependent(dependent.id, dependent)
        props.onUpdated(response.data)
      }
      props.onClose()
      Notify.success('Dependent added/updated successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.dependent) {
      setFy(props.dependent)
    }

    return () => {}
  }, [])

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Dependent</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <CFormGroup>
                    <CLabel>Name</CLabel>
                    <CInput
                      value={dependent.name}
                      name={'name'}
                      type={'text'}
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Relation</CLabel>
                    <CInput
                      value={dependent.relation}
                      name={'relation'}
                      type={'text'}
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Age</CLabel>
                    <CInput
                      value={dependent.age}
                      name={'age'}
                      type={'number'}
                      min={0}
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Monthly Income</CLabel>
                    <CInput
                      value={dependent.monthly_income}
                      name={'monthly_income'}
                      type={'number'}
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    />
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel>Married: </CLabel>

                    <CSwitch
                      className='text-right ml-2'
                      checked={dependent.married}
                      name={'married'}
                      color='dark'
                      size='lg'
                      variant='3d'
                      shape='pill'
                      onChange={(e) => {
                        setFy({ ...dependent, married: !dependent.married })
                      }}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : (
              'Add/Update Dependent'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
