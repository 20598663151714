import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CContainer } from '@coreui/react'
import AuthenticatedRoute from '../components/AuthenticatedRoute'
import Dashboard from '../pages/Dashboard'
import Settings from '../pages/Settings'
import Branches from '../pages/Branches'
import FinancialYears from '../pages/FinancialYears'
import Employees from '../pages/Employees'
import Employee from '../pages/Employee'
import Loans from '../pages/Loans'
import Contributions from '../pages/Contributions'
import Users from '../pages/Users'
import Signatories from '../pages/Signatories'
import AccountHeads from '../pages/AccountHeads'
import Receipts from '../pages/Receipts'
import Payments from '../pages/Payments'
import FiscalAccounts from '../pages/FiscalAccounts'
import ReceiptPaymentReports from '../pages/ReceiptPaymentReports'
import EPFReports from '../pages/EPFReports'
import ESSReports from '../pages/ESSReports'
import NPSReports from '../pages/NPSReports'
import Dependents from '../pages/Dependents'
import Unauthorized from '../pages/403'

export default function Main() {
  return (
    <div className='c-main'>
      <CContainer fluid>
        <Switch>
          <AuthenticatedRoute exact path='/dashboard' component={Dashboard} />
          <AuthenticatedRoute
            exact
            path='/dashboard/employees'
            component={Employees}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/employees/:employeeId'
            component={Employee}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/fiscal-accounts'
            component={FiscalAccounts}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/departments'
            component={Branches}
          />
          <AuthenticatedRoute exact path='/dashboard/loans' component={Loans} />
          <AuthenticatedRoute
            exact
            path='/dashboard/contributions'
            component={Contributions}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/financial-years'
            component={FinancialYears}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/receipts'
            component={Receipts}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/payments'
            component={Payments}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/reports/receipts-payments'
            component={ReceiptPaymentReports}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/reports/epf-accounts'
            component={EPFReports}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/reports/ess-accounts'
            component={ESSReports}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/reports/nps-accounts'
            component={NPSReports}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/account-heads'
            component={AccountHeads}
          />
          <AuthenticatedRoute exact path='/dashboard/users' component={Users} />
          <AuthenticatedRoute
            exact
            path='/dashboard/signatories'
            component={Signatories}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/settings'
            component={Settings}
          />
          <AuthenticatedRoute
            exact
            path='/dashboard/dependents'
            component={Dependents}
          />
          {/* <AuthenticatedRoute
            exact
            path='/dashboard/403'
            component={Unauthorized}
          /> */}
        </Switch>
      </CContainer>
    </div>
  )
}
