import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup,
  CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSpinner, CTextarea
} from '@coreui/react';
import React, { useState, useEffect } from 'react';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';

export default function BranchModal(props) {
  const branchModel = {
    id: '',
    name: '',
  }

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [branch, setBranch] = useState(branchModel)

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;
    setBranch({ ...branch, [name]: value })
  }

  function validateData() {
    if (!branch.name) {
      setError('Enter Department name')
      return false;
    }
    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (!props.branch) {
        const response = await Backend.addBranch(branch)
        props.onAdded(response.data)
      } else {
        const response = await Backend.updateBranch(branch.id, branch)
        props.onUpdated(response.data)
      }
      props.onClose()
      Notify.success('Department added/updated successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.branch) {
      setBranch(props.branch)
    }

    return () => {
    }
  }, [])


  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Department</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {
                error && <CAlert color="danger" className="w-100">{error}</CAlert>
              }
              <CCol sm="12">
                <CForm onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                  <CFormGroup>
                    <CLabel>Name</CLabel>
                    <CInput
                      value={branch.name}
                      type="text"
                      name="name"
                      placeholder="Enter Department Name"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}>Cancel</CButton>
          <CButton color="dark" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit()
            }}>
            {
              loading ?
                (
                  <>
                    <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                  </>
                ) : 'Add/Update Department'
            }
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
