import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup,
  CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CSpinner, CSwitch
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Backend from '../../utils/Backend';
import General from '../../utils/General';
import Notify from '../../utils/Notify';
import { months } from '../../constants/Options'
import CIcon from '@coreui/icons-react';
import CustomDatePicker from '../components/CustomDatePicker';


export default function AdvancePaymentModal(props) {
  const accounts = props.accounts
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [payment, setPayment] = useState(
    {
      account_id: '',
      amount: 0.00,
      date: moment().format('YYYY-MM-DD')
    })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;
    if (name == 'amount') {
      value = parseFloat(parseFloat(value).toFixed(2))
    }
    setPayment({ ...payment, [name]: value })
  }

  function validateData() {
    if (!payment.account_id) {
      setError('Select  Account')
      return false;
    } else if (isNaN(payment.amount) || payment.amount < 1) {
      setError('Advance payment amount must be number and greater than Zero')
      return false;
    } 

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      const response = await Backend.addAdvancePayment(payment)
      Notify.success('Payment added successfully.')
      if (props.onAdded) {
        props.onAdded()
      }
      props.onClose()
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'md'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add Advance Payment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol sm="12">
                <CForm onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                  <CFormGroup>
                    <CLabel>Select  Account</CLabel>
                    <CSelect
                      value={payment.account_id}
                      name="account_id"
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    >
                      <option value="">Select Account</option>
                      {
                        accounts.map(account => {
                          return (<option value={account.id}>{account.type.toUpperCase()} ({account.code})</option>)
                        })
                      }
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Amount</CLabel>
                    <CInput
                      value={payment.amount}
                      type="number"
                      name="amount"
                      min={0}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Date'}
                      value={moment().format('DD/MM/YYYY')}
                      onApply={(date) => {
                        setError('')
                        setPayment({
                          ...payment,
                          date: moment(date).format('YYYY-MM-DD'),
                        })
                      }} />
                  </CFormGroup>
                </CForm>
                {
                  error && <CAlert color={'danger'}>{error}</CAlert>
                }
              </CCol>
            </CRow>

          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton color="dark" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit()
            }}>
            {
              loading ?
                (
                  <>
                    <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                  </>
                ) : 'Add Advance Payment'
            }
          </CButton>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4 mr-1"
            disabled={loading}
            onClick={() => props.onClose()}>Close</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
