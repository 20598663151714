import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CPagination,
  CRow,
  CSelect,
  CTooltip,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { accountTypes } from '../constants/Options'
import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import AccountModal from './modals/AccountModal'
import ConfirmationModal from './modals/ConfirmationModal'
import EmployeeModal from './modals/EmployeeModal'
import ExportEmployeeModal from './modals/ExportEmployeeModal'
import ImportBasicPayModal from './modals/ImportBasicPayModal'
import ImportEmployeeModal from './modals/ImportEmployeeModal'
import MergeEmployeeModal from './modals/MergeEmployeeModal'

function Employees(props) {
  const nameRef = useRef(null)
  const branchRef = useRef(null)
  const typeRef = useRef(null)
  const [total, setTotal] = useState(0)
  const [employees, setEmployees] = useState([])
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [addEmployeeAccount, setAddEmployeeAccount] = useState()
  const [showEmployeeModal, setShowEmployeeModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showAccountConfirmModal, setShowAccountConfirmModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showMergeImportModal, setShowMergeModal] = useState(false)
  const [showImportBasicPayModal, setShowImportBasicPayModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const filterEmployees = async () => {
    await getEmployees()
  }

  const deleteEmployee = async () => {
    if (selectedEmployee == null) return

    try {
      await Backend.deleteEmployee(selectedEmployee.id)
      const updatedEmployees = employees.filter((item) => {
        if (item.id != selectedEmployee.id) {
          return item
        }
      })
      setEmployees([...updatedEmployees])
      setShowConfirmModal(false)
      setSelectedEmployee(null)
      Notify.success('Employee deleted successfully.')
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const getEmployees = async () => {
    setLoading(true)

    try {
      let params = `?per_page=15&page=${currentPage}`
      if (nameRef.current.value) {
        params += `&name=${nameRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&type=${typeRef.current.value}`
      }
      if (branchRef.current.value) {
        params += `&branch=${branchRef.current.value}`
      }
      const response = await Backend.getEmployees(params)
      if (response.data) {
        setTotal(response.data.total)
        setLastPage(response.data.last_page)
        setEmployees([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initBranches = async () => {
    try {
      const response = await Backend.getBranches()
      if (response.data) {
        setBranches([...response.data])
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  useEffect(() => {
    initBranches()
  }, [])

  useEffect(() => {
    getEmployees()
  }, [currentPage])

  return (
    <>
      <CRow>
        <CCol className={'col-3'}>
          <h4>Employees</h4>
        </CCol>
        <CCol className='text-right'>
          {AuthManager.getCurrentUser().role === 'admin' && (
            <>
              <CButton
                color='dark'
                className='btn-sm'
                onClick={() => setShowImportModal(!showImportModal)}
              >
                <CIcon name='cil-cloud-download' /> Import Employees
              </CButton>
              <CButton
                color='dark'
                className='btn-sm ml-2'
                onClick={() => setShowMergeModal(!showMergeImportModal)}
              >
                <CIcon name='cil-envelope-letter' /> Merge Employees
              </CButton>
            </>
          )}

          <CButton
            color='dark'
            className='btn-sm ml-2'
            onClick={() => setShowExportModal(!showExportModal)}
          >
            <CIcon name='cil-share' /> Export Employees
          </CButton>

          <CButton
            color='dark'
            className='btn-sm ml-2'
            onClick={() => setShowImportBasicPayModal(!showImportBasicPayModal)}
          >
            <CIcon name='cil-cloud-download' /> Import Employees Data
          </CButton>

          <CButton
            color='dark'
            className='btn-sm ml-2'
            onClick={() => setShowEmployeeModal(!showEmployeeModal)}
          >
            <CIcon name='cil-plus' /> Add Employee
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Employees</h6>
                  <h6>Total Accounts: {total}</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol className={'col-4'}>
                  <CInput
                    innerRef={nameRef}
                    name='name'
                    placeholder={'Search by Name...'}
                    onKeyUp={(evt) => {
                      if (evt.key === 'Enter' || !evt.target.value) {
                        filterEmployees()
                      }
                    }}
                  />
                </CCol>
                <CCol
                  className={'col-2'}
                  style={{
                    display:
                      AuthManager.getCurrentUser().role == 'admin'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <CSelect
                    innerRef={typeRef}
                    name='type'
                    onChange={(evt) => {
                      filterEmployees()
                    }}
                  >
                    <option value=''>--All Account Type--</option>
                    {accountTypes.map((accountType) => {
                      return (
                        <option value={accountType.value}>
                          {accountType.label}
                        </option>
                      )
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-4'}>
                  <CSelect
                    innerRef={branchRef}
                    name='branch'
                    onChange={(evt) => {
                      filterEmployees()
                    }}
                  >
                    <option value=''>Select Department</option>
                    {branches.map((branch) => {
                      return <option value={branch.id}>{branch.name}</option>
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-2'}>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    onChange={() => filterEmployees()}
                  >
                    <CIcon name='cil-filter' /> Filter
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <CDataTable
                    items={employees}
                    striped
                    outlined={true}
                    dense
                    fields={[
                      {
                        key: 'id',
                        label: 'Employee ID',
                      },
                      {
                        key: 'name',
                        label: 'Name',
                      },
                      {
                        key: 'nps',
                        label: 'NPS ID/PRAN NO',
                      },
                      {
                        key: 'father_name',
                        label: 'Father/Mother Name',
                      },
                      {
                        key: 'branch_name',
                        label: 'Department',
                      },
                      {
                        key: 'type',
                        label: 'Account Type',
                      },
                      {
                        key: 'code',
                        label: 'Account Code',
                      },
                      {
                        key: 'dob',
                        label: 'DOB',
                      },
                      {
                        key: 'service_started_at',
                        label: 'Service start date',
                      },
                      {
                        key: 'actions',
                        label: 'Actions',
                      },
                    ]}
                    scopedSlots={{
                      id: (item) => {
                        return <td>#{item.id}</td>
                      },
                      branch: (item) => {
                        return <td>{item.branch_name}</td>
                      },
                      nps: (item) => {
                        if (item.type === 'nps') {
                          return (
                            <td>
                              {item.nps_id} / {item.nps_pran_no}
                            </td>
                          )
                        }
                        return <td></td>
                      },
                      dob: (item) => {
                        return <td>{moment(item.dob).format('DD/MM/YYYY')}</td>
                      },
                      type: (item) => {
                        return <td>{item.type.toUpperCase()}</td>
                      },
                      code: (item) => {
                        return <td>{item.code.toUpperCase()}</td>
                      },
                      service_started_at: (item) => {
                        return (
                          <td>
                            {moment(item.service_started_at).format(
                              'DD/MM/YYYY'
                            )}
                          </td>
                        )
                      },
                      actions: (item) => {
                        return (
                          <td>
                            <CButtonGroup className={'btn-sm'}>
                              <CTooltip
                                content='View Employee Account'
                                placement='bottom'
                              >
                                <CButton
                                  color='success'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    // console.log(item.employee_id);
                                    props.history.push(
                                      `/dashboard/employees/${item.id}`
                                    )
                                  }}
                                >
                                  <CIcon name='cil-grid'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Edit Employee'
                                placement='bottom'
                              >
                                <CButton
                                  color='dark'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedEmployee(item)
                                    setShowEmployeeModal(true)
                                  }}
                                >
                                  <CIcon name='cil-pen-alt'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Delete Employee'
                                placement='bottom'
                              >
                                <CButton
                                  color='danger'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedEmployee(item)
                                    setShowConfirmModal(true)
                                  }}
                                >
                                  <CIcon name='cil-trash'></CIcon>
                                </CButton>
                              </CTooltip>
                            </CButtonGroup>
                          </td>
                        )
                      },
                    }}
                  />
                  <CPagination
                    className={'mt-2'}
                    activePage={currentPage}
                    pages={lastPage}
                    onActivePageChange={(i) => setCurrentPage(i)}
                  ></CPagination>
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showEmployeeModal && (
        <EmployeeModal
          show={showEmployeeModal}
          employee={selectedEmployee}
          branches={branches}
          onAdded={(employee) => {
            setAddEmployeeAccount(employee)
            // employees.unshift(employee);
            // setEmployees([...employees]);
            filterEmployees()
            setShowAccountConfirmModal(true)
          }}
          onUpdated={(employee) => {
            // const updatedEmployees = employees.map((item) => {
            //   if (item.id == employee.id) {
            //     return employee;
            //   } else {
            //     return item;
            //   }
            // });
            // setEmployees([...updatedEmployees]);
            filterEmployees()
          }}
          onClose={() => {
            setSelectedEmployee(null)
            setShowEmployeeModal(false)
          }}
        />
      )}

      {showAccountModal && (
        <AccountModal
          show={showAccountModal}
          employee={addEmployeeAccount}
          onClose={() => {
            setAddEmployeeAccount(null)
            setShowAccountModal(false)
          }}
        />
      )}

      {showImportModal && (
        <ImportEmployeeModal
          show={showImportModal}
          onImported={() => {
            setShowImportModal(false)
            getEmployees()
          }}
          onClose={() => {
            setShowImportModal(false)
          }}
        />
      )}

      {showMergeImportModal && (
        <MergeEmployeeModal
          show={showMergeImportModal}
          onMerged={() => {
            setShowMergeModal(false)
            getEmployees()
          }}
          onClose={() => {
            setShowMergeModal(false)
          }}
        />
      )}

      {showImportBasicPayModal && (
        <ImportBasicPayModal
          show={showImportBasicPayModal}
          onImported={() => {
            setShowImportBasicPayModal(false)
          }}
          onClose={() => {
            setShowImportBasicPayModal(false)
          }}
        />
      )}

      {showExportModal && (
        <ExportEmployeeModal
          show={showExportModal}
          onExported={() => {
            setShowExportModal(false)
          }}
          onClose={() => {
            setShowExportModal(false)
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={`Confirm delete Employee?`}
          content={() => (
            <>
              <CAlert color='danger' className='w-100'>
                Are you sure you want to delete "{selectedEmployee.name}"? All
                employee data will be deleted.
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          confirmText='Delete'
          onConfirm={() => {
            deleteEmployee()
          }}
        />
      )}

      {showAccountConfirmModal && (
        <ConfirmationModal
          show={showAccountConfirmModal}
          title={`Add employee Account?`}
          content={() => (
            <>
              <CAlert color='info' className='w-100'>
                Would you like to add "{addEmployeeAccount.name}'s" Account?
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowAccountConfirmModal(false)
          }}
          confirmText='Add Account'
          buttonType={'success'}
          onConfirm={() => {
            setShowAccountConfirmModal(false)
            setShowAccountModal(true)
          }}
        />
      )}
    </>
  )
}

export default withRouter(Employees)
