import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Notify {

  static success(message = "Success") {
    toast.dark(message, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
    });
  }

  static error(message = "An unexpected error occured, please try again") {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
    });
  }
}
