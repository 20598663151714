import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CPagination,
  CRow,
  CSelect,
  CTooltip,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { accountTypes } from '../constants/Options'
import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import General from '../utils/General'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import BulkLoanModal from './modals/BulkLoanModal'
import BulkLoanPaymentModal from './modals/BulkLoanPaymentModal'
import ConfirmationModal from './modals/ConfirmationModal'
import LoanApproveModal from './modals/LoanApproveModal'
import LoanPaymentModal from './modals/LoanPaymentModal'
import LoanPreClosureModal from './modals/LoanPreClosureModal'

function Loans(props) {
  const nameRef = useRef(null)
  const branchRef = useRef(null)
  const typeRef = useRef(null)
  const [loans, setLoans] = useState([])
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedLoan, setSelectedLoan] = useState()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showLoanModal, setShowLoanModal] = useState(false)
  const [showLoanPaymentModal, setShowLoanPaymentModal] = useState(false)
  const [showLoanPreClosureModal, setShowLoanPreClosureModal] = useState(false)
  const [showBulkLoanPaymentModal, setShowBulkLoanPaymentModal] = useState(false)
  const [showLoanApproveModal, setShowLoanApproveModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [exporting, setExporting] = useState(false)
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    content: '',
    confirmText: 'Delete',
    onConfirm: () => {},
  })

  const filterLoans = async () => {
    await getLoans()
  }

  const deleteLoan = async () => {
    if (selectedLoan == null) return

    try {
      await Backend.deleteLoan(selectedLoan.id)
      const updatedLoans = Loans.filter((item) => {
        if (item.id != selectedLoan.id) {
          return item
        }
      })
      setLoans([...updatedLoans])
      setShowConfirmModal(false)
      setSelectedLoan(null)
      Notify.success('Loan deleted successfully.')
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const getLoans = async () => {
    setLoading(true)

    try {
      let params = `?per_page=15&page=${currentPage}`
      if (nameRef.current.value) {
        params += `&name=${nameRef.current.value}`
      }
      if (branchRef.current.value) {
        params += `&branch=${branchRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&account_type=${typeRef.current.value}`
      }

      const response = await Backend.getLoans(params)
      if (response.data) {
        setLastPage(response.data.last_page)
        setLoans([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  const initBranches = async () => {
    try {
      const response = await Backend.getBranches()
      if (response.data) {
        setBranches([...response.data])
      }
    } catch (error) {
      console.log(error)
      //Notify.error(error.message)
    }
  }

  const exportLoans = async () => {
    setExporting(true)

    try {
      let data = {}
      let params = ''
      let url = `${window.Api.Base}/loans/export`
      data['headers'] = AuthManager.getHeaders()

      if (nameRef.current.value) {
        params += `&name=${nameRef.current.value}`
      }
      if (branchRef.current.value) {
        params += `&branch=${branchRef.current.value}`
      }
      if (typeRef.current.value) {
        params += `&account_type=${typeRef.current.value}`
      }

      if (params) {
        url += `/${params}`
      }

      const response = await fetch(url, data)
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    } catch (error) {
      Notify.error(error.message)
    }
    setExporting(false)
  }

  useEffect(() => {
    initBranches()
  }, [])

  useEffect(() => {
    getLoans()
  }, [currentPage])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Loans</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowLoanModal(!showLoanModal)}
          >
            <CIcon name='cil-plus' /> Add Loan
          </CButton>
          <CButton
            color='dark'
            className='btn-sm ml-2'
            onClick={() => setShowBulkLoanPaymentModal(!showBulkLoanPaymentModal)}
          >
            <CIcon name='cil-plus' /> Add Loan Payments
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Loans</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol className={'col-3'}>
                  <CInput
                    innerRef={nameRef}
                    name='name'
                    placeholder={'Search by Name...'}
                    onKeyUp={(evt) => {
                      if (evt.key == 'Enter' || !evt.target.value) {
                        filterLoans()
                      }
                    }}
                  />
                </CCol>
                <CCol
                  className={'col-2'}
                  style={{
                    display:
                      AuthManager.getCurrentUser().role == 'admin'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <CSelect innerRef={typeRef} onChange={() => filterLoans()}>
                    <option value=''>--Select Type--</option>
                    {accountTypes.map((accountType) => {
                      return (
                        <option value={accountType.value}>
                          {accountType.label}
                        </option>
                      )
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-3'}>
                  <CSelect
                    innerRef={branchRef}
                    name='branch'
                    onChange={(evt) => {
                      filterLoans()
                    }}
                  >
                    <option value=''>Select Department</option>
                    {branches.map((branch) => {
                      return <option value={branch.id}>{branch.name}</option>
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-sm-4'}>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    onClick={() => filterLoans()}
                  >
                    <CIcon name='cil-filter' /> Filter
                  </CButton>
                  <CButton
                    disabled={exporting}
                    className={'ml-2 btn-sm'}
                    color={'dark'}
                    onClick={() => exportLoans()}
                  >
                    <CIcon name='cil-share' />
                    {exporting ? 'Exporting Loans...' : 'Export Loans'}
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <CDataTable
                        items={loans}
                        itemsPerPage={100}
                        pagination
                        outlined={true}
                        fields={[
                          {
                            key: 'employee',
                            label: 'Employee',
                          },
                          {
                            key: 'code',
                            label: 'Account Code',
                          },
                          {
                            key: 'type',
                            label: 'Account Type',
                          },
                          {
                            key: 'amount',
                            label: 'Amount',
                          },
                          //  {
                          //   key: 'total_interest',
                          //   label: 'Interest'
                          // },
                          {
                            key: 'total',
                            label: 'Total Amount',
                          },
                          {
                            key: 'balance',
                            label: 'Balance',
                          },
                          {
                            key: 'no_of_emi',
                            label: 'No. of EMIs',
                          },
                          // {
                          //   key: 'interest_rate',
                          //   label: 'Interest Rate'
                          // },
                          {
                            key: 'disbursed_at',
                            label: 'Disburse Date',
                          },
                          {
                            key: 'approved',
                            label: 'Approved',
                          },
                          {
                            key: 'actions',
                            label: 'Actions',
                          },
                        ]}
                        scopedSlots={{
                          employee: (item) => {
                            return <td>{item.name.toUpperCase()}</td>
                          },
                          code: (item) => {
                            return <td>{item.code.toUpperCase()}</td>
                          },
                          type: (item) => {
                            return <td>{item.type.toUpperCase()}</td>
                          },
                          amount: (item) => {
                            return <td>{General.formatRupees(item.amount)}</td>
                          },
                          total_interest: (item) => {
                            return (
                              <td>
                                {General.formatRupees(item.total_interest)}
                              </td>
                            )
                          },
                          total: (item) => {
                            return <td>{General.formatRupees(item.total)}</td>
                          },
                          balance: (item) => {
                            return <td>{General.formatRupees(item.balance)}</td>
                          },
                          disbursed_at: (item) => {
                            return (
                              <td>
                                {moment(item.disbursed_at).format('DD/MM/YYYY')}
                              </td>
                            )
                          },
                          approved: (item) => {
                            return <td>{item.approved ? 'Yes' : 'Pending'}</td>
                          },
                          actions: (item) => {
                            return (
                              <td>
                                <CButtonGroup className={'btn-sm'}>
                                  {item.status === 'active' &&
                                    item.approved === 1 && (
                                      <>
                                        <CTooltip
                                          content='Add Payment'
                                          placement='bottom'
                                        >
                                          <CButton
                                            color='success'
                                            variant='outline'
                                            className={'btn-sm'}
                                            onClick={() => {
                                              setSelectedLoan(item)
                                              setShowLoanPaymentModal(true)
                                            }}
                                          >
                                            <CIcon name='cil-plus'></CIcon>
                                          </CButton>
                                        </CTooltip>
                                        <CTooltip
                                          content='Close Loan'
                                          placement='bottom'
                                        >
                                          <CButton
                                            color='danger'
                                            variant='outline'
                                            className={'btn-sm'}
                                            onClick={() => {
                                              setSelectedLoan(item)
                                              setShowLoanPreClosureModal(true)
                                            }}
                                          >
                                            <CIcon name='cil-lock-locked'></CIcon>
                                          </CButton>
                                        </CTooltip>
                                      </>
                                    )}{' '}
                                  {item.status === 'active' &&
                                    item.approved === 0 &&
                                    AuthManager.getCurrentUser().role ===
                                      'admin' && (
                                      <>
                                        <CTooltip
                                          content='Approve Loan'
                                          placement='bottom'
                                        >
                                          <CButton
                                            color='success'
                                            variant='outline'
                                            className={'btn-sm'}
                                            onClick={() => {
                                              setSelectedLoan(item)
                                              setShowLoanApproveModal(true)
                                            }}
                                          >
                                            <CIcon name='cil-check'></CIcon>
                                          </CButton>
                                        </CTooltip>
                                      </>
                                    )}
                                  {item.status === 'closed' && (
                                    <>
                                      <CTooltip
                                        content='View Details'
                                        placement='bottom'
                                      >
                                        <CButton
                                          color='success'
                                          variant='outline'
                                          className={'btn-sm'}
                                          onClick={() => {
                                            setSelectedLoan(item)
                                            setShowLoanPaymentModal(true)
                                          }}
                                        >
                                          <CIcon name='cil-spreadsheet'></CIcon>
                                        </CButton>
                                      </CTooltip>
                                    </>
                                  )}
                                </CButtonGroup>
                              </td>
                            )
                          },
                        }}
                      />
                      <CPagination
                        className={'mt-2'}
                        activePage={currentPage}
                        pages={lastPage}
                        onActivePageChange={(i) => setCurrentPage(i)}
                      ></CPagination>
                    </>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showLoanModal && (
        <BulkLoanModal
          show={showLoanModal}
          onAdded={() => {
            setShowLoanModal(false)
            getLoans()
          }}
          onClose={() => {
            setShowLoanModal(false)
          }}
        />
      )}
      {showLoanPaymentModal && (
        <LoanPaymentModal
          show={showLoanPaymentModal}
          loan={selectedLoan}
          onClose={() => {
            getLoans()
            setShowLoanPaymentModal(false)
          }}
        />
      )}

      {showLoanPreClosureModal && (
        <LoanPreClosureModal
          show={showLoanPreClosureModal}
          loan={selectedLoan}
          onClose={() => {
            getLoans()
            setShowLoanPreClosureModal(false)
          }}
        />
      )}

      {showLoanApproveModal && (
        <LoanApproveModal
          show={showLoanApproveModal}
          loan={selectedLoan}
          onClose={() => {
            getLoans()
            setShowLoanApproveModal(false)
          }}
        />
      )}

      {showBulkLoanPaymentModal && (
        <BulkLoanPaymentModal
          show={showBulkLoanPaymentModal}
          onAdded={() => {
            setShowBulkLoanPaymentModal(false)
            getLoans()
          }}
          onClose={() => {
            getLoans()
            setShowBulkLoanPaymentModal(false)
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmModal.title}
          content={() => (
            <>
              <CAlert color='danger' className='w-100'>
                {confirmModal.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          confirmText={confirmModal.confirmText}
          onConfirm={confirmModal.onConfirm}
        />
      )}
    </>
  )
}

export default withRouter(Loans)
