import {
  CAlert, CButton,
  CCard, CCardBody, CCol,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSpinner
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

export default function Settings() {
  const settingsModel = {
    epf_interest_rate: 0,
    epf_contribution_percentage: 0,
    ess_interest_rate: 0,
    ess_contribution_percentage: 0,    
    nps_interest_rate: 0,
    nps_contribution_percentage: 0,
    loan_interest_rate: 0,
  };

  const [error, setError] = useState("");
  const [settings, setSettings] = useState(settingsModel);
  const [loading, setLoading] = useState(false);

  const initSettings = async () => {
    const response = await Backend.getSettings();

    if (response.data && response.data.length) {
      response.data.forEach((item) => {
        settingsModel[item.name] = item.value;
      });
      setSettings({ ...settingsModel });
    }
  };

  const handleFormChange = (evt) => {
    setError("");
    let name = evt.target.name;
    let value = evt.target.value;

    // if (name == 'epf_interest_rate' || name == 'loan_interest_rate') {
    value = parseFloat(parseFloat(value).toFixed(2));
    // }
    setSettings({ ...settings, [name]: value });
  };

  const validateData = () => {
    if (isNaN(settings.epf_interest_rate)) {
      setError("Enter valid value of EPF interest rate");
      return false;
    } else if (isNaN(settings.epf_contribution_percentage)) {
      setError("Enter valid value of EPF Contribution Percentage");
      return false;
    } else if (isNaN(settings.ess_interest_rate)) {
      setError("Enter valid value of ESS interest rate");
      return false;
    } else if (isNaN(settings.ess_contribution_percentage)) {
      setError("Enter valid value of ESS contribution percentage");
      return false;
    } else if (isNaN(settings.nps_interest_rate)) {
      setError("Enter valid value of NPS interest rate");
      return false;
    } else if (isNaN(settings.nps_contribution_percentage)) {
      setError("Enter valid value of NPS contribution percentage");
      return false;
    } else if (isNaN(settings.loan_interest_rate)) {
      setError("Enter valid value of Loan interest rate");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      Backend.updateSettings(settings);
      Notify.success("Settings updated successfully.");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    initSettings();
  }, []);

  return (
    <>
      <CRow>
        <CCol xs={"12"}>
          <h4>Settings</h4>
        </CCol>
      </CRow>

      <CRow className={"mt-3"}>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardBody>
              {error && (
                <CAlert color="danger" className="w-100">
                  {error}
                </CAlert>
              )}

              <CForm
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel>EPF Interest Rate (per annum)</CLabel>
                      <CInput
                        value={settings.epf_interest_rate}
                        type="number"
                        name="epf_interest_rate"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </CFormGroup>
                    <CFormGroup>
                      <CLabel>EPF Contribution Percentage (Basic Pay)</CLabel>
                      <CInput
                        value={settings.epf_contribution_percentage}
                        type="number"
                        name="epf_contribution_percentage"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </CFormGroup>
                  </CCol>

                  <CCol>
                    <CFormGroup>
                      <CLabel>ESS Interest Rate (per annum)</CLabel>
                      <CInput
                        value={settings.ess_interest_rate}
                        type="number"
                        name="ess_interest_rate"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </CFormGroup>
                    <CFormGroup>
                      <CLabel>ESS Contribution Percentage (Basic Pay)</CLabel>
                      <CInput
                        value={settings.ess_contribution_percentage}
                        type="number"
                        name="ess_contribution_percentage"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel>NPS Interest Rate (per annum)</CLabel>
                      <CInput
                        value={settings.nps_interest_rate}
                        type="number"
                        name="nps_interest_rate"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </CFormGroup>
                    <CFormGroup>
                      <CLabel>NPS Contribution Percentage (Basic Pay)</CLabel>
                      <CInput
                        value={settings.nps_contribution_percentage}
                        type="number"
                        name="nps_contribution_percentage"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>

                <CFormGroup>
                  <CLabel>Loan Interest Rate (per annum)</CLabel>
                  <CInput
                    value={settings.loan_interest_rate}
                    type="number"
                    name="loan_interest_rate"
                    onChange={(e) => handleFormChange(e)}
                  />
                </CFormGroup>
                <CFormGroup className={"text-right mt-2"}>
                  <CButton
                    color="dark"
                    className="btn-sm"
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {loading ? (
                      <>
                        <CSpinner
                          component="span"
                          size="sm"
                          aria-hidden="true"
                        />{" "}
                        Saving...
                      </>
                    ) : (
                      "Update Settings"
                    )}
                  </CButton>
                </CFormGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
}
