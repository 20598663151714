
import { CIcon } from '@coreui/icons-react'
import {
  CAlert, CButton, CButtonGroup, CCard, CCardBody, CCardHeader, CCol,
  CDataTable, CPagination, CRow, CTooltip
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import AccountHeadModal from './modals/AccountHeadModal'
import ConfirmationModal from './modals/ConfirmationModal'

function AccountHeads(props) {
  const [accountHeads, setAccountHeads] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedAccountHead, setSelectedAccountHead] = useState()
  const [showAccountHeadModal, setShowAccountHeadModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const perPage = 10

  const deleteAccountHead = async () => {
    if (selectedAccountHead == null) return

    try {
      await Backend.deleteAccountHead(selectedAccountHead.id)
      const updatedAccountHeads = accountHeads.filter(item => {
        if (item.id != selectedAccountHead.id) {
          return item
        }
      })
      setAccountHeads([...updatedAccountHeads])
      setShowConfirmModal(false)
      setSelectedAccountHead(null)
      Notify.success('Account Head deleted successfully.')
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const getAccountHeads = async () => {
    setLoading(true)

    try {
      let params = `?per_page=${perPage}&page=${currentPage}`
      const response = await Backend.getAccountHeads(params);
      if (response.data) {
        setLastPage(response.data.last_page)
        setAccountHeads([...response.data.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAccountHeads()
  }, [currentPage])

  return (
    <>
      <CRow>
        <CCol>
          <h4>AccountHeads</h4>
        </CCol>
        <CCol className="text-right">
          <CButton color="dark" className="btn-sm"
            onClick={() => setShowAccountHeadModal(!showAccountHeadModal)} ><CIcon name="cil-plus" /> Add Account Head</CButton>
        </CCol>
      </CRow>


      <CRow className={'mt-3'}>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Account Heads</h6>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {
                loading ?
                  <Loading />
                  :
                  <>
                    <CDataTable
                      items={accountHeads}
                      striped
                      outlined={true}
                      fields={[
                        {
                          key: 'slNo',
                          label: '#'
                        }, {
                          key: 'name',
                          label: 'Name'
                        }, {
                          key: 'type',
                          label: 'Type'
                        }, {
                          key: 'actions',
                          label: 'Actions',
                        }
                      ]}
                      scopedSlots={{
                        'slNo': (item, index) => {
                          return (
                            <td>{(currentPage - 1) * perPage + (++index)}</td>
                          )
                        },
                        'actions': (item) => {
                          return (
                            <td>
                              <CButtonGroup className={'btn-sm'}>
                                <CTooltip content="Edit Account Head" placement="bottom">
                                  <CButton color="dark" variant="outline" className={'btn-sm'}
                                    onClick={() => {
                                      setSelectedAccountHead(item)
                                      setShowAccountHeadModal(true)
                                    }}>
                                    <CIcon name="cil-pen-alt"></CIcon>
                                  </CButton>
                                </CTooltip>
                                <CTooltip content="Delete Account Head" placement="bottom">
                                  <CButton color="danger" variant="outline" className={'btn-sm'}
                                    onClick={() => {
                                      setSelectedAccountHead(item)
                                      setShowConfirmModal(true)
                                    }}>
                                    <CIcon name="cil-trash"></CIcon>
                                  </CButton>
                                </CTooltip>

                              </CButtonGroup>
                            </td>
                          )
                        },

                      }}
                    />
                    <CPagination
                      className={'mt-2'}
                      activePage={currentPage}
                      pages={lastPage}
                      onActivePageChange={(i) => setCurrentPage(i)}
                    ></CPagination>
                  </>
              }
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {
        showAccountHeadModal &&
        <AccountHeadModal
          show={showAccountHeadModal}
          accountHead={selectedAccountHead}
          onAdded={(accountHead) => {
            accountHeads.unshift(accountHead)
            setAccountHeads([...accountHeads])
          }}
          onUpdated={(accountHead) => {
            const updatedAccountHeads = accountHeads.map(item => {
              if (item.id == accountHead.id) {
                return accountHead
              } else {
                return item
              }
            })
            setAccountHeads([...updatedAccountHeads])
          }}
          onClose={() => {
            setSelectedAccountHead(null)
            setShowAccountHeadModal(false)
          }
          }
        />
      }

      {
        showConfirmModal &&
        (
          <ConfirmationModal
            show={showConfirmModal}
            title={`Confirm delete Account Head?`}
            content={() =>
            (
              <>
                <CAlert color="danger" className="w-100">
                  Are you sure you want to delete "{selectedAccountHead.name}"?
                </CAlert>
              </>
            )
            }
            onClose={() => {
              setShowConfirmModal(false)
            }}
            confirmText='Delete'
            onConfirm={() => {
              deleteAccountHead()
            }}
          />
        )
      }
    </>
  )
}

export default withRouter(AccountHeads)
