import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import navigations from '../layouts/_nav'

export default function AuthenticatedRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  var user = currentUser

  // if (currentUser && currentUser.hasOwnProperty('user')) {
  //   user = currentUser.user
  // }
  // // Check Permission
  // if (user) {
  //   const { path } = { ...rest }
  //   // console.log(user)

  //   var hasPermission = navigations.find((nav) => {
  //     if (
  //       (nav.to === path || nav.route === path) &&
  //       nav.roles.includes(user.role)
  //     ) {
  //       return true
  //     }

  //     if (nav.hasOwnProperty('_children')) {
  //       return nav._children.find((childNav) => {
  //         if (childNav.to === path && childNav.roles.includes(user.role)) {
  //           return true
  //         }

  //         return false
  //       })
  //     }

  //     return false
  //   })

  //   // console.log(hasPermission)

  //   if (hasPermission === undefined) {
  //     return <Redirect to='/dashboard/403' />
  //   }
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? <Component {...props} /> : <Redirect to='/login' />
      }}
    ></Route>
  )
}
