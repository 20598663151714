import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup,
  CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSpinner, CSwitch
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';

export default function AccountHeadModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [accountHead, setAccountHead] = useState({
    id: '',
    name: '',
    type: 'Receipt',
  })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;

    setAccountHead({ ...accountHead, [name]: value })
  }

  function validateData() {
    if (!accountHead.name) {
      setError('Enter Account head name')
      return false;
    } else if (!accountHead.type) {
      setError('Select type')
      return false;
    }
    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (!props.accountHead) {
        const response = await Backend.addAccountHead(accountHead)
        props.onAdded(response.data)
      } else {
        const response = await Backend.updateAccountHead(accountHead.id, accountHead)
        props.onUpdated(response.data)
      }
      props.onClose()
      Notify.success('Account Head added/updated successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.accountHead) {
      setAccountHead(props.accountHead)
    }
  }, [])


  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Account Head</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {
                error && <CAlert color="danger" className="w-100">{error}</CAlert>
              }
              <CCol sm="12">
                <CForm onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                  <CFormGroup>
                    <CLabel>Name</CLabel>
                    <CInput
                      value={accountHead.name}
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Type</CLabel><br />
                    <CSwitch
                      className="text-right"
                      checked={accountHead.type == 'Receipt'}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setAccountHead({ ...accountHead, type: 'Receipt' })
                      }
                      }
                    />
                    <CLabel className={'pl-2'}>Receipt</CLabel><br />
                    <CSwitch
                      className="text-right mt-2"
                      checked={accountHead.type == 'Payment'}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setAccountHead({ ...accountHead, type: 'Payment' })
                      }
                      }
                    />
                    <CLabel className={'pl-2'}>Payment</CLabel>
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}>Cancel</CButton>
          <CButton color="dark" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit()
            }}>
            {
              loading ?
                (
                  <>
                    <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                  </>
                ) : 'Add/Update Account Head'
            }
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
