
const _nav = [
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-applications-settings',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Departments',
    to: '/dashboard/departments',
    icon: 'cil-lan',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Employees',
    to: '/dashboard/employees',
    icon: 'cil-user',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Fiscal Accounts',
    to: '/dashboard/fiscal-accounts',
    icon: 'cil-grid',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Loans',
    to: '/dashboard/loans',
    icon: 'cil-grid',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Contributions',
    to: '/dashboard/contributions',
    icon: 'cil-grid',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Financial Years',
    to: '/dashboard/financial-years',
    icon: 'cil-list',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Account Heads',
    to: '/dashboard/account-heads',
    icon: 'cil-notes',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Receipts',
    to: '/dashboard/receipts',
    icon: 'cil-list',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Payments',
    to: '/dashboard/payments',
    icon: 'cil-list',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavDropdown',
    name: 'Reports',
    route: '/dashboard/reports',
    icon: 'cil-graph',
    _children: [
      {
        roles: ['admin', 'epf', 'ess', 'npf'],
        _tag: 'CSidebarNavItem',
        name: 'Receipt & Payments',
        to: '/dashboard/reports/receipts-payments',
      },
      {
        roles: ['admin', 'epf'],
        _tag: 'CSidebarNavItem',
        name: 'EPF Accounts',
        to: '/dashboard/reports/epf-accounts',
      },
      {
        roles: ['admin', 'ess'],
        _tag: 'CSidebarNavItem',
        name: 'ESS Accounts',
        to: '/dashboard/reports/ess-accounts',
      },
      {
        roles: ['admin', 'npf'],
        _tag: 'CSidebarNavItem',
        name: 'NPS Accounts',
        to: '/dashboard/reports/nps-accounts',
      },
    ],
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Authorized Signatories',
    to: '/dashboard/signatories',
    icon: 'cil-user',
  },
  {
    roles: ['admin', 'epf', 'ess', 'npf'],
    _tag: 'CSidebarNavItem',
    name: 'Users',
    to: '/dashboard/users',
    icon: 'cil-user',
  },
  {
    roles: ['admin'],
    _tag: 'CSidebarNavItem',
    name: 'Settings',
    to: '/dashboard/settings',
    icon: 'cil-settings',
  },
  {
    roles: ['employee'],
    _tag: 'CSidebarNavItem',
    name: 'Applications',
    to: '/dashboard/applications',
    icon: 'cil-list',
  }, 
  {
    roles: ['employee'],
    _tag: 'CSidebarNavItem',
    name: 'Dependents',
    to: '/dashboard/dependents',
    icon: 'cil-user',
  },
]

export default _nav
