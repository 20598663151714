
import { CIcon } from '@coreui/icons-react'
import {
  CAlert,
  CButton, CButtonGroup, CCard, CCardBody, CCardHeader, CCol,
  CDataTable, CRow, CTooltip
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import ConfirmationModal from './modals/ConfirmationModal'
import SignatoryModal from './modals/SignatoryModal'

function Signatories(props) {
  const [signatories, setSignatories] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedSignatory, setSelectedSignatory] = useState()
  const [showSignatoryModal, setShowSignatoryModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const deleteSignatory = async () => {
    if (selectedSignatory == null) return

    try {
      await Backend.deleteSignatory(selectedSignatory.id)
      const updatedSignatories = signatories.filter(item => {
        if (item.id != selectedSignatory.id) {
          return item
        }
      })
      setSignatories([...updatedSignatories])
      setShowConfirmModal(false)
      setSelectedSignatory(null)
      Notify.success('Signatory deleted successfully.')
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
    }
  }

  const getSignatories = async () => {
    setLoading(true)

    try {
      const response = await Backend.getSignatories();
      if (response.data) {
        setSignatories([...response.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSignatories()
  }, [])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Authorized Signatories</h4>
        </CCol>
        <CCol className="text-right">
          <CButton color="dark" className="btn-sm"
            onClick={() => setShowSignatoryModal(!showSignatoryModal)} ><CIcon name="cil-plus" /> Add Signatory</CButton>
        </CCol>
      </CRow>


      <CRow className={'mt-3'}>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Authorized Signatories</h6>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {
                loading ?
                  <Loading />
                  :
                  <>
                    <CDataTable
                      items={signatories}
                      striped
                      outlined={true}
                      fields={[
                        {
                          key: 'name',
                          label: 'Name'
                        },
                        {
                          key: 'designation',
                          label: 'Designation'
                        }, {
                          key: 'path',
                          label: 'Signature'
                        }, {
                          key: 'created_at',
                          label: 'Created At'
                        }, {
                          key: 'actions',
                          label: 'Actions',
                        }
                      ]}
                      scopedSlots={{
                        'path': (item) => {
                          return (
                            <td><img src={`data:image/png;base64,${item.base64}`} style={{maxWidth: '100%', height: '50px'}} /></td>
                          )
                        },                        'created_at': (item) => {
                          return (
                            <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                          )
                        },
                        'actions': (item) => {
                          return (
                            <td>
                              <CButtonGroup className={'btn-sm'}>
                                <CTooltip content="Edit Signatory" placement="bottom">
                                  <CButton color="dark" variant="outline" className={'btn-sm'}
                                    onClick={() => {
                                      setSelectedSignatory(item)
                                      setShowSignatoryModal(true)
                                    }}>
                                    <CIcon name="cil-pen-alt"></CIcon>
                                  </CButton>
                                </CTooltip>
                                <CTooltip content="Delete Signatory" placement="bottom">
                                  <CButton color="danger" variant="outline" className={'btn-sm'}
                                    onClick={() => {
                                      setSelectedSignatory(item)
                                      setShowConfirmModal(true)
                                    }}>
                                    <CIcon name="cil-trash"></CIcon>
                                  </CButton>
                                </CTooltip>
                              </CButtonGroup>
                            </td>
                          )
                        },
                      }}
                    />
                  </>
              }
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {
        showSignatoryModal &&
        <SignatoryModal
          show={showSignatoryModal}
          signatory={selectedSignatory}
          onAdded={(signatory) => {
            signatories.unshift(signatory)
            setSignatories([...signatories])
          }}
          onUpdated={(signatory) => {
            const updatedSignatories = signatories.map(item => {
              if (item.id == signatory.id) {
                return signatory
              } else {
                return item
              }
            })
            setSignatories([...updatedSignatories])
          }}
          onClose={() => {
            setSelectedSignatory(null)
            setShowSignatoryModal(false)
          }
          }
        />
      }

      {
        showConfirmModal &&
        (
          <ConfirmationModal
            show={showConfirmModal}
            title={`Confirm delete Signatory?`}
            content={() =>
            (
              <>
                <CAlert color="danger" className="w-100">
                  Are you sure you want to delete "{selectedSignatory.name}"?
                </CAlert>
              </>
            )
            }
            onClose={() => {
              setShowConfirmModal(false)
            }}
            confirmText='Delete'
            onConfirm={() => {
              deleteSignatory()
            }}
          />
        )
      }
    </>
  )
}

export default withRouter(Signatories)
