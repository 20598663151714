import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup,
  CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CSpinner
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { roles } from '../../constants/Options';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import UploadFile from '../components/UploadFile';

export default function SignatoryModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [signatory, setSignatory] = useState(props.signatory ? props.signatory :
    {
      name: '',
      designation: '',
      path: '',
    })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;

    setSignatory({ ...signatory, [name]: value })
  }

  function validateData() {
    if (!signatory.name) {
      setError('Enter signatory name')
      return false;
    } else if (!signatory.designation) {
      setError('Enter valid designation')
      return false;
    } else if (!props.signatory && !signatory.path) {
      setError('Select signature File')
      return false;
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    try {
      if (!props.signatory) {
        const response = await Backend.addSignatory(signatory)
        props.onAdded(response.data)
      } else {
        const response = await Backend.updateSignatory(signatory.id, signatory)
        props.onUpdated(response.data)
      }
      props.onClose()
      Notify.success('Signatory added/updated successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.signatory) {
      setSignatory(props.signatory)
    }
  }, [])


  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Signatory</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {
                error && <CAlert color="danger" className="w-100">{error}</CAlert>
              }
              <CCol sm="12">
                <CForm onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                  <CFormGroup>
                    <CLabel>Name</CLabel>
                    <CInput
                      value={signatory.name}
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Designation</CLabel>
                    <CInput
                      value={signatory.designation}
                      type="designation"
                      name="designation"
                      placeholder="Enter designation"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <UploadFile
                    label={'Select Signature image'}
                    buttonLabel={'Upload Signature'}
                    folder={'signatures'}
                    accepts={'.png, .jpeg, .jpg'}
                    onUploaded={(fileUrl) => {
                      setSignatory({ ...signatory, path: fileUrl })
                    }}
                  />
                  <CFormGroup>
                    <CLabel style={{wordBreak:'break-all'}}>Signature File: {signatory.path}</CLabel>
                  </CFormGroup>
                  {/* {
                    props.signatory &&
                    (
                      <CFormGroup>
                        <CAlert color={'info'}>Leave this field blank if you dont want to change Signature</CAlert>
                      </CFormGroup>
                    )
                  } */}
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton variant="outline" color="danger" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}>Cancel</CButton>
          <CButton color="dark" className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit()
            }}>
            {
              loading ?
                (
                  <>
                    <CSpinner component="span" size="sm" aria-hidden="true" />  Saving...
                  </>
                ) : 'Add/Update Signatory'
            }
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
