import { CIcon } from '@coreui/icons-react'
import { CAlert, CButton, CButtonGroup, CCard, CCardBody, CCardHeader, CCol, CDataTable, CRow, CTooltip } from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Loading from './components/Loading'
import BranchModal from './modals/BranchModal'
import ConfirmationModal from './modals/ConfirmationModal'

export default function Departments() {
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedBranch, setSelectedBranch] = useState()
  const [showBranchModal, setShowBranchModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const deleteBranch = async () => {
    if (selectedBranch == null) return

    try {
      await Backend.deleteBranch(selectedBranch.id)
      const updatedBranches = branches.filter(item => {
        if (item.id != selectedBranch.id) {
          return item
        }
      })
      setBranches([...updatedBranches])
      setShowConfirmModal(false)
      setSelectedBranch(null)
      Notify.success('Department deleted successfully.')
    } catch (error) {
      setShowConfirmModal(false)
      Notify.error(error.message)
      Notify.error('Something went wrong, Please try again later.')
    }
  }

  const initBranches = async () => {
    try {
      const response = await Backend.getBranches();
      if (response.data) {
        setBranches([...response.data])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      Notify.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    initBranches()
  }, [])

  return (
    <>
      <CRow>
        <CCol>
          <h4>Departments</h4>
        </CCol>
        <CCol className="text-right">
          <CButton color="dark" className="btn-sm"
            onClick={() => setShowBranchModal(!showBranchModal)} ><CIcon name="cil-plus" /> Add Department</CButton>
        </CCol>
      </CRow>

      {
        loading ?
          <Loading />
          :
          <CRow className={'mt-3'}>
            <CCol xs="12" lg="12">
              <CCard>
                <CCardHeader>
                  Departments
                </CCardHeader>
                <CCardBody>
                  <CDataTable
                    items={branches}
                    striped
                    itemsPerPage={100}
                    pagination
                    outlined={true}
                    fields={[
                      {
                        key: 'name',
                        label: 'Name'
                      }, {
                        key: 'created_at',
                        label: 'Created At'
                      }, {
                        key: 'updated_at',
                        label: 'Updated At'
                      }, {
                        key: 'actions',
                        label: 'Actions',
                      }
                    ]}
                    scopedSlots={{
                      'created_at': (item) => {
                        return (
                          <td>{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</td>
                        )
                      },
                      'updated_at': (item) => {
                        return (
                          <td>{moment(item.updated_at).format('DD/MM/YYYY hh:mm A')}</td>
                        )
                      },
                      'actions': (item) => {
                        return (
                          <td>
                            <CButtonGroup className={'btn-sm'}>
                              <CTooltip content="Edit Department" placement="bottom">
                                <CButton color="dark" variant="outline" className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedBranch(item)
                                    setShowBranchModal(true)
                                  }}>
                                  <CIcon name="cil-pen-alt"></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip content="Delete Department" placement="bottom">
                                <CButton color="danger" variant="outline" className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedBranch(item)
                                    setShowConfirmModal(true)
                                  }}>
                                  <CIcon name="cil-trash"></CIcon>
                                </CButton>
                              </CTooltip>

                            </CButtonGroup>
                          </td>
                        )
                      },

                    }}

                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
      }

      {
        showBranchModal &&
        <BranchModal
          show={showBranchModal}
          branch={selectedBranch}
          onAdded={(branch) => {
            branches.unshift(branch)
            setBranches([...branches])
          }}
          onUpdated={(branch) => {
            const updatedBranches = branches.map(item => {
              if (item.id == branch.id) {
                return branch
              } else {
                return item
              }
            })
            setBranches([...updatedBranches])
          }}
          onClose={() => {
            setSelectedBranch(null)
            setShowBranchModal(false)
          }
          }
        />
      }

      {
        showConfirmModal &&
        (
          <ConfirmationModal
            show={showConfirmModal}
            title={`Confirm delete "${selectedBranch.name}"?`}
            content={() =>
            (
              <>
                <CAlert color="warning" className="w-100">
                  Are you sure you want to delete "{selectedBranch.name}"? Deleting this will affects employees with this Department.
                </CAlert>
              </>
            )
            }
            onClose={() => {
              setShowConfirmModal(false)
            }}
            confirmText='Delete'
            onConfirm={() => {
              deleteBranch()
            }}
          />
        )
      }
    </>
  )
}
