import {
  CAlert, CButton, CCol, CContainer, CForm, CFormGroup, CLabel, CModal, CModalBody, CModalFooter, CModalHeader,
  CModalTitle, CRow, CSelect, CSpinner
} from '@coreui/react';
import React, { useState } from 'react';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import '../../constants/Api'
import {accountTypes} from '../../constants/Options'


export default function ExportEmployeeModal(props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [exportData, setExportData] = useState({
    type: '',
  })

  function handleFormChange(evt) {
    setError('')
    let name = evt.target.name;
    let value = evt.target.value;

    setExportData({ ...exportData, [name]: value })
  }

  function validateData() {
    if (!exportData.type) {
      setError('Select Employee Type')
      return false;
    }

    return true
  }

  async function handleSubmit() {
    setLoading(true)

    if (!validateData()) {
      setLoading(false)
      return
    }

    // console.log(JSON.stringify(exportData))
    // return

    try {
      let data = {}
      data['headers'] = AuthManager.getHeaders()
      const response = await fetch(`${window.Api.Employees}/export?type=${exportData.type}`, data)
      response.blob().then(blob => showInOtherTab(blob))
      props.onClose()
      Notify.success('Employees exported successfully.')
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  function showInOtherTab(blob) {
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }


  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>Export Employees</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color="danger" className="w-100">
                  {error}
                </CAlert>
              )}
              <CCol sm="12">
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>Employee Type:</CLabel>
                    <CSelect
                      value={exportData.type}
                      name={"type"}
                      onChange={(e) => handleFormChange(e)}
                    >
                      <option value="">--Select Employee Type--</option>
                      {accountTypes.map((accountType) => {
                        return (
                          <option value={accountType.value}>
                            {accountType.label}
                          </option>
                        );
                      })}
                    </CSelect>
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color="dark"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component="span" size="sm" aria-hidden="true" />{" "}
                Exporting...
              </>
            ) : (
              "Export Employees"
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
