import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
  CSwitch
} from "@coreui/react";
import React, { useState } from "react";
import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";

export default function AccountModal(props) {
  const providentAccountModel = {
    id: "",
    employee_id: props.employee ? props.employee.id : "",
    code: "",
    type: "epf",
    total_outstanding_loan: 0.0,
    total_outstanding_balance: 0.0,
  };
  const essAccountModel = {
    id: "",
    employee_id: props.employee ? props.employee.id : "",
    code: "",
    type: "ess",
    total_outstanding_loan: 0.0,
    total_outstanding_balance: 0.0,
  };
  const npsAccountModel = {
    id: "",
    employee_id: props.employee ? props.employee.id : "",
    code: "",
    type: "nps",
    total_outstanding_loan: 0.0,
    total_outstanding_balance: 0.0,
  };
  const employees = props.employees ? props.employees : [];
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState(
    props.employee ? props.employee.id : ""
  );
  const [epf, setPension] = useState(props.epf ? true : false);
  const [ess, setEss] = useState(props.ess ? true : false);
  const [nps, setNps] = useState(props.nps ? true : false);
  const [epfAccount, setEpfAccount] = useState(
    props.epf ? props.epf : providentAccountModel
  );
  const [essAccount, setEssAccount] = useState(
    props.ess ? props.ess : essAccountModel
  );
  const [npsAccount, setNpsAccount] = useState(
    props.nps ? props.nps : npsAccountModel
  );

  function handleFormChange(type, evt) {
    setError("");
    let name = evt.target.name;
    let value = evt.target.value;
    if (
      name == "total_outstanding_balance" ||
      name == "total_outstanding_loan"
    ) {
      value = parseFloat(parseFloat(value).toFixed(2));
    }
    if (type == "epf") {
      setEpfAccount({ ...epfAccount, [name]: value });
      console.log(epfAccount);
    } else if (type == "ess") {
      setEssAccount({ ...essAccount, [name]: value });
    } else {
      setNpsAccount({ ...npsAccount, [name]: value });
    }
  }

  function validateData() {
    if (epf && !epfAccount.employee_id) {
      setError("Select employee");
      return false;
    } else if (ess && !essAccount.employee_id) {
      setError("Select employee");
      return false;
    } else if (nps && !npsAccount.employee_id) {
      setError("Select employee");
      return false;
    } else if (!epf && !ess && !nps) {
      setError("Select account type (EPF, ESS or NPS)");
      return false;
    }
    if (epf) {
      // if (!epfAccount.code) {
      //   setError('Enter EPF account code')
      //   return false;
      // } else
      if (isNaN(epfAccount.total_outstanding_loan)) {
        setError("Enter EPF account total outstanding loan value");
        return false;
      } else if (isNaN(epfAccount.total_outstanding_balance)) {
        setError("Enter EPF account total outstanding balance value");
        return false;
      }
    }
    if (ess) {
      // if (!essAccount.code) {
      //   setError('Enter ESS account code')
      //   return false;
      // } else
      if (isNaN(essAccount.total_outstanding_loan)) {
        setError("Enter ESS account total outstanding loan value");
        return false;
      } else if (isNaN(essAccount.total_outstanding_balance)) {
        setError("Enter ESS account total outstanding balance value");
        return false;
      }
    }
    if (nps) {
      // if (!essAccount.code) {
      //   setError('Enter ESS account code')
      //   return false;
      // } else
      if (isNaN(npsAccount.total_outstanding_loan)) {
        setError("Enter NPS account total outstanding loan value");
        return false;
      } else if (isNaN(npsAccount.total_outstanding_balance)) {
        setError("Enter NPS account total outstanding balance value");
        return false;
      }
    }
    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      // EPF account
      if (props.epf && epf) {
        const response = await Backend.updateAccount(epfAccount.id, epfAccount);
        if (props.onUpdated) {
          props.onUpdated();
        }
        Notify.success("EPF Account updated successfully.");
      }

      if (!props.epf && epf) {
        const response = await Backend.addAccount(epfAccount);
        if (props.onAdded) {
          props.onAdded(response.data);
        }
        Notify.success("EPF Account added successfully.");
      }

      // ESS account
      if (props.ess && ess) {
        const response = await Backend.updateAccount(essAccount.id, essAccount);
        if (props.onUpdated) {
          props.onUpdated();
        }
        Notify.success("ESS Account updated successfully.");
      }

      if (!props.ess && ess) {
        const response = await Backend.addAccount(essAccount);
        if (props.onAdded) {
          props.onAdded(response.data);
        }
        Notify.success("ESS Account added successfully.");
      }

      // NPS account
      if (props.nps && nps) {
        const response = await Backend.updateAccount(npsAccount.id, npsAccount);
        if (props.onUpdated) {
          props.onUpdated();
        }
        Notify.success("NPS Account updated successfully.");
      }

      if (!props.nps && nps) {
        const response = await Backend.addAccount(npsAccount);
        if (props.onAdded) {
          props.onAdded(response.data);
        }
        Notify.success("NPS Account added successfully.");
      }

      props.onClose();
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Account</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              <CCol sm="12">
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>Select Employee</CLabel>
                    <CSelect
                      value={employeeId}
                      name="employee_id"
                      onChange={(e) => {
                        handleFormChange(e);
                        setEmployeeId(e.target.value);
                      }}
                    >
                      {props.employee ? (
                        <option value={props.employee.id}>
                          {props.employee.name}
                        </option>
                      ) : (
                        employees.map((employee) => {
                          return (
                            <option value={employee.id}>{employee.name}</option>
                          );
                        })
                      )}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>EPF Account : </CLabel>
                    <CSwitch
                      className="text-right"
                      checked={epf}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setPension(!epf);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>ESS Account : </CLabel>
                    <CSwitch
                      className="text-right"
                      checked={ess}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setEss(!ess);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>NPS Account : </CLabel>
                    <CSwitch
                      className="text-right"
                      checked={nps}
                      color="dark"
                      size="lg"
                      variant="3d"
                      shape="pill"
                      onChange={(e) => {
                        setNps(!nps);
                      }}
                    />
                  </CFormGroup>
                  {epf && (
                    <>
                      <hr />
                      <h5>EPF Account Details:</h5>
                      <hr />
                      <CFormGroup>
                        <CLabel>Account Code</CLabel>
                        <CInput
                          value={epfAccount.code}
                          type="text"
                          name="code"
                          readOnly={true}
                          onChange={(e) => handleFormChange("epf", e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Total Outstanding Loan</CLabel>
                        <CInput
                          value={epfAccount.total_outstanding_loan}
                          type="number"
                          name="total_outstanding_loan"
                          min={"0"}
                          onChange={(e) => handleFormChange("epf", e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>
                          Total Outstanding Balance <br />
                          <CAlert color={"info"}>
                            <small>
                              This will be used as Opening Balance of current
                              Fiscal Account
                            </small>
                          </CAlert>
                        </CLabel>
                        <CInput
                          value={epfAccount.total_outstanding_balance}
                          type="number"
                          name="total_outstanding_balance"
                          min={"0"}
                          onChange={(e) => handleFormChange("epf", e)}
                        />
                      </CFormGroup>
                    </>
                  )}

                  {ess && (
                    <>
                      <hr />
                      <h5>ESS Account Details:</h5>
                      <hr />
                      <CFormGroup>
                        <CLabel>Account Code</CLabel>
                        <CInput
                          value={essAccount.code}
                          type="text"
                          name="code"
                          readOnly={true}
                          onChange={(e) => handleFormChange("ess", e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Total Outstanding Loan</CLabel>
                        <CInput
                          value={essAccount.total_outstanding_loan}
                          type="number"
                          name="total_outstanding_loan"
                          min={"0"}
                          onChange={(e) => handleFormChange("ess", e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>
                          Total Outstanding Balance <br />
                          <CAlert color={"info"}>
                            <small>
                              This will be used as Opening Balance of current
                              Fiscal Account
                            </small>
                          </CAlert>
                        </CLabel>
                        <CInput
                          value={essAccount.total_outstanding_balance}
                          type="number"
                          name="total_outstanding_balance"
                          min={"0"}
                          onChange={(e) => handleFormChange("ess", e)}
                        />
                      </CFormGroup>
                    </>
                  )}

                  {nps && (
                    <>
                      <hr />
                      <h5>NPS Account Details:</h5>
                      <hr />
                      <CFormGroup>
                        <CLabel>Account Code</CLabel>
                        <CInput
                          value={npsAccount.code}
                          type="text"
                          name="code"
                          readOnly={true}
                          onChange={(e) => handleFormChange("nps", e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>Total Outstanding Loan</CLabel>
                        <CInput
                          value={npsAccount.total_outstanding_loan}
                          type="number"
                          name="total_outstanding_loan"
                          min={"0"}
                          onChange={(e) => handleFormChange("nps", e)}
                        />
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel>
                          Total Outstanding Balance <br />
                          <CAlert color={"info"}>
                            <small>
                              This will be used as Opening Balance of current
                              Fiscal Account
                            </small>
                          </CAlert>
                        </CLabel>
                        <CInput
                          value={npsAccount.total_outstanding_balance}
                          type="number"
                          name="total_outstanding_balance"
                          min={"0"}
                          onChange={(e) => handleFormChange("nps", e)}
                        />
                      </CFormGroup>
                    </>
                  )}
                </CForm>
              </CCol>
            </CRow>
            {error && (
              <CRow>
                <CAlert color="danger" className="w-100">
                  {error}
                </CAlert>
              </CRow>
            )}
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color="dark"
            className="btn-sm pl-4 pr-4"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component="span" size="sm" aria-hidden="true" />{" "}
                Saving...
              </>
            ) : (
              "Add/Update Account"
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
