import '../constants/Api'
import FetchHelper from './FetchHelper'

class Backend {
  static csrfCookie() {
    return FetchHelper.get(`${window.Api.Base}/sanctum/csrf-cookie`, false)
  }
  // Dashboard Stats
  static getStats() {
    return FetchHelper.get(`${window.Api.Employees}/stats`, true)
  }

  // Settings
  static getSetting(name) {
    return FetchHelper.get(`${window.Api.Settings}/${name}`, true)
  }

  static getSettings() {
    return FetchHelper.get(`${window.Api.Settings}`, true)
  }

  static updateSettings(data) {
    return FetchHelper.post(`${window.Api.Settings}`, data, false, true)
  }

  // Branches
  static getBranch(id) {
    return FetchHelper.get(`${window.Api.Branches}/${id}`, true)
  }

  static getBranches() {
    return FetchHelper.get(`${window.Api.Branches}`, true)
  }

  static addBranch(data) {
    return FetchHelper.post(`${window.Api.Branches}`, data, false, true)
  }

  static updateBranch(id, data) {
    return FetchHelper.put(`${window.Api.Branches}/${id}`, data, true, true)
  }

  static deleteBranch(id) {
    return FetchHelper.delete(`${window.Api.Branches}/${id}`, null, true)
  }

  // Financial Years
  static getFY(id) {
    return FetchHelper.get(`${window.Api.FinancialYears}/${id}`, true)
  }

  static getCurrentFY() {
    return FetchHelper.get(`${window.Api.FinancialYears}/current`, true)
  }

  static getFYs() {
    return FetchHelper.get(`${window.Api.FinancialYears}`, true)
  }

  static addFY(data) {
    return FetchHelper.post(`${window.Api.FinancialYears}`, data, false, true)
  }

  static updateFY(id, data) {
    return FetchHelper.put(
      `${window.Api.FinancialYears}/${id}`,
      data,
      true,
      true
    )
  }

  static deleteFY(id) {
    return FetchHelper.delete(`${window.Api.FinancialYears}/${id}`, null, true)
  }

  // Users
  static getUser(id) {
    return FetchHelper.get(`${window.Api.Users}/${id}`, true)
  }

  static getUsers() {
    return FetchHelper.get(`${window.Api.Users}`, true)
  }

  static addUser(data) {
    return FetchHelper.post(`${window.Api.Users}`, data, false, true)
  }

  static updateUser(id, data) {
    return FetchHelper.put(`${window.Api.Users}/${id}`, data, true, true)
  }

  static deleteUser(id) {
    return FetchHelper.delete(`${window.Api.Users}/${id}`, null, true)
  }

  // Employees
  static getEmployee(id) {
    return FetchHelper.get(`${window.Api.Employees}/${id}`, true)
  }

  static getEmployeeData(id, relation = '') {
    return FetchHelper.get(`${window.Api.Employees}/${id}/${relation}`, true)
  }

  static getEmployees(params = '') {
    return FetchHelper.get(`${window.Api.Employees}/paginate${params}`, true)
  }

  static getAllEmployees(params = '') {
    return FetchHelper.get(`${window.Api.Employees}/all${params}`, true)
  }

  static addEmployee(data) {
    return FetchHelper.post(`${window.Api.Employees}`, data, false, true)
  }

  static addEmployeeFiscalAccount(id, data) {
    return FetchHelper.post(
      `${window.Api.Employees}/${id}/fiscal-account`,
      data,
      false,
      true
    )
  }

  static updateEmployee(id, data) {
    return FetchHelper.put(`${window.Api.Employees}/${id}`, data, true, true)
  }

  static deleteEmployee(id) {
    return FetchHelper.delete(`${window.Api.Employees}/${id}`, null, true)
  }

  static importEmployees(data) {
    return FetchHelper.post(`${window.Api.Employees}/import`, data, false, true)
  }

  static importEmployeesBasicPay(data) {
    return FetchHelper.post(
      `${window.Api.Employees}/import-basic-pay`,
      data,
      false,
      true
    )
  }

  static exportEmployees(data) {
    return FetchHelper.post(`${window.Api.Employees}/export`, data, false, true)
  }

  static mergeEmployees(data) {
    return FetchHelper.post(`${window.Api.Employees}/merge`, data, false, true)
  }

  // Accounts
  static getAccount(id) {
    return FetchHelper.get(`${window.Api.Accounts}/${id}`, true)
  }

  static getAccountData(id, relation = '') {
    return FetchHelper.get(`${window.Api.Accounts}/${id}/${relation}`, true)
  }

  static getAccounts(params = '') {
    return FetchHelper.get(`${window.Api.Accounts}/paginate${params}`, true)
  }

  static getAllAccounts() {
    return FetchHelper.get(`${window.Api.Accounts}`, true)
  }

  static addAccount(data) {
    return FetchHelper.post(`${window.Api.Accounts}`, data, false, true)
  }

  static updateAccount(id, data) {
    return FetchHelper.put(`${window.Api.Accounts}/${id}`, data, true, true)
  }

  static deleteAccount(id) {
    return FetchHelper.delete(`${window.Api.Accounts}/${id}`, null, true)
  }

  static closeAccount(id) {
    return FetchHelper.put(`${window.Api.Accounts}/${id}/close`, {}, true, true)
  }

  // Fiscal Accounts
  static getAllFiscalAccounts(params = '') {
    return FetchHelper.get(`${window.Api.FiscalAccounts}/all${params}`, true)
  }

  static getFiscalAccounts(params = '') {
    return FetchHelper.get(
      `${window.Api.FiscalAccounts}/paginate${params}`,
      true
    )
  }

  static addFiscalAccount(data) {
    return FetchHelper.post(`${window.Api.FiscalAccounts}`, data, false, true)
  }

  static updateFiscalAccount(id, data) {
    return FetchHelper.put(
      `${window.Api.FiscalAccounts}/${id}`,
      data,
      true,
      true
    )
  }

  static fiscalAccountData(id, relation = '') {
    return FetchHelper.get(
      `${window.Api.FiscalAccounts}/${id}/${relation}`,
      true
    )
  }

  static fiscalAccountStatements(id) {
    return FetchHelper.get(`${window.Api.FiscalAccounts}/${id}/statements`)
  }

  static fiscalAccountPDFStatements(id, params = '') {
    return FetchHelper.get(
      `${window.Api.FiscalAccounts}/${id}/pdf-statements${params}`
    )
  }

  static closeFiscalAccount(id) {
    return FetchHelper.put(
      `${window.Api.FiscalAccounts}/${id}/close`,
      {},
      true,
      true
    )
  }

  static createFiscalAccounts(fyId) {
    return FetchHelper.post(
      `${window.Api.FiscalAccounts}/${fyId}/employees`,
      {},
      false,
      true
    )
  }

  static deleteFiscalAccount(id) {
    return FetchHelper.delete(`${window.Api.FiscalAccounts}/${id}`, null, true)
  }

  // Contributions
  static getContribution(id) {
    return FetchHelper.get(`${window.Api.Contributions}/${id}`, true)
  }

  static getContributions(params = '') {
    return FetchHelper.get(
      `${window.Api.Contributions}/paginate${params}`,
      true
    )
  }

  static getFilteredContributions(params = '') {
    return FetchHelper.get(
      `${window.Api.Contributions}/filter/employees${params}`,
      true
    )
  }

  static addContribution(data) {
    return FetchHelper.post(`${window.Api.Contributions}`, data, false, true)
  }

  static updateContribution(id, data) {
    return FetchHelper.put(
      `${window.Api.Contributions}/${id}`,
      data,
      true,
      true
    )
  }

  static deleteContribution(id) {
    return FetchHelper.delete(
      `${window.Api.Contributions}/${id}/permanent`,
      null,
      true
    )
  }

  // Loans
  static validateLoan(data) {
    return FetchHelper.post(`${window.Api.Loans}/validate`, data, false, true)
  }

  static getLoanPreClosureData(id) {
    return FetchHelper.get(`${window.Api.Loans}/${id}/pre-closure`, true)
  }

  static getLoan(id) {
    return FetchHelper.get(`${window.Api.Loans}/${id}`, true)
  }

  static getLoans(params = '') {
    return FetchHelper.get(`${window.Api.Loans}/paginate${params}`, true)
  }

  static addLoan(data) {
    return FetchHelper.post(`${window.Api.Loans}`, data, false, true)
  }

  static closeLoan(id) {
    return FetchHelper.post(`${window.Api.Loans}/${id}/close`, {}, false, true)
  }

  static approveLoan(id) {
    return FetchHelper.post(
      `${window.Api.Loans}/${id}/approve`,
      {},
      false,
      true
    )
  }

  // Loan Recovery
  static payLoanRecovery(id, data) {
    return FetchHelper.put(
      `${window.Api.LoanRecoveries}/${id}`,
      data,
      true,
      true
    )
  }

  static adjustLoanRecovery(id, data) {
    return FetchHelper.put(
      `${window.Api.LoanRecoveries}/${id}/adjust`,
      data,
      true,
      true
    )
  }

  static getFilteredLoanRecoveries(params = '') {
    return FetchHelper.get(
      `${window.Api.LoanRecoveries}/filter/employees${params}`,
      true
    )
  }

  // Signatories
  static getSignatory(id) {
    return FetchHelper.get(`${window.Api.Signatories}/${id}`, true)
  }

  static getSignatories() {
    return FetchHelper.get(`${window.Api.Signatories}`, true)
  }

  static addSignatory(data) {
    return FetchHelper.post(`${window.Api.Signatories}`, data, false, true)
  }

  static updateSignatory(id, data) {
    return FetchHelper.put(`${window.Api.Signatories}/${id}`, data, true, true)
  }

  static deleteSignatory(id) {
    return FetchHelper.delete(`${window.Api.Signatories}/${id}`, null, true)
  }

  // Account Heads
  static getAccountHead(id) {
    return FetchHelper.get(`${window.Api.AccountHeads}/${id}`, true)
  }

  static getAllAccountHeads() {
    return FetchHelper.get(`${window.Api.AccountHeads}`, true)
  }

  static getAccountHeads(params = '') {
    return FetchHelper.get(`${window.Api.AccountHeads}/paginate${params}`, true)
  }

  static addAccountHead(data) {
    return FetchHelper.post(`${window.Api.AccountHeads}`, data, false, false)
  }

  static updateAccountHead(id, data) {
    return FetchHelper.put(`${window.Api.AccountHeads}/${id}`, data, true, true)
  }

  static deleteAccountHead(id) {
    return FetchHelper.delete(`${window.Api.AccountHeads}/${id}`, null, true)
  }

  // Receipts
  static getReceipt(id) {
    return FetchHelper.get(`${window.Api.Receipts}/${id}`, true)
  }

  static getAllReceipts() {
    return FetchHelper.get(`${window.Api.Receipts}`, true)
  }

  static getReceipts(params = '') {
    return FetchHelper.get(`${window.Api.Receipts}/paginate${params}`, true)
  }

  static exportReceipts(params = '') {
    return FetchHelper.getRaw(`${window.Api.Receipts}/export${params}`, true)
  }

  static addReceipt(data) {
    return FetchHelper.post(`${window.Api.Receipts}`, data, false, true)
  }

  static updateReceipt(id, data) {
    return FetchHelper.put(`${window.Api.Receipts}/${id}`, data, true, true)
  }

  static deleteReceipt(id) {
    return FetchHelper.delete(`${window.Api.Receipts}/${id}`, null, true)
  }

  // Payments
  static getPayment(id) {
    return FetchHelper.get(`${window.Api.Payments}/${id}`, true)
  }

  static getAllPayments() {
    return FetchHelper.get(`${window.Api.Payments}`, true)
  }

  static getPayments(params = '') {
    return FetchHelper.get(`${window.Api.Payments}/paginate${params}`, true)
  }

  static exportPayments(params = '') {
    return FetchHelper.get(`${window.Api.Payments}/export${params}`, true)
  }

  static addPayment(data) {
    return FetchHelper.post(`${window.Api.Payments}`, data, false, true)
  }

  static updatePayment(id, data) {
    return FetchHelper.put(`${window.Api.Payments}/${id}`, data, true, true)
  }

  static deletePayment(id) {
    return FetchHelper.delete(`${window.Api.Payments}/${id}`, null, true)
  }

  static getAccountReports(params = '') {
    return FetchHelper.get(`${window.Api.Base}/account-reports${params}`, true)
  }

  static exportReports(params = '') {
    return FetchHelper.get(`${window.Api.Base}/rp-reports${params}`, true)
  }

  // Advance Payments
  static getAdvancePayment(id) {
    return FetchHelper.get(`${window.Api.AdvancePayments}/${id}`, true)
  }

  static getAllAdvancePayments(employeeId) {
    return FetchHelper.get(`${window.Api.AdvancePayments}/${employeeId}`, true)
  }

  // static getAdvancePayments(params = '') {
  //   return FetchHelper.get(`${window.Appi.Payments}/paginate${params}`, true)
  // }

  static addAdvancePayment(data) {
    return FetchHelper.post(`${window.Api.AdvancePayments}`, data, false, true)
  }

  static updateAdvancePayment(id, data) {
    return FetchHelper.put(
      `${window.Api.AdvancePayments}/${id}`,
      data,
      true,
      true
    )
  }

  static deleteAdvancePayment(id) {
    return FetchHelper.delete(`${window.Api.AdvancePayments}/${id}`, null, true)
  }

  // Dependents
  static getDependents(params = '') {
    return FetchHelper.get(`${window.Api.Dependents}/paginate${params}`, true)
  }

  static addDependent(data) {
    return FetchHelper.post(`${window.Api.Dependents}`, data, false, true)
  }

  static updateDependent(id, data) {
    return FetchHelper.put(`${window.Api.Dependents}/${id}`, data, true, true)
  }

  static deleteDependent(id) {
    return FetchHelper.delete(`${window.Api.Dependents}/${id}`, null, true)
  }

  static sendOtp(phoneNo) {
    return FetchHelper.post(
      `${window.Api.User.OTP}`,
      { phone_no: phoneNo },
      false,
      false
    )
  }

  static setPassword(data) {
    return FetchHelper.post(
      `${window.Api.Employees}/set-password`,
      data,
      false,
      false
    )
  }

  // Upload file
  static uploadFile(file, folder) {
    let data = new FormData()
    data.append('file', file)
    console.log(data)
    return FetchHelper.post(`${window.Api.UploadFile}/${folder}`, data, true)
  }
}

export default Backend
