import '../constants/Api'
import FetchHelper from './FetchHelper'

export default class AuthManager {
  static isAuthenticated() {
    return AuthManager.isLoggedIn
  }

  static getAccessToken() {
    return AuthManager.accessToken
  }

  static getCurrentUser() {
    return AuthManager.currentUser
  }

  static _hasError(responseJson) {
    let hasError = false
    let tokens = responseJson.tokens

    if (!tokens) {
      hasError = true
    }

    if (tokens.length === 0) {
      hasError = true
    }

    if (!tokens.access) {
      hasError = true
    }

    if (!tokens.refresh) {
      hasError = true
    }

    return hasError
  }

  static register(data) {
    return FetchHelper.post(window.Api.Register, data, false, false)
      .then((responseJson) => {
        // if (this._hasError(responseJson)) {
        //   throw AuthManager.getError(responseJson)
        // }
        AuthManager._setUser(responseJson)
        return responseJson
      })
      .catch((error) => {
        throw AuthManager.getError(error)
      })
  }

  static login(name, password) {
    let data = { name, password }

    return FetchHelper.post(window.Api.User.Login, data, false, false)
      .then((responseJson) => {
        // if (this._hasError(responseJson)) {
        //   throw AuthManager.getError(responseJson)
        // }

        AuthManager._setUser(responseJson)

        return responseJson
      })
      .catch((error) => {
        throw AuthManager.getError(error)
      })
  }

  static async isUserLoggedIn(onSuccess, onError) {
    if (AuthManager.isLoggedIn) {
      onSuccess()
      return
    }

    return AuthManager.checkUserLoginStatus(() => {
      return onSuccess()
    }).catch((error) => {
      onError()
      // window.location.href = '/login'
    })
  }

  static checkUserLoginStatus() {
    return FetchHelper.get(window.Api.User.Info, false).then((responseJson) => {
      return AuthManager._setUser(responseJson)
    })
  }

  static silentLogin() {
    return AuthManager.checkUserLoginStatus()
      .then(() => {
        return FetchHelper.get(window.Api.User.Info)
      })
      .then((responseJson) => {
        AuthManager._setUser(responseJson)
        return AuthManager.currentUser
      })
      .catch((error) => {
        AuthManager.isLoggedIn = null
        AuthManager.currentUser = null
        throw error
      })
  }

  static async logout() {
    return FetchHelper.post(window.Api.User.Logout, {}, false, false).then(
      () => {
        return AuthManager.removeCredentials()
      }
    )
  }

  static requestResetPassword(email) {
    return FetchHelper.post(
      window.Api.User.RequestResetPassword,
      {
        email,
      },
      false,
      false
    )
  }

  static resetPassword(email, password, code) {
    let data = {
      email,
      password,
      verification_code: code,
    }
    return FetchHelper.post(window.Api.User.ResetPassword, data, false, true)
  }

  static removeCredentials() {
    AuthManager.isLoggedIn = false
    AuthManager.currentUser = null
    return true
  }

  static getError(error) {
    var errorMessage = 'An unexpected error occured'
    if (error.email) {
      errorMessage = error.email[0]
    } else if (error.message) {
      errorMessage = error.message
    } else if (error.non_field_errors) {
      errorMessage = error.non_field_errors[0]
    } else if (error.detail) {
      errorMessage = error.detail
    }
    return { error: errorMessage, message: errorMessage }
  }

  static _setUser(responseJson) {
    AuthManager.isLoggedIn = true
    AuthManager.currentUser = this._getCurrentUser(responseJson)
  }

  static _getCurrentUser(responseJson) {
    if (responseJson.user) {
      return responseJson.user
    }
    return null
  }

  static getHeaders(contentType = 'application/json') {
    var headers = {
      'X-Requested-With': 'XMLHttpRequest',
    }

    const csrfToken = this.getCookie('XSRF-TOKEN')
    if (contentType.length) {
      headers['Content-Type'] = contentType
    }
    headers['X-XSRF-TOKEN'] = csrfToken

    return new Headers(headers)
  }

  static getCookie(name) {
    if (!document.cookie) {
      return null
    }

    const xsrfCookies = document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((c) => c.startsWith(name + '='))

    if (xsrfCookies.length === 0) {
      return null
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1])
  }
}
